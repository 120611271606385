import { useContext, useEffect } from "react";
import AdminHeader from "./_components/header/header";
import AdminSidebar from "./_components/sidebar/sidebar";
import { AuthContext } from "../../context/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { openSidebar } from "../../utils/main";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function AdminWrapper({mainClass='', isSidebar=true, isRightbar=true, model = null, isHeader = true, headerClass = 'bg-white', isGlobalNavs = true, ...props}){
    const {isLogin, isReady, token, logout, user} = useContext(AuthContext);
    const {height} = useWindowDimensions()
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(()=>{
        if(isReady && !isLogin) {
            navigate('/login', {state:{pathname:location.pathname}});
        }
    }, [isReady, isLogin, navigate]);
    useEffect(()=>{
        if(isSidebar) openSidebar();
    }, [isSidebar])

    function keyDownHandler(e){
        if(e.altKey && e.code == 'KeyA'){
            navigate('/visits/create')
        }

        if(e.ctrlKey && e.code == 'KeyZ'){
            navigate(-1)
        }
    }
    useEffect(()=>{
        document.addEventListener('keyup', keyDownHandler);
        return ()=>document.removeEventListener('keyup', keyDownHandler);
    }, []);
    return (
        <div className="admin-wrapper">
            {
                isHeader &&
                <AdminHeader isSidebar={isSidebar} isRightbar={isRightbar} headerClass={headerClass} isGlobalNavs={isGlobalNavs}/>
            }
            <div className="d-flex">
                <div className={!isSidebar ? 'd-none' : ''}>
                    <AdminSidebar model={model}/>
                </div>
                <main className={"main "+mainClass} style={{height:height+'px'}}>
                    {props.children}
                </main>
            </div>
            {/* <AdminFooter /> */}
        </div>
    )
}