import { useContext, useEffect, useRef, useState } from "react"
import Button, { AccentButton } from "../button/button"
import styles from './style.module.css'
import { AuthContext } from "../../context/AuthContext"
import ModalWithButton from "../modal-button.jsx/modal-btn"
import Modal from "../modal/modal"
import { LangContext } from "../../context/LangContext"
export default function ProfileImg({
    height=50, src = "/img/thumbnail.png", 
    imagePosition = 0, updateImagePosition = ()=>{},
    isUpdate = false, isRemovable=false, 
    save=()=>{}, remove=()=>{}, 
    id=''
}){
    const ref = useRef()
    const {user, setUser, logout} = useContext(AuthContext)
    return (
        <div className={styles.box}>
            <img src={src} alt="Profile image" className={(isUpdate ? styles.imgUpd : styles.img) + ' ' +positions[imagePosition]} width={height} height={height} crossOrigin='' id={id}/>
            {
                isUpdate &&
                <div className={styles.tools}>
                    <input type="file" className={'d-none'} ref={ref} accept="image/*" onChange={(e)=>save(e)} multiple={false}/>
                    {
                        isRemovable &&
                        <Button theme="danger" buttonSize="little" title='Ýok etmek' onClick={remove}>
                            <i className="bi bi-trash2"/>
                        </Button>
                    }
                    <Button theme="warning" buttonSize="little" className="mx-1" 
                        data-bs-toggle={"modal"} data-bs-target={`#${SETTING_MODAL}`}>
                        <i className="bi bi-gear"></i>
                    </Button>
                    <Button theme="primary" buttonSize="little" title='Surat ýüklemek' onClick={()=>{
                        if(ref.current) ref.current.click()
                    }}>
                        <i className="bi bi-upload"/>
                    </Button>
                </div>
            }
            {
                isUpdate &&
                <SettingModal src={src} oldPosition={imagePosition} updateImagePosition={updateImagePosition}/>
            }
        </div>
    )
}
const SETTING_MODAL = "setting-modal"
const positions = [styles.objectPositionCenter, styles.objectPositionTop, styles.objectPositionBottom]
function SettingModal({src, oldPosition, updateImagePosition = ()=>{}}){
    const closeRef = useRef()
    const {langDictionary} = useContext(LangContext)
    const [position, setPosition] = useState(0)
    useEffect(()=>setPosition(oldPosition), [oldPosition])
    return(
        <Modal id={SETTING_MODAL} isHeader={false} isFooter={false}>
            <div className="d-flex align-items-center justify-content-center mb-2">
                <div className="d-flex flex-column flex-wrap me-3">
                {
                    [
                        {id:1, icon:'top'},
                        {id:0, icon:'center'},
                        {id:2, icon:'bottom'},
                    ].map((_position)=>{
                        return(
                            <AccentButton theme={position == _position?.id ? 'primary' : "secondary"} className="border my-1" onClick={()=>setPosition(_position?.id)}>
                                <i className={`bi bi-align-${_position?.icon}`}/>
                            </AccentButton>
                        )
                    })
                }
                </div>
                <img src={src} alt="Profile image" width={150} height={150} crossOrigin='' id={'shadow-image'}  className={styles.img + ' ' +positions[position]}/>
            </div>
            <div className="d-flex align-items-center flex-wrap">
                <div className="col-12 p-1">
                    <Button theme="success" className="w-100" onClick={()=>updateImagePosition(position)}>
                        <i className="bi bi-check2-square me-2"/>
                        {langDictionary?.save}
                    </Button>
                </div>
            </div>
            <Button theme="light" className="position-absolute top-0 end-0 m-1 rounded-circle" data-bs-dismiss="modal">
                <i className="bi bi-x-lg"/>
            </Button>
        </Modal>  
    )
}