import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { checkOnlyLetters, checkRequire, getFormattedDate, getString } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import { handleErrors } from "../../../../utils/error-handler";
import Button, { AccentButton } from "../../../../components/button/button";

export default function AdminHospitals(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({title:''});
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:10});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout, user} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-hospitals-modal';
    const pageKey="admin-hospitals-page";
    const pageTitle="Hassahanalar";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    const handleRemove = (item)=>{
        if(window.confirm("Siz hakykatdan hem bu hassahanany ýok etmekçimi?")){
            AxiosHelper.deleteHospital(item?.id, token).then((result)=>{
                if(result.status == 200){
                    appendNotification("Döwlet üstünlikli ýok edildi!")
                    refresh()
                }
            })
        }
    }
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Ady" filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {getString(item?.name) || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Gysga ady" filter={filter} setFilter={setFilter} targetAttribute='code'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {getString(item?.code) || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Tertip belgisi" filter={filter} setFilter={setFilter} targetAttribute='order'/>, content:(item)=>{
                return (
                    item?.order || '-//-'
                )
            }
        },
        {
            text:<Sort text="Status" filter={filter} setFilter={setFilter} targetAttribute='status'/>, content:(item)=>{
                return (
                    <AccentButton theme={item?.status == 99 ? "secondary" : 'success'}>
                        <i className={`bi bi-eye${item?.status == 99 ? '-slash' : ''}`}/>
                    </AccentButton>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(new Date(item?.createdAt), '/', true)}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <AccentButton theme="danger m-1" onClick={()=>handleRemove(item)}>
                            <i className="bi bi-trash2"/>
                        </AccentButton>
                        <AccentButton theme="primary m-1" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"/>
                        </AccentButton>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getHospitals(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateHospital(form?.id, form, token) : AxiosHelper.createHospital(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Hassahana üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        if(closeRef.current) closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, logout, errors, setErrors)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
           err.name='Hassahana ady boş bolup bilmez!'
        }else if(!checkOnlyLetters(form?.name)){
            err.name='Hassahana ady diňe harplardan ybarat bolmaly!';
        }
        
        if(!checkRequire(form?.code)){
            err.code='Hassahana gysga ady boş bolup bilmez!'
        }else if(!checkOnlyLetters(form?.code)){
            err.code='Hassahana gysga ady diňe harplardan ybarat bolmaly!';
        }else if(form?.code?.length > 5){
            err.code='Hassahana gysga ady 3 harpdan gysga bolmaly!';
        }

        setErrors(err)
        return err.name || err.code;
    }
    function handleSync() {
        if(window.confirm("Siz hakykatdan hem hassahanalary sinhronlaşdyrmakçymy?")){
            AxiosHelper.syncHospitals().then((result)=>{
                if(result.status == 200){
                    appendNotification("Hassahanalar üstünlikli sinhronlaşdyryldy", 'success')
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading}
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle}
            pageTitleEndComponent={
                <Button onClick={handleSync} theme={'warning'}>
                    Sync
                </Button>
            }
            startBlock={
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Hassahana üýtgetmek' :"Täze Hassahana goşmak"}
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} onButtonClick={()=>{setIsUpdate(false); setForm({title:'', code:'', order:''}); setErrors({})}}>
                    <Form form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save}/>
                </ModalWithButton>
            } />
    )
}