import { useContext, useEffect, useRef, useState } from "react"
import { LangContext } from "../../../../context/LangContext"
import { AuthContext } from "../../../../context/AuthContext"
import Button from "../../../../components/button/button"
import AxiosHelper from "../../../../utils/API/AxiosHelper"
import { handleErrors } from "../../../../utils/error-handler"
import Sort from "../../../../components/sort/sort"
import { firstLetterUpperCase, getFormattedDate, getFullname } from "../../../../utils/main"
import DataViewer from "../../../../components/data-viewer"
import Modal from "../../../../components/modal/modal"
import LoadingIcon from "../../../../components/loading-icon/loading-icon"
export const viewAnalyseMetadataModalID = 'view-analyse-metadata-modal'
export const analyse_types = {
    'laboratory':'Laborator',
    'radiology':'Radiolog',
    'spec':'Hünärmen',
    'instrumental':'Enjam'
}
export default function PatientAnalyses({model}){
    const {langDictionary} = useContext(LangContext)
    const {isReady, logout} = useContext(AuthContext)
    const [data, setData] = useState([])
    const [filter, setFilter] = useState({limit:10, page:1})
    const [count, setCount] = useState(0)
    const [selected, setSelected] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const modalTogglerRef = useRef()
    const refresh = ()=>{
        if(isReady && !isLoading){
            setIsLoading(true)
            AxiosHelper.getAnalyses({patient_id:model?.id}).then((result)=>{
                if(result.status == 200){
                    setData(result?.data?.data)
                    setCount(result?.data?.count)
                }
            }).catch((e)=>handleErrors(e, logout))
            .finally(()=>setIsLoading(false))
        }
    }
    useEffect(refresh, [isReady])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1
            }
        },
        {
            text:langDictionary?.visit_office, content:(item)=>{
                return (
                    <span>
                        {item?._hospital?.name || 'Näbelli'}
                    </span>
                )
            }
        },
        {
            text:langDictionary?.analyse_type, content:(item)=>{
                return analyse_types[item?.analyse_type]
            }
        },
        {
            text:langDictionary?.type, content:(item)=>{
                return getAnalyseName(item)
            }
        },
        {
            text:langDictionary?.department, content:(item)=>{
                return (item?.metadata?._type?._department?.name || '-//-')
            }
        },
        {
            text:langDictionary?.doctor, content:(item)=>{
                return (item?.metadata?._type?._doctor ? getFullname(item?.metadata?._type?._doctor) : '-//-')
            }
        },
        {
            text:<Sort text={langDictionary?.date} filter={filter} setFilter={setFilter} targetAttribute={"date"}/>, content:(item)=>{
                const date = new Date(item?.analyse_date)
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '-')}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <Button theme="light" className="bg-gray text-dark me-2 border" 
                            data-bs-toggle="modal" data-bs-target={`#${viewAnalyseMetadataModalID}`} 
                            onClick={()=>setSelected(item)}>
                            <i className="bi bi-eye"/>
                        </Button>
                    </div>
                )
            }
        }
    ]
    return(
        <div className="p-2 rounded-3 box-shadow mb-3">
            <div className="d-flex align-items-center fw-500">
                <span className="text-slatgray">
                    {langDictionary?.analyses}
                </span>
                <Button theme="light" className="bg-gray border ms-auto" onClick={refresh}>
                    <LoadingIcon isAnimate={isLoading}/>
                </Button>
            </div>
            <hr className="my-2" />
            <DataViewer data={data} items={items} count={count} filter={filter} setFilter={setFilter} refresh={refresh}
                key={'personified-records-data'} onDoubleClick={(item)=>{
                    if(modalTogglerRef?.current){
                        setSelected(item)
                        modalTogglerRef.current.click()
                    }
                }}/>
                <AnalyseViewMetadata model={selected}/>
                <button className="d-none" data-bs-toggle="modal" data-bs-target={`#${viewAnalyseMetadataModalID}`} ref={modalTogglerRef}/>
        </div>
    )
}

export function getAnalyseName(analyse){
    if(analyse?.metadata?._type?._parent){
        return(`${analyse?.metadata?._type?._parent?.name} (${analyse?.metadata?._type?.name})`)
    }else{
        return(analyse?.metadata?._type?.name)
    }
}

export function AnalyseViewMetadata({model}) {
    console.log(model)
    const {langDictionary} = useContext(LangContext)
    const [dgs, setDgs] = useState([])
    useEffect(()=>{
        const arr = []
        if(model?.metadata?.diagnoses && model?.metadata?.diagnoses?.length){
            Array.from(model?.metadata?.diagnoses).map((diagnose)=>{
                if(diagnose && diagnose?._diagnose){
                    arr.push(`${diagnose?._diagnose?.code}-${diagnose?._diagnose?.name}`)
                }
            })
        }
        setDgs(arr.join(', '))
    }, [model])
    const arr = [
        // {title:langDictionary?.patient, value:getFullname(model?._patient, true)},
        {title:langDictionary?.visit_office, value:model?._hospital?.name},
        {title:langDictionary?.analyse_type, value:analyse_types[model?.analyse_type]},
        {title:langDictionary?.type, value:getAnalyseName(model)},
        {title:langDictionary?.date, value:getFormattedDate(model?.analyse_date, '/', true)},
        {title:langDictionary?.department, value:model?.metadata?._type?._department?.name},
        {title:langDictionary?.doctor, value:getFullname(model?.metadata?._type?._doctor, true)},
        // {title:langDictionary?.diagnose, value:dgs},
    ]
    return(
        <Modal id={viewAnalyseMetadataModalID} isFooter={false} isHeader={false} isBackdropStatic={false} modalSize="modal-lg">
            <div className="">
                <div className="d-flex align-items-center fw-500">
                    <span className="text-slatgray">
                        {langDictionary?.analyse} #{model?.id}
                    </span>
                    <Button theme="light" className="ms-auto text-slatgray bg-gray border"
                        data-bs-dismiss="modal">
                        <i className="bi bi-x-lg me-2"/>
                        {langDictionary?.close}
                    </Button>
                </div>
                <hr className="my-2" />
                <div className="row mb-2 text-small">
                    {
                        arr.map((item, index)=>{
                            return(
                                <div className={`col-md-6 my-1`}>
                                    <div className="d-flex align-items-bottom">
                                        <span className="text-slatgray text-nowrap pe-1">
                                            {item?.title}:
                                        </span>
                                        <div className="w-100 border-0 border-bottom border-dashed"/>
                                        <span className="text-nowrap ps-1">
                                            {item?.value}
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="overflow--auto text-small" style={{maxHeight:500+'px'}}>
                    <AnalyseResultsViewer model={model}/>
                </div>
            </div>
        </Modal>
    )
}

export function AnalyseResultsViewer({model}) {
    const [results, setResults] = useState([])
    useEffect(()=>{
        const arr = []
        const obj = {}
        if(model?.metadata?.results){
            Object.keys(model?.metadata?.results).map((key)=>{
                const result = model?.metadata?.results[key]
                if(result?.value && String(result?.value).trim() != null){
                    if(!obj[result?.question]){
                        obj[result?.question] = [result?.value]
                    }else{
                        obj[result?.question] = [...obj[result?.question], result?.value]
                    }
                }
            })
        }
        Object.entries(obj).map(([queston, result])=>{
            arr.push({
                title:queston,
                value:result.join(', ')
            })
        })
        if(model?.metadata?.result) arr.push({
            title:'Netije',
            value:model?.metadata?.result
        })
        if(model?.metadata?.advices) arr.push({
            title:'Maslahat',
            value:model?.metadata?.advices
        })
        setResults(arr)
    }, [model])
    return(
        <table className="table table-sm table-bordered table-striped m-0">
            <tbody>
            {
                results.map((item)=>{
                    return(
                        <tr>
                            <td className="text-secondary" style={{width:40+'%'}}>{firstLetterUpperCase(item?.title)}</td>
                            <td className="text-dark text-center">{item?.value}</td>
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
    )
}
