import { useState } from "react";
import AdminWrapper from "../../Wrapper";
import Form from "./form";

export default function AdminUsersCreate(){
    const [form, setForm] = useState({permissions:[]});
    const [errors, setErrors] = useState({});
    return (
        <AdminWrapper isSidebar={false} mainClass="position-relative overflow-hidden">
            <Form errors={errors} setErrors={setErrors} form={form} setForm={setForm} isUpdate={false} key={'admin-users-form'} />
        </AdminWrapper>
    )
}