import AdminWrapper from "../Wrapper";
import UnreachedMessage from "./unreached-message";

export default function Unreached(){
    return (
        <AdminWrapper isRightbar={true} isSidebar={false}>
            <div className="d-flex align-items-center justify-content-center">
                <UnreachedMessage/>
            </div>
        </AdminWrapper>
    )
}