import { useContext, useState } from "react"
import { LangContext } from "../../../../../context/LangContext"
import Button, { AccentButton } from "../../../../../components/button/button"
import SemiDonutChart from "../../../../../components/semi-donut-chart"
import BarChart from "../../../../../components/bar-chart"
import { COLORS } from "../../../../../utils/main"
import DonutChart from "../../../../../components/donut-chart"
import Chart from "react-apexcharts";

export default function ReportPatientsBlock(){
    const {langDictionary} = useContext(LangContext)
    return(
        <div className="p-2 rounded-3 box-shadow bg-white">
            <div className="d-flex align-items-center">
                <span className="text-slatgray fw-500">
                    {langDictionary?.patients}
                </span>
                <div className="ms-auto">
                    <AccentButton theme="primary" className="me-2">
                        <i className="bi bi-file-earmark-medical"/>
                    </AccentButton>
                    <Button theme="light">
                        <i className="bi bi-arrow-repeat"/>
                    </Button>
                </div>
            </div>
            <hr className="my-2" />
            <div className="row">
                <div className="col-md-8 border-end">
                    <ByRegion/>
                </div>
                <div className="col-md-4">
                    <ByGender/>
                    <ByBenefit/>
                </div>
            </div>
        </div>
    )
}

function ByRegion() {
    const data = [
        {x:'Aşgabat', y:30250},
        {x:'Arkadag', y:1236},
        {x:'Ahal', y:20157},
        {x:'Balkan', y:19684},
        {x:'Mary', y:25364},
        {x:'Lebap', y:17298},
        {x:'Daşoguz', y:21391}
    ]
    
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'radar',
            toolbar: {
                show: false
            }
        },
        title: {
            text: undefined,
        },
        xaxis: {
            categories: data.map((_)=>_.x),
            labels: {
                show: true,
                style: {
                    colors: ["#a8a8a8"],
                    fontSize: "11px",
                    fontFamily: 'Arial'
                }
            }
        },
        stroke: {
            width: 1,
        },
        markers: {
            size: 3,
        },
        colors:['#FFBE98'],
        plotOptions: {
            radar: {
            polygons: {
                strokeColor: '#e8e8e8',
                fill: {
                    colors: ['#f8f8f8', '#fff']
                }
            }
            }
        }
    });

    const [chartSeries, setChartSeries] = useState([
        {
            name: 'Raýatlar',
            data: data.map((_)=>_.y),
        }
    ]);
    return(
        <div className="p-1">
            <div className="row">
                <div className="col-md-4 text-small fw-500">
                    <div className="mb-3">
                        <span className="p-1 px-2 rounded-2 bg-light text-slatgray fs-4 border">
                            <i className="bi bi-people-fill me-2"/>
                            <span className="text-slatgray fw-500">
                                120 250
                            </span>
                        </span>
                    </div>
                    {
                        data.map((item, index)=>{
                            return(
                                <div className="d-flex align-items-bottom">
                                    <span className="text-slatgray text-nowrap">
                                        <i className="bi bi-square-fill me-1" style={{color:COLORS[index]}}/>
                                        {item.x}
                                    </span>
                                    <div className="border-bottom w-100 border-0 border-bottom border-dashed"/>
                                    <span>
                                        {item.y}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="col-md-8">
                    <div className="overflow-hidden" style={{maxHeight:255+'px'}}>
                        <Chart
                            options={chartOptions}
                            series={chartSeries}
                            type="radar"
                            width="100%"
                            height="300"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ByBenefit() {
    const women = [
        {
            x:'1 der',
            y:200,
        },
        {
            x:'2 der',
            y:140,
        },
        {
            x:'Çag.',
            y:170,
        },
        {
            x:'Pen.',
            y:110,
        },
        {
            x:'WUW',
            y:50,
        }
    ]
    const men = [
        {
            x:'1 der',
            y:130,
        },
        {
            x:'2 der',
            y:160,
        },
        {
            x:'Çag.',
            y:145,
        },
        {
            x:'Pen.',
            y:110,
        },
        {
            x:'WUW',
            y:150,
        }
    ]
    return(
        <div className="p-0 overflwo-hidden" style={{maxHeight:150+'px'}}>
            <BarChart id={'patient-benefit-chart'} text={""} colors={["#0091E4", "#315CE7"]} height={170}
                toolbarShow={false}
                showYLabels={false}
                series={[
                    {
                        name:'Erkekler',
                        data:men
                    },
                    {
                        name:'Aýallar',
                        data:women
                    }
                ]}/>
        </div>
    )
}

function ByGender() {
    return(
        <div className="p-1 pb-0 text-medium ">
            <div className="d-flex align-items-center">
                <div className="col-6 pe-1">
                    <span className="text-slatgray text-end fw-500">
                        <i className="bi bi-square-fill text-primary me-1"/>
                        Erkek - 60%
                    </span>
                </div>
                <div className="col-6 ps-1 text-end">
                    <span className="text-slatgray text-end fw-500">
                        <i className="bi bi-square-fill text-danger me-1"/>
                        Aýal - 40%
                    </span>
                </div>
            </div>
            <div className="overflow-hidden" style={{maxHeight:75+'px'}}>
                <SemiDonutChart 
                    labels={['Erkek', 'Ayal']}
                    series={[60, 40]}
                    height={150}
                    labelShow={false}
                    toolbarShow={false}
                    colors={['#0091E4', '#ff285a']}
                    />
            </div>
        </div>
    )
}