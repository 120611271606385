import { useState } from 'react';
import axios from 'axios';

const useDownload = () => {
  const [downloading, setDownloading] = useState(false);
  const [progress, setProgress] = useState(0);

  const downloadFile = (url, filename) => {
    setDownloading(true);
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        setProgress(Math.round(progressEvent.progress * 100));
      }
    })
      .then((response) => {
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setProgress(100);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return { downloading, progress, downloadFile };
};

export default useDownload;
