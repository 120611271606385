import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { checkRequire, getFormattedDate, getString } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import { handleErrors } from "../../../../utils/error-handler";
import Button, { AccentButton } from "../../../../components/button/button";

export default function AdminDocumentTypes(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({title:''});
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:10, statuses:[]});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout, user} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-document-types-modal';
    const pageKey="admin-document-types-page";
    const pageTitle="Resminama görnüşleri";
    
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Ady" filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.name) ? getString(item?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Gysga ady" filter={filter} setFilter={setFilter} targetAttribute='short_name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {getString(item?.short_name)}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Seriýa belgisi bolmalymy" filter={filter} setFilter={setFilter} targetAttribute='isSerialKeyRequired'/>, content:(item)=>{
                return (
                    item?.isSerialKeyRequired ?
                    <span className={`badge bg-accent-primary text-primary border border-primary ms-2 fw-bold`}>
                        Hawa
                    </span>
                    :
                    <span className={`badge bg-accent-warning text-orange border border-warning ms-2 fw-bold`}>
                        Ýok
                    </span>
                )
            }
        },
        {
            text:<Sort text="Tertip belgisi" filter={filter} setFilter={setFilter} targetAttribute='order'/>, content:(item)=>{
                return (
                    item?.order || '-//-'
                )
            }
        },
        {
            text:<Sort text="Status" filter={filter} setFilter={setFilter} targetAttribute='status'/>, content:(item)=>{
                return (
                    <AccentButton theme={item?.status == 99 ? "secondary" : 'success'}>
                        <i className={`bi bi-eye${item?.status == 99 ? '-slash' : ''}`}/>
                    </AccentButton>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '/', true)}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu resminama görnüşini ýok etmekçimi?")){
                                AxiosHelper.deleteDocumentType(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Resminama görnüşi üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                })
                            }
                        }}>
                            <i className="bi bi-trash2"/>
                        </button>
                        <button className="btn btn-sm bg-accent-primary text-primary m-1" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"/>
                        </button>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getDocumentTypes(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateDocumentType(form?.id, form, token) : AxiosHelper.createDocumentType(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Resminama görnüşi üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, logout, errors, setErrors)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
           err.name='Resminama görnüşiniň ady boş bolup bilmez!'
        }else if(form?.name?.length > 100){
            err.name='Resminama görnüşiniň ady 100 harpdan uzyn bolmaly däl'
        }else{
            err.name = null;
        }

        setErrors({...errors, ...err})
        return err.name;
    }
    function handleSync() {
        if(window.confirm("Siz hakykatdan hem resminama görnüşleri sinhronlaşdyrmakçymy?")){
            AxiosHelper.syncDocumentTypes().then((result)=>{
                if(result.status == 200){
                    appendNotification("Resminama görnüşleri üstünlikli sinhronlaşdyryldy", 'success')
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle}
            isFilterable={false}
            pageTitleEndComponent={
                <Button onClick={handleSync} theme={'warning'}>
                    Sync
                </Button>
            }
            startBlock={
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Resminama görnüşini üýtgetmek' :"Täze resminama görnüşini goşmak"} modalSize="modal-lg"
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} onButtonClick={()=>{setIsUpdate(false); setForm({}); setErrors({})}}>
                    <Form form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save}/>
                </ModalWithButton>
            } />
    )
}