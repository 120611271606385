import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { checkRequire, getFormattedDate, getString } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import Filter from "./filter";
import { handleErrors } from "../../../../utils/error-handler";
import Button, { AccentButton } from "../../../../components/button/button";
import IndexAllButton from "./index-all";

export default function AdminDiagnoses(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({title:''});
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:10, statuses:[]});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout, user} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-diagnoses-modal';
    const pageKey="admin-diagnoses-page";
    const pageTitle="Kesel kesgitleri";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Kody" filter={filter} setFilter={setFilter} targetAttribute='code'/>, content:(item)=>{
                if(item?._source) item = item?._source
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.code) ? getString(item?.code) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Ady" filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                if(item?._source) item = item?._source
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.name) ? getString(item?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Название" filter={filter} setFilter={setFilter} targetAttribute='name_ru'/>, content:(item)=>{
                if(item?._source) item = item?._source
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.name_ru) ? getString(item?.name_ru) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Düşündirilişi" filter={filter} setFilter={setFilter} targetAttribute='description'/>, content:(item)=>{
                if(item?._source) item = item?._source
                return (
                    <span className="fst-italic">
                        {`${getString(item?.description) ? getString(item?.description) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Tertip belgisi" filter={filter} setFilter={setFilter} targetAttribute='order'/>, content:(item)=>{
                return (
                    item?.order || '-//-'
                )
            }
        },
        {
            text:<Sort text="Status" filter={filter} setFilter={setFilter} targetAttribute='status'/>, content:(item)=>{
                return (
                    <AccentButton theme={item?.status == 99 ? "secondary" : 'success'}>
                        <i className={`bi bi-eye${item?.status == 99 ? '-slash' : ''}`}/>
                    </AccentButton>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '/', true)}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                if(item?._source) item = item?._source
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu kesel kesgidini ýok etmekçimi?")){
                                AxiosHelper.deleteDiagnose(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Kesel kesgidi üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                }).catch((e)=>handleErrors(e))
                            }
                        }}>
                            <i className="bi bi-trash2"/>
                        </button>
                        <button className="btn btn-sm bg-accent-primary text-primary m-1" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"/>
                        </button>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getDiagnoses(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateDiagnose(form?.id, form, token) : AxiosHelper.createDiagnose(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Kesel kesgidi üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, logout, errors, setErrors)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
           err.name='Kesel kesgidiniň adyny giriziň'
        }else{
            err.name = null;
        }

        if(!checkRequire(form?.code)){
           err.code='Kesel kesgidiniň kodyny giriziň'
        }else if(form?.code?.length >  10){
            err.code='Kesel kesgidiniň kody 10 harpdan uzyn bolmaly däl'
         }else{
            err.code = null;
        }

        setErrors({...errors, ...err})
        return err.name || err.code;
    }
    function importFromExcel(){
        AxiosHelper.importDiagnosesExcel().then((result)=>{
            if(result.status == 200){
                appendNotification('ok', 'success')
            }
        }).catch((e)=>handleErrors(e, logout))
    }
    function searchFromEl(){
        AxiosHelper.searchDiagnoses(filter).then((result)=>{
            if(result.status == 200){
                console.log(result.data);
            }
        }).catch((e)=>handleErrors(e, logout))
    }
    function handleSync() {
        if(window.confirm("Siz hakykatdan hem kesel kesgitlerini sinhronlaşdyrmakçymy?")){
            AxiosHelper.syncDiagnoses().then((result)=>{
                if(result.status == 200){
                    appendNotification("Kesel kesgitleri üstünlikli sinhronlaşdyryldy", 'success')
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle}
            isFilterable={true} filterBlock={
                <>
                <Filter filter={filter} setFilter={setFilter} key={'admin-diagnoses-filter-block'} />
                </>
            } 
            pageTitleEndComponent={
                <>
                {/* <Button theme="light" onClick={importFromExcel}><i className="bi bi-arrow-repeat"/></Button> */}
                <Button onClick={handleSync} theme={'warning'}>
                    Sync
                </Button>
                </>
            }
            startBlock={
                <>
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Kesel kesgidini üýtgetmek' :"Täze kesel kesgidini goşmak"} modalSize="modal-xl"
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} onButtonClick={()=>{setIsUpdate(false); setForm({title:'', code:'', status:undefined}); setErrors({})}}>
                    <Form form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save}/>
                </ModalWithButton>
                <IndexAllButton/>
                </>
            }/>
    )
}