import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { checkRequire, getFormattedDate, getString, positionTypes } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import Filter from "./filter";
import { handleErrors } from "../../../../utils/error-handler";
import TableStatusItem from "../../../../components/status-item/status-item";

export default function AdminPositions(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({title:''});
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:10, statuses:[]});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout, user} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-positions-modal';
    const pageKey="admin-positions-page";
    const pageTitle="Ulanyjy wezipeleri";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Ady" filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {getString(item?.name) || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Bölümi" filter={filter} setFilter={setFilter} targetAttribute='department'/>, content:(item)=>{
                return (
                    item?._department
                    ?
                    <span className="text-secondary">
                        {getString(item?._department?.name)}
                    </span>
                    :
                    '-//-'
                )
            }
        },
        {
            text:<Sort text="Wezipe görnüşi" filter={filter} setFilter={setFilter} targetAttribute='type'/>, content:(item)=>{
                return (
                    item?.type ?
                    <span className="text-slatgray fst-italic text-medium ">
                        {positionTypes[item?.type] }
                    </span>
                    :
                    '-//-'
                )
            }
        },
        {
            text:<Sort text="Orunlar" filter={filter} setFilter={setFilter} targetAttribute='status'/>, content:(item)=>{
                return (
                    <div className="p-0 m-0 text-center">
                        <span className="text-slatgray">
                            {`${item?.users?.length || 0}/${item?.seats}`}
                        </span>
                        <div className="d-flex align-items-center border border-secondary w-100 rounded-pill">
                            <div className="d-flex bg-primary rounded-pill" style={{height:5+'px', width:Math.ceil(item?.users?.length/item?.seats)*100+'%'}}/>
                            <div className="d-flex w-100" style={{height:5+'px'}}/>
                        </div>
                    </div>
                )
            }
        },
        {
            text:<Sort text="Tertip belgisi" filter={filter} setFilter={setFilter} targetAttribute='order'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?.order || '-//-'}
                    </span>
                )
            }
        },
        TableStatusItem(filter, setFilter),
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '/', true)}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu wezipäni ýok etmekçimi?")){
                                AxiosHelper.deletePosition(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Wezipe üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                }).catch((e)=>handleErrors(e))
                            }
                        }}>
                            <i className="bi bi-trash2"/>
                        </button>
                        <button className="btn btn-sm bg-accent-primary text-primary m-1" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"/>
                        </button>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getPositions(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updatePosition(form?.id, form, token) : AxiosHelper.createPosition(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Wezipe üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, logout, errors, setErrors)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
            err.name='Wezipe ady boş bolup bilmez!'
        }else if(form?.name?.length > 250){
            err.name='Wezipe ady 250 harpdan gysga bolmaly!'
        }

        if(!checkRequire(form?.department)){
            err.department='Wezipäniň degişli bölümini saýlaň!'
        }

        if(!checkRequire(form?.type)){
            err.type='Wezipäniň degişli görnüşini saýlaň!'
        }

        setErrors({...errors, ...err})
        return err.name || err.department || err.type;
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle}
            isFilterable={true} filterBlock={
                <Filter filter={filter} setFilter={setFilter} key={'admin-positions-filter-block'} />
            }
            startBlock={
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Wezipäni üýtgetmek' :"Täze wezipe goşmak"}
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} onButtonClick={()=>{setIsUpdate(false); setForm({title:'', department:'', type:''}); setErrors({})}}>
                    <Form form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save}/>
                </ModalWithButton>
            } />
    )
}