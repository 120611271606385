export default function InputWrapper({id, label, error, className='', labelClassName='', isTitle=true, isRequired = false, ...props}){
    return (
        <div className="mb-2">
            {
                isTitle &&  
                <label htmlFor={id} className={`form-label ${labelClassName}`}>
                    {label}
                    {
                        isRequired && 
                        <sup className="text-danger fw-bold ms-1">
                            <big>
                                *
                            </big>
                        </sup>
                    }
                </label>
            }
            <div className={"position-relative "+className}>
            {
                props.children
            }
            </div>
            <span className={`text-danger text-small px-2 ${error ? '' :'d-none'}`}>{error}</span>
        </div>
    )
}