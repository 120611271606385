import { useContext } from "react";
import { LangContext } from "../../../../context/LangContext";
import DashboardElements from "../../../../utils/dashboard_elements";
import Button from "../../../../components/button/button";
import { LIMITS } from "../../../../utils/main";
import Checkbox from "../../../../components/checkbox/checkbox";

export default function LimitForm({form, setForm, errors, setErrors}){
    const {langDictionary} = useContext(LangContext)
    const arr = [
        {title:langDictionary?.registration, value:DashboardElements.PATIENTS},
        {title:langDictionary?.visits, value:DashboardElements.VISITS},
        {title:langDictionary?.reception, value:DashboardElements.RECEPTION},
        {title:langDictionary?.laboratory, value:DashboardElements.LAB},
        {title:langDictionary?.radiology, value:DashboardElements.RAD},
        {title:langDictionary?.specializations, value:DashboardElements.SPEC},
        {title:langDictionary?.payments, value:DashboardElements.PAYMENTS}
    ]
    return(
        <div className="mb-3">
            <div className="border rounded-3 overflow-hidden">
                <div className="w-100 text-start text-slatgray d-flex align-items-center bg-light p-2 rounded-3 fw-500 cursor-pointer bottom-radius-0 border-bottom collapsed"
                    data-bs-toggle="collapse" aria-expanded="" data-bs-target={`#admin-roles-form-limit`} aria-controls="contents-collapse">
                    Elýeterlilik çägi
                    <Button theme="light" className="ms-auto">
                        <i className="bi bi-chevron-compact-down"/>
                    </Button>
                </div>
                <ul id={'admin-roles-form-limit'} className="nav-content list-unstyled tree mb-0 px-2 collapse">
                    <table className="table table-striped my-2 rounded-2 overflow-hidden">
                        <thead className="bg-accent-primary text-primary">
                            <tr>
                                <th></th>
                                {
                                    Object.entries(LIMITS).map(([key, value])=>{
                                        return(
                                            <th className="text-center">{value}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                        {
                            [
                                {title:'Ýüztutmalar', target:'visit_limit'},
                                {title:'Laborator barlaglar', target:'lab_limit'},
                                {title:'Radiologiki barlaglar', target:'rad_limit'},
                                {title:'Inçe hünärmen', target:'spec_limit'},
                                {title:'Enjamlaýyn', target:'inst_limit'},
                            ].map((item)=>{
                                return(
                                    <tr>
                                        <td>{item?.title}</td>
                                        {
                                            Object.keys(LIMITS).map((key)=>{
                                                return(
                                                    <td className="fs-4">
                                                        <Checkbox title={''} isChecked={form[item?.target]==key} 
                                                            onChange={()=>setForm({...form, [item.target]:key})}
                                                            wrapperOptions={{
                                                                className:'text-center'
                                                            }}
                                                            />
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </ul>
            </div>
        </div>
    )
}