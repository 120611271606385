import { appendNotification } from "../components/notification";

export function handleErrors(e, logout = ()=>{}, errors=null, setErrors=()=>{}){
  console.error(e);
  if(e?.code === "ERR_NETWORK"){
    
  }else if(e?.response?.status == 403){
      appendNotification('Siziň rugsatlary almaga ýeterlik priwelegiýaňyz ýok!', 'danger');
  }else if(e?.response?.status == 401){
    logout()
  }else if(e?.response?.status == 400){
    let message = e?.response?.data?.message || 'Ýalňyşlyklary düzedip täzeden synanyşyň!'
    appendNotification(message, 'danger')
    if(typeof setErrors === 'function'){
      setErrors(e?.response?.data?.errors)
    }
  }else if(e?.response?.status == 500){
      appendNotification('Serwerde näsazlyk ýüze çykdy!', 'danger')
  }else{
      appendNotification('Näsazlyk ýüze çykdy!', 'danger')
  }
}
