import NoResult from "../noResult/noResult";
import Pagination from "../pagination/pagination";
import Summary from "../summary/summary";
import AdminTable from "../table/table";

export default function DataViewer({data, items, filter, setFilter, count, refresh, isRefreshable=true, onDoubleClick=()=>{}, onClick=()=>{}, isPaginate = true}){
    return (
        <div className="data-viewer">
            {
                data && data?.length ?
                <>
                    <AdminTable data={data} items={items} key={'statistic-records'} isRefreshable={isRefreshable} refreshFunction={refresh} onDoubleClick={onDoubleClick} onClick={onClick}/>
                    {
                        isPaginate ?
                        <div className="d-flex align-items-center justify-content-between px-2">
                            <Summary count={count} filter={filter} />
                            {
                                count > filter?.limit ? 
                                <Pagination filter={filter} setFilter={setFilter}  count={count} />
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                </>
                :
                <NoResult isRefreshable={isRefreshable} refreshFunction={refresh}/>
            }
        </div>
    )
}