import { useContext, useEffect, useState } from "react";
import InputBlock from "../../../../components/input-block/input-block";
import { epidStatuses, getArrayFromObject, positionTypes } from "../../../../utils/main";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../../utils/error-handler";
import { AuthContext } from "../../../../context/AuthContext";
import Select from "../../../../components/select/select";
export default function Form({form, setForm, errors, setErrors, saveFunction}){
    const [options, setOptions] = useState({departments:[]})
    const {logout} = useContext(AuthContext)
    useEffect(()=>{
        AxiosHelper.getDepartment('filter').then((result)=>{
            if(result.status == 200){
                setOptions({...options, departments:result.data?.data})
            }
        }).catch((e)=>handleErrors(e, logout))
    }, [])
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
            <div className="row">
                <div className="col-md-12">
                    <InputBlock error={errors?.name} id={'admin-positions-form-name'} label={'Ady'} 
                        value={form?.name} setValue={(e)=>{
                            setErrors({...errors, name:''})
                            setForm({...form, name:e})
                        }}
                        icon={'bi bi-keyboard'} />
                </div>
                <div className="col-md-12">
                    <Select error={errors?.department} id={'admin-positions-form-department'} label={'Bölüm'} 
                        value={form?.department} setValue={(e)=>{
                            setErrors({...errors, department:''})
                            setForm({...form, department:e})
                        }} items={options?.departments}
                        icon={'bi bi-keyboard'} />
                </div>
                <div className="col-md-6">
                    <Select error={errors?.type} id={'admin-positions-form-type'} label={'Wezipe görnüşi'} 
                        value={form?.type} setValue={(e)=>{
                            setForm({...form, type:e})
                        }} items={getArrayFromObject(positionTypes)}
                        icon={'bi bi-keyboard'} />
                </div>
                <div className="col-md-6">
                    <InputBlock error={errors?.seats} id={'admin-positions-form-seats'} label={'Orunlar'} 
                        value={form?.seats} setValue={(e)=>{
                            setErrors({...errors, seats:''})
                            setForm({...form, seats:e})
                        }}
                        icon={'bi bi-keyboard'} />
                </div>
                <div className="col-md-6">
                    <InputBlock error={errors?.order} id={'admin-bed-form-order'} label={'Tertip belgisi'} 
                        value={form?.order} setValue={(e)=>setForm({...form, order:e})} type="number"
                        icon={'bi bi-123'} />
                </div>
                <div className="col-md-6">
                    <Select error={errors?.status} id={'analyse-type-status'} items={epidStatuses} label={"Statusy"}
                        value={form?.status} setValue={(e)=>setForm({...form, status:e})} itemTitleLabel="title" itemValueLabel="value"/>
                </div>
            </div>
        </form>
    )
}