export default class Configurations {
    // ----------------------------- VARIABLES -----------------------------

    /**
     * Application running mode 
     * It's must be in [DEV or PUB]
     * DEV - for develeopment case
     * PUB - for publish case
     *  */ 
    static MODE = "PUB"

    /**
     * Hospital code for the application
     */
    static HOSPITAL_CODE = "SSU"

    /**
     * Company details for the displaying in application
     */
    static COMPANY_NAME = "Älem Tilsimat"
    static COMPANY_APPENDIX = "HJ"

    /**
     * Application shortname and fullname
     */
    static APPNAME = "MSSGU"
    static FULLAPPNAME = "MILLI SANLY SAGLYGY GORAÝYŞ ULGAMY"
    static VERSION = "2.2"

    /**
     * Backend server for the application
     * devServer - uses for the development case
     * pubServer - uses for the publishment case
     */
    static devServer = "http://localhost:8815/"
    static pubServer = "https://ssu.alemtilsimat.com/api/"


    static devSocketServer = "http://localhost:8815/"
    static pubSocketServer = "https://ssu.alemtilsimat.com/"

    static socketServer = this.MODE === 'PUB' ? this.pubSocketServer : this.devSocketServer

    /**
     * Resources main path for the application
     */
    static devMainPath = ""
    static pubMainPath = ""

    /**
     * Axios timeout in milliseconds
     */
    static axiosTimeout = 15000

    /**
     * Logout status code for the axios requests
     */
    static forbiddenStatus = 401

    // ----------------------------- FUNCTIONS -----------------------------
    
    static getServer(){
        return (this.MODE === 'PUB' ? this.pubServer : this.devServer)
    }

    static getStaticServer(){
        return this.getServer()+'static/'
    }

    static getMainPath(mode){
        switch (mode) {
            case "DEV":
                return this.devMainPath
            case "PUB":
                return this.pubMainPath
            default:
                throw new Error("Application run mode isn't correct!")
        }
    }

    static BOOK_KEEPING_URI = 'https://alemhasap.alemtilsimat.com'
    static WAREHOUSE_URI = 'https://ammar.alemtilsimat.com'
}