class CONSTANTS {
    static ST_DIAGNOSE = 10
    static CL_DIAGNOSE = 20
    static FN_DIAGNOSE = 30
    static PM_DIAGNOSE = 40

    static DG_TYPES = [
        {id:this.ST_DIAGNOSE, name:'Başlangyç'},
        {id:this.CL_DIAGNOSE, name:'Kliniki'},
        {id:this.FN_DIAGNOSE, name:'Gutarnykly'},
        {id:this.PM_DIAGNOSE, name:'Post mortem'}
    ]

    static PARENT_FATHER = 10
    static PARENT_MOTHER = 20
    static PARENT_CHILDREN = 30

    static QS_STRING = 0
    static QS_BOOL = 10
    static QS_TEXT = 20
    static QS_MULTIPLE = 30
    static QS_SINGLE = 40

    static QS_TYPES = [
        {id:this.QS_STRING, name:'Gysga tekst'},
        {id:this.QS_TEXT, name:'Uzyn tekst'},
        {id:this.QS_BOOL, name:'Logiki baha'},
        {id:this.QS_SINGLE, name:'Bir parametr'},
        {id:this.QS_MULTIPLE, name:'Köp parametr'}
    ]

    static QS_TYPES_OBJ = {
        [this.QS_STRING] : 'Gysga tekst',
        [this.QS_TEXT] : 'Uzyn tekst',
        [this.QS_BOOL] : 'Logiki baha',
        [this.QS_SINGLE] : 'Bir parametr',
        [this.QS_MULTIPLE] : 'Köp parametr'
    }
    
    static CL_SYMPTOM_TYPES = [
        {id:this.QS_MULTIPLE, name:'Köp parametr'},
        {id:this.QS_STRING, name:'Gysga tekst'}
    ]
    
    static PR_DRUG = 0
    static PR_DIET = 10
    static PR_PROCEDURE = 20
    static PR_ADVICE = 30
    static PR_LAB = 40
    static PR_INS = 50

    static PR_TYPES = [
        {id:this.PR_DRUG, name:'Derman serişdeler'},
        {id:this.PR_DIET, name:'Berhizler'},
        {id:this.PR_PROCEDURE, name:'Emler'}
    ]

    static PR_TYPES_OBJ = {
        [this.PR_DRUG] :'Derman serişdeler',
        [this.PR_DIET] :'Berhizler',
        [this.PR_PROCEDURE] :'Emler'
    }

    static PR_WAIT = 0
    static PR_COMPLETE = 10
    static PR_REJECT = 20

    static PR_STATUSES = [
        {id:this.PR_WAIT, name:'Garaşylýan'},
        {id:this.PR_COMPLETE, name:'Ýerine ýetirilen'},
        {id:this.PR_REJECT, name:'Ret edilen'},
    ]

    static PR_STATUSES_OBJ = {
        [this.PR_WAIT] :'Garaşylýan',
        [this.PR_COMPLETE] :'Ýerine ýetirilen',
        [this.PR_REJECT] :'Ret edilen'
    }

    static MORNING = 10
    static NOON = 20
    static EVENING = 30
    static NIGHT = 40

    static TIMES = [
        {id:this.MORNING, name:'Irden'},
        {id:this.NOON, name:'Günortan'},
        {id:this.EVENING, name:'Agşam'},
        {id:this.NIGHT, name:'Gije'},
    ]

    static TIMES_OBJ = {
        [this.MORNING] :'Irden',
        [this.NOON]:'Günortan',
        [this.EVENING]:'Agşam',
        [this.NIGHT]:'Gije'
    }

    static PRE_OP_DG = 0
    static POST_OP_DG = 20

    static OP_DGS = [
        {id:this.PRE_OP_DG, name:'Operasiýadan öňki kesel kesgidi'},
        {id:this.POST_OP_DG, name:'Operasiýadan soňky kesel kesgidi'},
    ]

    
    static OP_AC_ARB = 5
    static OP_AC_DEP = 10
    static OP_AC_HOME = 20

    static OP_ACS = [
        {id:this.OP_AC_ARB, name:'ARB'},
        {id:this.OP_AC_DEP, name:'Bölüme'},
        {id:this.OP_AC_HOME, name:'Öýüne'}
    ]
    static OP_ACS_OBJ = {
        [this.OP_AC_ARB]:'ARB',
        [this.OP_AC_DEP]:'Bölüme',
        [this.OP_AC_HOME]:'Öýüne'
    }

    static SCIENCE_TYPE_1 = 10
    static SCIENCE_TYPE_2 = 20
    static SCIENCE_TYPE_3 = 30
    static SCIENCE_TYPE_4 = 40

    static SCIENCE_TYPES = [
        {id:this.SCIENCE_TYPE_1, name:'Retrospektiw'},
        {id:this.SCIENCE_TYPE_2, name:'Prospektiw'},
        {id:this.SCIENCE_TYPE_3, name:'Meýilleşdirilen'},
        // {id:this.SCIENCE_TYPE_3, name:'Aprobasiýa'},
        // {id:this.SCIENCE_TYPE_4, name:'Statistika'}
    ]

    static SCIENCE_TYPES_OBJ = {
        [this.SCIENCE_TYPE_1]:'Retrospektiw',
        [this.SCIENCE_TYPE_2]:'Prospektiw',
        [this.SCIENCE_TYPE_3]:'Meýilleşdirilen',
        // [this.SCIENCE_TYPE_3]:'Aprobasiýa',
        // [this.SCIENCE_TYPE_4]:'Statistika'
    }

    static START_TEMPERATURE = 32
    static END_TEMPERATURE = 45
    static MIN_PRESSURE = 0
    static MAX_PRESSURE = 300

    static SHIT_1 = 10
    static SHIT_2 = 20
    static SHIT_3 = 30
    static SHIT_4 = 40

    static SHIT_STATES = [
        {id:this.SHIT_1, name:'Kadaly', theme:'primary'},
        {id:this.SHIT_2, name:'Ganly', theme:'danger'},
        {id:this.SHIT_3, name:'Suwuk', theme:'warning'},
        {id:this.SHIT_4, name:'Reňksiz', theme:'secondary'}
    ]
    static SHITS = {
        [this.SHIT_1] : {name:'Kadaly', theme:'primary'},
        [this.SHIT_2] : {name:'Ganly', theme:'danger'},
        [this.SHIT_3] : {name:'Suwuk', theme:'warning'},
        [this.SHIT_4] : {name:'Reňksiz', theme:'secondary'}
    }

    static URINE_STATES = [
        {id:this.SHIT_1, name:'Kadaly', theme:'primary'},
        {id:this.SHIT_2, name:'Ganly', theme:'danger'},
        {id:this.SHIT_3, name:'Bulanyk', theme:'warning'}
    ]
    static URINES = {
        [this.SHIT_1]:{name:'Kadaly', theme:'primary'},
        [this.SHIT_2]:{name:'Ganly', theme:'danger'},
        [this.SHIT_3]:{name:'Bulanyk', theme:'warning'}
    }

    static MEMORY_1 = 10
    static MEMORY_2 = 20
    static MEMORY_3 = 30
    
    static MEMORY_STATES = [
        {id:this.MEMORY_1, name:'Aýdyň', theme:'primary'},
        {id:this.MEMORY_2, name:'Huşsyz', theme:'danger'},
        {id:this.MEMORY_3, name:'Haýal', theme:'warning'}
    ]
    
    static MEMORIES = {
        [this.MEMORY_1]:{name:'Aýdyň', theme:'primary'},
        [this.MEMORY_2]:{name:'Huşsyz', theme:'danger'},
        [this.MEMORY_3]:{name:'Haýal', theme:'warning'}
    }

    
    static STATE_1 = 10
    static STATE_2 = 20
    static STATE_3 = 30
    static STATE_4 = 40
    
    static STATES = [
        {id:this.STATE_1, name:'Gowy', theme:'success'},
        {id:this.STATE_2, name:'Kangatlanarly', theme:'primary'},
        {id:this.STATE_3, name:'Kangatlanarsyz', theme:'warning'},
        {id:this.STATE_4, name:'Agyr', theme:'danger'}
    ]

    static STATES_OBJ = {
        [this.STATE_1] : {name:'Gowy', theme:'success'},
        [this.STATE_2] : {name:'Kangatlanarly', theme:'primary'},
        [this.STATE_3] : {name:'Kangatlanarsyz', theme:'warning'},
        [this.STATE_4] : {name:'Agyr', theme:'danger'}
    }

    static ORGAN_1 = 10
    static ORGAN_2 = 20
    static ORGAN_3 = 30
    static ORGAN_4 = 40
    static ORGAN_5 = 50
    static ORGAN_6 = 60
    static ORGAN_7 = 70
    static ORGAN_8 = 80

    static OPERATION_ORGANS = {
        [this.ORGAN_1]:'Göz agzalarynda',
        [this.ORGAN_2]:'Gulak, burun, bokurdak agzalarynda',
        [this.ORGAN_3]:'Damarlarda',
        [this.ORGAN_4]:'Garyn boşlugynyň agzalarynda',
        [this.ORGAN_5]:'Aýal jyns agzalarynda',
        [this.ORGAN_6]:'Süňk-myşsa ulgamynda',
        [this.ORGAN_7]:'Göwüs mäzinde',
        [this.ORGAN_8]:'Deri we deriasty mäzinde'
    }

    static RAD_FILE_DCM = 10
    static RAD_FILE_PDF = 20
    static RAD_FILE_ZIP = 30
    static RAD_FILES = [
        {id:[this.RAD_FILE_DCM], name:'Barlag suraty (.dcm)'},
        {id:[this.RAD_FILE_PDF], name:'Barlag netijesi (.pdf)'},
        {id:[this.RAD_FILE_ZIP], name:'Arhiw faýl (.zip)'},
    ]

    static LANG_KEY = "app_lang"

    static HOSPITAL = 'Halkara fiziologiýa ylmy-kliniki merkezi'

    static HOS_FIZ = 'AG_FIZ'
    static HOS_REH = 'AG_REH'
    static HOSPITALS = {
        [this.HOS_FIZ]:"AG fiziologiýa",
        [this.HOS_REH]:"AG REHAB",
    }

    static BLOOD_1 = 10
    static BLOOD_2 = 20
    static BLOOD_3 = 30
    static BLOOD_4 = 40
    static BLOOD_5 = 50
    static BLOOD_6 = 60
    static BLOOD_7 = 70
    static BLOOD_8 = 80

    static BLOODS = {
        [this.BLOOD_1] : 'I(O) RH+',
        [this.BLOOD_2] : 'II(A) RH+',
        [this.BLOOD_3] : 'III(B) RH+',
        [this.BLOOD_4] : 'IV(AB) RH+',
        [this.BLOOD_5] : 'I(O) RH-',
        [this.BLOOD_6] : 'II(A) RH-',
        [this.BLOOD_7] : 'III(B) RH-',
        [this.BLOOD_8] : 'IV(AB) RH-'
    }
    
    static PARENT_TYPE_1 = 10
    static PARENT_TYPE_2 = 20
    static PARENT_TYPE_3 = 30
    static PARENT_TYPE_4 = 40
    // static PARENT_TYPE_5 = 50
    // static PARENT_TYPE_6 = 60

    static PARENT_TYPES = {
        [this.PARENT_TYPE_1]:'Ejesi',
        [this.PARENT_TYPE_2]:'Kakasy',
        [this.PARENT_TYPE_3]:'Çagasy',
        [this.PARENT_TYPE_4]:'Ýanýoldaşy'
    }

    static RE_PARENT_TYPES = {
        [this.PARENT_TYPE_1]:'Çagasy',
        [this.PARENT_TYPE_2]:'Çagasy',
        [this.PARENT_TYPE_3]:'Ene-atasy',
        [this.PARENT_TYPE_4]:'Ýanýoldaşy'
    }
    

    static PERIOD_TYPE_1 = 1
    static PERIOD_TYPE_2 = 2
    static PERIOD_TYPE_3 = 3
    static PERIOD_TYPE_7 = 7
    static PERIOD_TYPE_8 = 8
    static PERIOD_TYPE_9 = 9
    static PERIOD_TYPE_10 = 10
    static PERIOD_TYPE_11 = 11
    static PERIOD_TYPE_12 = 12
    static PERIOD_TYPE_13 = 13
    static PERIOD_TYPE_14 = 14

    static PERIOD_TYPES = [
        {id:this.PERIOD_TYPE_1, name:'Her gün'},
        {id:this.PERIOD_TYPE_2, name:'2 günden'},
        {id:this.PERIOD_TYPE_3, name:'3 günden'},
        {id:this.PERIOD_TYPE_7, name:'Hepdede'},
        {id:this.PERIOD_TYPE_8, name:'Her sagatda'},
        {id:this.PERIOD_TYPE_9, name:'2 sagatdan'},
        {id:this.PERIOD_TYPE_10, name:'4 sagatdan'},
        {id:this.PERIOD_TYPE_11, name:'6 sagatdan'},
        {id:this.PERIOD_TYPE_12, name:'8 sagatdan'},
        {id:this.PERIOD_TYPE_13, name:'10 sagatdan'},
        {id:this.PERIOD_TYPE_14, name:'12 sagatdan'}
    ]

}

export default CONSTANTS;