import { checkOnlyLetters, checkPhone, checkRequire } from "../../../../utils/main";
import PageTitle from "../../../../components/page-title/page-title";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { useNavigate } from "react-router-dom";
import FormStep from "../../../../components/form-step/step";
import UserDataElement from "./form-elements/user-data";
import MainElement from "./form-elements/main";
import OfficeElement from "./form-elements/office";
import { handleErrors } from "../../../../utils/error-handler";
import ScrollToTop from "../../../../components/to-top";
export default function Form({form, setForm, errors, setErrors, isUpdate}){
    const [options, setOptions] = useState({departments:[], roles:[], positions:[]})
    const MAIN_BLOCK_ID = 'main-block-steps';
    const {isReady, logout} = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getUserFilter().then((result)=>{
                if(result.status == 200){
                    setOptions(result.data)
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }, [isReady]) 
    function save(){
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateUser(form?.id, form) : AxiosHelper.createUser(form)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Ulanyjy üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        navigate('/settings/users')
                    }
                }).catch((e)=>{
                    handleErrors(e, logout, errors, setErrors)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
                console.log(errors);
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
           err.name='Ulanyjynyň adyny giriziň'
        }else if(form?.name?.length > 100){
            err.name='Ulanyjynyň ady 100 harpdan uzyn bolmaly däl'
        }else if(!checkOnlyLetters(form?.name)){
            err.name='Ulanyjynyň ady diňe harplardan ybarat bolmaly';
        }

        if(!checkRequire(form?.surname)){
           err.surname='Ulanyjynyň familiýasyny giriziň'
        }else if(form?.surname?.length > 100){
            err.surname='Ulanyjynyň familiýasy 100 harpdan uzyn bolmaly däl'
        }else if(!checkOnlyLetters(form?.surname)){
            err.surname='Ulanyjynyň familiýasy diňe harplardan ybarat bolmaly';
        }

        if(form?.second_name?.length > 100){
            err.second_name='Ulanyjynyň atasynyň ady 100 harpdan uzyn bolmaly däl'
        }else if(form?.second_name && !checkOnlyLetters(form?.second_name)){
            err.second_name='Ulanyjynyň atasynyň ady diňe harplardan ybarat bolmaly';
        }

        if(!checkRequire(form?.phone_number)){
            err.phone_number='Ulanyjynyň telefon belgisini giriziň'
        }else if(!checkPhone(form?.phone_number)){
            err.phone_number='Ulanyjynyň telefon belgisini dogry giriziň';
        }

        if(!checkRequire(form?.phone_number)){
             err.phone_number='Ulanyjynyň telefon belgisini giriziň'
        }else if(!checkPhone(form?.phone_number)){
            err.phone_number='Ulanyjynyň telefon belgisini dogry giriziň';
        }

        if(!checkRequire(form?.name)){
            err.login='Ulanyjy adyny giriziň'
        }else if(form?.login?.length > 100){
            err.login='Ulanyjy ady 100 harpdan uzyn bolmaly däl'
        }

        if(!isUpdate &&!checkRequire(form?.password)){
            err.password='Açar sözüni giriziň'
        }else if(!isUpdate && form?.password?.length > 100){
            err.password='Açar sözi 100 harpdan uzyn bolmaly däl'
        }

        if(form?.status === null || form?.status === undefined){
            err.status='Ulanyjy statusyny saýlaň'
        }

        // if(form?.role === null || form?.role === undefined){
        //     err.role='Ulanyjy roluny saýlaň'
        // }else{
        //     err.role = null;
        // }

        // if(!checkRequire(form?.department)){
        //     err.office='Ulanyjynyň degişli bolan edarasyny saýlaň'
        // }else{
        //     err.office = null;
        // }
        setErrors({...errors, ...err})
        return err.name || err.surname || err.second_name || err.phone_number || err.login || err.password || err.status || err.role || err.office;
    }
    return (
        <>
            <PageTitle titleClass={'border-bottom border-1 mb--0 pb-2 px-1'} title={!isUpdate ? "Täze ulanyjy goşmak" : 'Ulanyjyny üýtgetmek'} endComponent={
                <div className="d-flex align-items-center">
                    <button className="btn btn-danger btn-sm px-3 me-2" onClick={()=>navigate('/settings/users')}>
                        <i className="bi bi-x-lg me-2"/>
                        Ret etmek
                    </button>
                    <button className="btn btn-slat btn-sm px-3" onClick={save}>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                        <i className="bi bi-check2-square me-2"/>
                        Ýatda saklamak
                    </button>
                </div>
            }/>
            <div className="vh-100 overflow--auto px-1 pt-2" style={{paddingBottom:150+'px'}} id={MAIN_BLOCK_ID}>
                <div className="steps">
                    <FormStep id={'form-user-data'} stepName={"Ädim 1"} 
                        title={"Esasy maglumatlar"} isActive={true} key={'form-user-data-step'}
                        content={
                            <UserDataElement errors={errors} form={form} setForm={setForm} key={'form-user-user-data-element'} setErrors={setErrors} />
                        }/>
                    <FormStep id={'form-user-login-data'} stepName={"Ädim 2"} 
                        title={"Giriş maglumatlary"} isActive={true} key={'form-user-login-data-step'}
                        content={
                            <MainElement errors={errors} form={form} setForm={setForm} key={'form-user-main-element'}  setErrors={setErrors}/>
                        }/>
                    <FormStep id={'form-user-office-data'} stepName={"Ädim 3"} 
                        title={"Degişli bölümi we wezipesi"} isActive={true} key={'form-user-office-data-step'}
                        content={
                            <OfficeElement form={form} setForm={setForm} errors={errors} setErrors={setErrors} 
                                options={options} key={'form-user-office-element'} />
                        }/>
                </div>
            </div>
            <div className="position-absolute bottom-0 end-0 m-3 me-4 mb-5">
                <ScrollToTop id={MAIN_BLOCK_ID}/>
            </div>
        </>
    )
}