import { useContext, useEffect, useState } from "react"
import AdminWrapper from "../Wrapper"
import JumbotronButton from "../../../components/jumbotron-button/jumbotron-button"
import { LangContext } from "../../../context/LangContext"
import styles from './settings.module.css'
import { Link, useParams } from "react-router-dom"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import Settings from "../../../utils/settings"
import { AuthContext } from "../../../context/AuthContext"


export default function AdminSettings(){
    const {langDictionary} = useContext(LangContext)
    const params = useParams()
    const {height} = useWindowDimensions()
    const [allowList, setAllowList] = useState([])
    const {user, isReady} = useContext(AuthContext)
    const sorted_items = [
        {
            title:'Ulanyjylary dolandyryş', 
            children:[
                {
                    icon:<img src="/img/user.png" className="jumbotron-button-img" />, 
                    title:'Ulanyjylar', 
                    link:'/users', 
                    key:Settings.USERS
                },
                {
                    icon:<img src="/img/user.png" className="jumbotron-button-img" />, 
                    title:'Ulanyjy rollary', 
                    link:'/roles', 
                    key:Settings.ROLES
                },
                {
                    icon:<img src="/img/user.png" className="jumbotron-button-img" />, 
                    title:'Wezipeler', 
                    link:'/positions', 
                    key:Settings.POSITIONS
                },
                // {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'Aspirantlar', link:'/aspirants'},
                // {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'Milletler', link:'/origins'},
                // {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'Bilim derejeleri', link:'/edu-grades'},
                // {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'Ylmy derejeler', link:'/sc-grades'},
                // {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'Okuw jaýlary', link:'/edu-institutes'},
                // {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'Aspirantura okuw görnüşleri', link:'/edu-types'},
                // {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'Ylmy hünärler', link:'/sc-specs'},
                // {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'Ylmy barlaghanalar', link:'/sc-labs'},
            ]
        },
        {
            title:'Raýatlary dolandyryş', 
            children:[
                {
                    icon:<img src="/img/document.png" className="jumbotron-button-img" />, 
                    title:'Resminama görnüşleri', 
                    link:'/document-types', 
                    key:Settings.DOCUMENT_TYPES
                },
                {
                    icon:<img src="/img/passport-series.png" className="jumbotron-button-img" />, 
                    title:'Seriýa belgileri', 
                    link:'/document-serial-keys', 
                    key:Settings.DOCUMENT_SERIAL_KEYS
                },
                {
                    icon:<img src="/img/diagnose.png" className="jumbotron-button-img" />, 
                    title:'Ýeňillik kategoriýalary', 
                    link:'/benefits', 
                    key:Settings.BENEFITS
                },
                {
                    icon:<img src="/img/diagnose.png" className="jumbotron-button-img" />, 
                    title:'Bilim derejeleri', 
                    link:'/education-grades', 
                    key:Settings.BENEFITS
                },
                {
                    icon:<img src="/img/diagnose.png" className="jumbotron-button-img" />, 
                    title:'Garyndaşlyk görnüşleri', 
                    link:'/relation-types', 
                    key:Settings.RELATION_TYPES
                },
                {
                    icon:<img src="/img/diagnose.png" className="jumbotron-button-img" />, 
                    title:'Kesel kesgidi', 
                    link:'/diagnoses', 
                    key:Settings.DIAGNOSES
                
                },
                {
                    icon:<img src="/img/visit.png" className="jumbotron-button-img" />, 
                    title:'Zyýanly endikler', 
                    link:'/bad-habits', 
                    key:Settings.BAD_HABITS
                },
                {
                    icon:<img src="/img/visit.png" className="jumbotron-button-img" />, 
                    title:'Mahsus keseller', 
                    link:'/like-ills', 
                    key:Settings.LIKE_ILLS
                },
                {
                    icon:<img src="/img/diagnose.png" className="jumbotron-button-img" />, 
                    title:'Register görnüşi', 
                    link:'/registration-types', 
                    key:Settings.REGISTRATION_TYPE
                }
            ]
        },
        {
            title:'Administratiw dolandyryş', 
            children:[
                {
                    icon:<img src="/img/regions.png" className="jumbotron-button-img" />, 
                    title:'Döwletler', 
                    link:'/countries', 
                    key:Settings.COUNTRIES
                },
                {
                    icon:<img src="/img/regions.png" className="jumbotron-button-img" />, 
                    title:'Welaýatlar', 
                    link:'/regions', 
                    key:Settings.REGIONS
                },
                {
                    icon:<img src="/img/villages.png" className="jumbotron-button-img" />, 
                    title:'Etrap/şäher', 
                    link:'/districts', 
                    key:Settings.DISTRICTS
                },
                {
                    icon:<img src="/img/villages.png" className="jumbotron-button-img" />, 
                    title:'Etr. şäher/Şäherçe/oba', 
                    link:'/villages', 
                    key:Settings.VILLAGES
                },
                {
                    icon:<img src="/img/villages.png" className="jumbotron-button-img" />, 
                    title:'Köçe', 
                    link:'/streets', 
                    key:Settings.VILLAGES
                },
                {
                    icon:<img src="/img/villages.png" className="jumbotron-button-img" />, 
                    title:'Jaý', 
                    link:'/homes', 
                    key:Settings.VILLAGES
                },
                {
                    icon:<img src="/img/villages.png" className="jumbotron-button-img" />, 
                    title:'Öý', 
                    link:'/apartments', 
                    key:Settings.VILLAGES
                },
                {
                    icon:<img src="/img/office.png" className="jumbotron-button-img" />, 
                    title:'Bölümler', 
                    link:'/departments', 
                    key:Settings.DEPARTMENTS
                },
                {
                    icon:<img src="/img/office.png" className="jumbotron-button-img" />, 
                    title:'Hassahanalar', 
                    link:'/hospitals', 
                    key:Settings.DEPARTMENTS
                }
                // {icon:<img src="/img/office.png" className="jumbotron-button-img" />, title:'Edaralar', link:'/offices'},
            ]
        },
        {title:'Administrirleme', children:[
            {icon:<img src="/img/virus.png" className="jumbotron-button-img" />, title:'Loglar', link:'/logs', key:Settings.LOGS},
            {icon:<img src="/img/virus.png" className="jumbotron-button-img" />, title:'Dumplar', link:'/dumps', key:Settings.DUMPS},
        ]},
    ]
    useEffect(()=>{
        // if(isReady && user?._role && user?._role?.modules){
        if(isReady){
            const arr = []
            sorted_items.map((item)=>{
                const control_map = item.children.map(_=>_.key)
                // if(control_map.some(_item=>user?._role?.modules.includes(_item))){
                    arr.push(item)
                // }
            })
            setAllowList(arr)
        }
    }, [user, isReady])
    return (
        <AdminWrapper isSidebar={false}>
            <div className="d-flex align-items-center justify-content-between text-slatgray mb-3">
                <h5>{langDictionary?.settings}</h5>
            </div>
            <div className="d-flex">
                <div className={styles.sidebar}>
                {
                    allowList.map((item, index)=>{
                        return(
                            <Link to={`/settings/sorted/${index+1}`} className={`btn  btn-sm w-100 my-1 text-slatgray border ${params?.type == index+1 || !params.type && !index ? 'bg-accent-primary text-primary box-shadow' : ''}`}>
                                {item?.title}
                            </Link>
                        )
                    })
                }
                </div>
                <div className="w-100 px-3">
                    <div class="row align-items-md-stretch justify-content-center" style={{maxHeight:(height-118)+'px', overflowY:'auto'}}>
                        {
                            allowList && allowList?.length ?
                            allowList[(params?.type-1) || 0]?.children.map((item, index)=>{
                                {/* if(user?._role?.modules.includes(item.key)){ */}
                                    return (
                                        <div class={`col-md-4 ${index>2 && 'mt-4'}`}>
                                            <JumbotronButton icon={item?.icon} link={`/settings${item?.link}`} title={item?.title} />
                                        </div>
                                    )
                                {/* } */}
                            })
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </AdminWrapper>
    )
}