import { useContext, useEffect, useRef, useState } from "react";
import ReportsFilter from "../../../components/filter-modal";
import InputBlock from "../../../components/input-block/input-block";
import Select from "../../../components/select/select";
import { getArrayFromObject, maxDate, minDate } from "../../../utils/main";
import { LangContext } from "../../../context/LangContext";
import { AuthContext } from "../../../context/AuthContext";
import AxiosHelper from "../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../utils/error-handler";
import STATUSES from "../../../utils/statuses";

export default function Filter({filter, setFilter}){
    const closeRef = useRef()
    const {isReady, logout} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    const modalId = 'personified-records-filter-modal'
    const [options, setOptions] = useState({
        types:[],
        methods:[],
        epid_anamnezes:[],
        visit_results:[],
        counts:[],
        bad_habits:[],
        like_ills:[]
    })
    const [shadowFilter, setShadowFilter] = useState()
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getVisitElements().then((result)=>{
                if(result.status == 200){
                    setOptions({...result.data})
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }, [isReady])
    return (
        <ReportsFilter title={langDictionary?.filter} closRef={closeRef} modalId={modalId} 
            clearFilter={()=>{
                setShadowFilter({start_date:'', end_date:'', text:'', type:'', method:'', epid_anamnez:'', visit_count:'', habit:'', ill:'', status:'', visit_result:''})
                setFilter({limit:filter?.limit, page:filter?.page, text:''})
            }}
            isSaveable={true} 
            saveFunction={()=>{
                setFilter({...filter, ...shadowFilter})
            }}>
                <div className="row">
                    <div className="col-md-12">
                        <InputBlock error={null} id={'per-filter-text-input'} label={langDictionary?.search_text}
                            value={shadowFilter?.text} setValue={(e)=>setShadowFilter({...shadowFilter, text:e})}
                            icon={'bi bi-search'}  placeholder="..." />
                    </div>
                    <div className="col-md-6">
                        <InputBlock error={null} id={'per-filter-start-input'} type={'date'} 
                            inputProps={{min:minDate, max:maxDate}} label={langDictionary?.start_date}
                            value={shadowFilter?.start_date} setValue={(e)=>setShadowFilter({...shadowFilter, start_date:e})} />
                    </div>
                    <div className="col-md-6">
                        <InputBlock error={null} id={'per-filter-end-input'} type={'date'} 
                            inputProps={{min:minDate, max:maxDate}} label={langDictionary?.end_date}
                            value={shadowFilter?.end_date} setValue={(e)=>setShadowFilter({...shadowFilter, end_date:e})} />
                    </div>
                    <div className="col-md-6">
                        <Select  error={null} id={'visit-filter-type'} items={options?.types || []} label={langDictionary?.visit_type} 
                            value={shadowFilter?.type} setValue={(e)=>setShadowFilter({...shadowFilter, type:e})} />
                    </div>
                    <div className="col-md-6">
                        <Select  error={null} id={'visit-filter-method'} items={options?.methods || []} label={langDictionary?.visit_method} 
                            value={shadowFilter?.method} setValue={(e)=>setShadowFilter({...shadowFilter, method:e})} />
                    </div>
                    <div className="col-md-6">
                        <Select  error={null} id={'visit-filter-epid_anamnez'} items={options?.epid_anamnezes || []} label={langDictionary?.epidemiological_anamnez} 
                            value={shadowFilter?.epid_anamnez} setValue={(e)=>setShadowFilter({...shadowFilter, epid_anamnez:e})} />
                    </div>
                    <div className="col-md-6">
                        <Select  error={null} id={'visit-filter-visit_count'} items={options?.counts || []} label={langDictionary?.count_with_same_ill} 
                            value={shadowFilter?.visit_count} setValue={(e)=>setShadowFilter({...shadowFilter, visit_count:e})} />
                    </div>
                    <div className="col-md-6">
                        <Select  error={null} id={'visit-filter-habit'} items={options?.bad_habits || []} label={langDictionary?.bad_habits} 
                            value={shadowFilter?.habit} setValue={(e)=>setShadowFilter({...shadowFilter, habit:e})} />
                    </div>
                    <div className="col-md-6">
                        <Select  error={null} id={'visit-filter-ill'} items={options?.like_ills || []} label={langDictionary?.relation_ills} 
                            value={shadowFilter?.ill} setValue={(e)=>setShadowFilter({...shadowFilter, ill:e})} />
                    </div>
                    <div className="col-md-6">
                        <Select  error={null} id={'visit-filter-visit_result'} items={options?.visit_results || []} label={langDictionary?.visit_result} 
                            value={shadowFilter?.visit_result} setValue={(e)=>setShadowFilter({...shadowFilter, visit_result:e})} />
                    </div>
                    <div className="col-md-6">
                        <Select  error={null} id={'visit-filter-status'} items={getArrayFromObject(STATUSES.VISIT_STATUSES)} label={langDictionary?.status} 
                            value={shadowFilter?.status} setValue={(e)=>setShadowFilter({...shadowFilter, status:e})} />
                    </div>
                </div>
            </ReportsFilter>
    )
}