import { useContext, useEffect, useState } from "react";
import InputBlock from "../../../../components/input-block/input-block";
import Select from "../../../../components/select/select";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../../utils/error-handler";
import { epidStatuses } from "../../../../utils/main";
export default function Form({form, setForm, errors, saveFunction}){
    const {isReady, token, logout} = useContext(AuthContext);
    const [docTypes, setDocTypes] = useState([]);
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getDocumentTypes({limit:-1, isSerialKeyRequired:true}, token).then((result)=>{
                if(result.status == 200){
                    setDocTypes(result.data?.data)
                }
            }).catch((e)=>handleErrors(e, logout));
        }
    }, [isReady])
    return (
        <div className="row">
            <div className="col-md-12">
                <InputBlock error={errors?.name} id={'admin-serial-keys-form-name'} label={'Ady'} 
                    value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                    icon={'bi bi-keyboard'} />
            </div>
            <div className="col-md-12">
                <Select error={errors?.documet_type} id={'admin-type'} items={docTypes} label={'Resminama görnüşi'} value={form?.documet_type} 
                    setValue={(documet_type)=>setForm({...form, documet_type})} />
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.order} id={'admin-bed-form-order'} label={'Tertip belgisi'} 
                    value={form?.order} setValue={(e)=>setForm({...form, order:e})} type="number"
                    icon={'bi bi-123'} />
            </div>
            <div className="col-md-6">
                <Select error={errors?.status} id={'analyse-type-status'} items={epidStatuses} label={"Statusy"}
                    value={form?.status !== undefined ?  form?.status : ''} setValue={(e)=>setForm({...form, status:e})} itemTitleLabel="title" itemValueLabel="value"/>
            </div>
        </div>
    )
}