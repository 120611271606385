export default function CheckableCard({title, isActive=false, cardClass='', onClick=()=>{}, isChecker = true, cardOptions = null}){
    return (
        <div className={`checkable-card m-1 ${isActive ? 'active' : ''} ${cardClass}`} onClick={onClick} {...cardOptions}>
        {
            isChecker ?
            <div className="checker"></div>
            :
            null
        }
            <span>{title}</span>
        </div>
    )
}