import { useContext, useEffect, useRef, useState } from "react"
import AuthWrapper from "./Wrapper"
import InputBlock from "../../components/input-block/input-block"
import { checkRequire } from "../../utils/main"
import AxiosHelper from "../../utils/API/AxiosHelper"
import { appendNotification } from "../../components/notification"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import InputWrapper from "../../components/input-wrapper/input-wrapper"
import { handleErrors } from "../../utils/error-handler"
import {Buffer} from 'buffer'
import Button from "../../components/button/button"
import { LangContext } from "../../context/LangContext"
import Timer from "../../components/timer/timer"

export default function Login(){
    const [form, setForm] = useState({})
    const [captchaImg, setCaptchaImg] = useState('')
    const [errors, setErrors] = useState({})
    const {login, isReady} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    const formRef = useRef()
    const navigate = useNavigate()
    const [blockedTill, setBlockedTill] = useState(null)
    const [failAttempts, setFailAttempts] = useState(0)
    const [opts, setOpts] = useState({isPassword:true, isLoading:false})
    function submit(e){
        e?.preventDefault()
        if(!opts.isLoading){
            const {isError, err} = validate() 
            setErrors(err)
            if(!isError){
                setOpts({...opts, isLoading:true})
                AxiosHelper.login(form).then((result)=>{
                    console.log(result.data);
                    if(result.status === 200){
                        if(result.data?.isOAuth){
                            navigate("/confirm", {state:{session_id:result.data?.session_id, startTime:(new Date().getTime())}, replace:true})
                        }else{
                            clearFailAttempts()
                            login(result.data?.token, result.data?.user)
                        }
                    }
                }).catch((e)=>{
                    console.log(e)
                    handleCaptcha()
                    appendNotification('Ulanyjy ady ýa-da açar sözi nädogry', 'danger')
                    handleFailAttempt()
                }).finally(()=>setOpts({...opts, isLoading:false}))
            }
        }
    }

    function validate() {
        let err = {}
        if(!checkRequire(form?.login)) err.login = 'Ulanyjy ady boş bolup bilmez!'

        if(!checkRequire(form?.password)) err.password = 'Açar sözi boş bolup bilmez!'
        return {isError:err.login || err.password, err}
    }

    function handleCaptcha(){
        if(isReady){
            AxiosHelper.captcha().then((result)=>{
                if(result.status == 200){
                    const base64 = Buffer(result.data, 'binary').toString('base64')
                    setCaptchaImg(`data:image/png; base64,${base64}`)
                }
            }).catch((e)=>handleErrors(e))
        }
    }

    function handleFailAttempt() {
        const attempt = parseInt(failAttempts)+1;
        setFailAttempts(attempt)
        localStorage.setItem(LABEL_FAIL_ATTEMPT, attempt)
        if(attempt >= MAX_FAIL_ATTEMPT){
            const blocked_till = new Date()
            blocked_till.setMinutes(blocked_till.getMinutes()+3)
            localStorage.setItem(LABEL_BLOCKED_TILL, blocked_till)
            setBlockedTill(blocked_till)
            setFailAttempts(0)
            localStorage.removeItem(LABEL_FAIL_ATTEMPT)
        }
    }
    function clearFailAttempts() {
        localStorage.removeItem(LABEL_FAIL_ATTEMPT)
        localStorage.removeItem(LABEL_BLOCKED_TILL)
    }
    useEffect(()=>handleCaptcha(), [isReady])
    useEffect(()=>{
        const _blockedTill = localStorage.getItem(LABEL_BLOCKED_TILL);
        const _failAttempts = localStorage.getItem(LABEL_FAIL_ATTEMPT);
        if(!_blockedTill || new Date(_blockedTill)<= new Date()){
            localStorage.removeItem(LABEL_BLOCKED_TILL)
        }else{
            setBlockedTill(_blockedTill)
        }
        if(parseInt(_failAttempts)) setFailAttempts(parseInt(_failAttempts))
    }, [])
    return (
        <AuthWrapper>
            {
                !blockedTill &&
                <>
                <span className="h5">{langDictionary?.login}</span>
                <span className="fst-italic text-small mb-3">{langDictionary?.login_text}</span>
                </>
            }
            <form className="auth-form" onSubmit={(e)=>submit(e)} ref={formRef}>
            {
                blockedTill ?
                <BlockedMessage blockedTill={blockedTill} setBlockedTill={setBlockedTill}/>
                :
                <>
                    <InputBlock error={errors?.login} id={"loginUsername"} label={langDictionary?.username} value={form?.login} setValue={(val)=>{
                        setErrors({...errors, login:''})
                        setForm({...form, login:val.trim()})
                    }} icon={'bi bi-person'} placeholder={`${langDictionary?.username}...`}/>
                    <InputBlock error={errors?.password} id={"loginPassword"} label={langDictionary?.password} 
                        value={form?.password} setValue={(val)=>{
                            setErrors({...errors, password:''})
                            setForm({...form, password:val.trim()})
                        }} 
                        icon={opts.isPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}  placeholder={`${langDictionary?.password}...`} 
                        type={opts.isPassword ? 'password' : 'text'} onIconClick={()=>setOpts({...opts, isPassword:!opts.isPassword})}/>

                    <InputWrapper error={errors?.captcha} id={"captcha"} label={langDictionary?.captcha}>
                        <div className="d-flex align-items-bottom">
                            <input className="form-control form-control-sm number-input" type="number" 
                                placeholder="...." value={form?.captcha} onChange={(e)=>{
                                setErrors({...errors, captcha:''})
                                setForm({...form, captcha:e.target.value})
                            }}/>
                            <div className="d-flex align-items-center ps-1">
                                {
                                    captchaImg ?
                                    <img src={captchaImg} alt="captcha img" />
                                    :
                                    null 
                                }
                                <div className="d-flex p-1 border bg-light rounded-1 px-2 cursor-pointer" onClick={(e)=>{
                                    e.preventDefault()
                                    handleCaptcha()
                                    setForm({...form, captcha:''})
                                }}>
                                    <i className="bi bi-arrow-repeat"/>
                                </div>
                            </div>
                        </div>
                    </InputWrapper>
                    {
                        failAttempts ?
                        <p className="text-center fw-500 fst-italic text-medium p-0 my-2 text-danger">
                            Şowsuz synanyşyklar: {failAttempts}/{MAX_FAIL_ATTEMPT}
                        </p>
                        :
                        null
                    }
                    <Button theme="slat rounded-pill w-100" buttonSize="md">{
                        opts.isLoading ?
                        <div class="spinner-border spinner-border-sm">
                        </div>
                        :
                        langDictionary?.login
                    }</Button>
                </>
            }
            </form>
        </AuthWrapper>
    )
}

function BlockedMessage({blockedTill, setBlockedTill}) {
    return(
        <div className="p-3 rounded-3 bg-accent-danger text-danger border border-danger fst-italic text-center">
            Siz {MAX_FAIL_ATTEMPT} gezek ýalňyş synanyşyk etdiňiz. Garaşmagyňyzy haýyş edýäris:
            <h2>
                <Timer startTime={new Date().getTime()}  waitTime={new Date(blockedTill).getTime()-new Date().getTime()} onStop={()=>{
                    setBlockedTill(null)
                    localStorage.removeItem(LABEL_BLOCKED_TILL)
                }}/>
            </h2>
        </div>
    )
}

const LABEL_BLOCKED_TILL = 'blockedTill'
const LABEL_FAIL_ATTEMPT = 'failAttempts'
const MAX_FAIL_ATTEMPT = 5