import { useContext } from "react"
import { LangContext } from "../../../../context/LangContext"
import Dropdown from "../../../../components/dropdown";
import './lang.css';
import CONSTANTS from "../../../../utils/constants";

export default function LanguageSwitcher({isSmall = false}){
    const {langs, lang, setLang} = useContext(LangContext);
    function handleLangChange(langKey){
        setLang(langKey)
        localStorage.setItem(CONSTANTS.LANG_KEY, lang);
    }
    return (
        <div className="language-switcher me-">
            <Dropdown title={
                <div className="d-flex align-items-center">
                    <img src={`/img/${lang}.png`} alt="" className={`lang-icon ${!isSmall ? 'me-2' : ''}`} />
                    <span className="lang-title">
                        {
                            !isSmall ?
                            langs[lang]
                            :
                            ''
                        }
                    </span>
                </div>
            }  buttonOptions={{
                className:'btn btn-sm bg-white border fw-500 text-secondary me-2 px-3 lang-switch-button'
            }} dropdownClass="p-1 dropdown-menu-center">
                {
                    Object.entries(langs).map(([langKey, langTitle])=>{
                        return (
                            <button className="btn btn-sm btn-light text-slatgray fw-500 my-1 text-start w-100" onClick={()=>handleLangChange(langKey)}>
                                <img src={`/img/${langKey}.png`} alt="" className="lang-icon me-2" />
                                {langTitle}
                            </button>
                        )
                    })
                }
            </Dropdown>
        </div>
    )
}