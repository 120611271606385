import { useContext } from "react";
import { LangContext } from "../../../../context/LangContext";
import DashboardElements from "../../../../utils/dashboard_elements";
import Button from "../../../../components/button/button";

export default function DashboardForm({form, setForm, errors, setErrors}){
    const {langDictionary} = useContext(LangContext)
    const arr = [
        {title:langDictionary?.registration, value:DashboardElements.PATIENTS},
        {title:langDictionary?.visits, value:DashboardElements.VISITS},
        {title:langDictionary?.reception, value:DashboardElements.RECEPTION},
        {title:langDictionary?.laboratory, value:DashboardElements.LAB},
        {title:langDictionary?.radiology, value:DashboardElements.RAD},
        {title:langDictionary?.specializations, value:DashboardElements.SPEC},
        {title:langDictionary?.instrumental_analyses, value:DashboardElements.INSTRUMENTAL},
        {title:langDictionary?.nurse, value:DashboardElements.NURSE},
        {title:langDictionary?.payments, value:DashboardElements.PAYMENTS}
    ]
    return(
        <div className="mb-3">
            <div className="border rounded-3 overflow-hidden">
                <div className="w-100 text-start text-slatgray d-flex align-items-center bg-light p-2 rounded-3 fw-500 cursor-pointer bottom-radius-0 border-bottom collapsed"
                    data-bs-toggle="collapse" aria-expanded="" data-bs-target={`#admin-roles-form-start_page`} aria-controls="contents-collapse">
                    {langDictionary?.start_page}
                    <Button theme="light" className="ms-auto">
                        <i className="bi bi-chevron-compact-down"/>
                    </Button>
                </div>
                <ul id={'admin-roles-form-start_page'} className="nav-content list-unstyled tree mb-0 px-2 collapse">
                {
                    arr.map((item, index)=>{
                        return(
                            <li className="form-check">
                                <input className={'form-check-input'} type="checkbox" name={`${item?.value}-${index}`} id={`${item?.value}-${index}`} onClick={(e)=>{
                                    
                                }} checked={form?.start_page == item.value} onChange={(e)=>{
                                    if(e.target.checked){
                                        setForm({...form, start_page:item?.value})
                                    }
                                }}/>
                                <label htmlFor={`${item?.value}-${index}`} className="form-check-label">
                                    {item.title}
                                </label>
                            </li>
                        )
                    })
                }
                </ul>
            </div>
            {
                errors.start_page && <span className="text-danger text-small ms-2">{errors?.start_page}</span>
            }
        </div>
    )
}