export default class STATUSES {
    static STATUSE_ACTIVE = 0
    static STATUSE_INACTIVE = 99

    static ANALYSE_QUEUE = 0;
    static ANALYSE_WAIT = 10;
    static ANALYSE_COMPLETED = 20;
    static ANALYSE_REJECTED = 99;

    static VISIT_OPEN = 0;
    static VISIT_CLOSE = 10;
    static VISIT_REJECTED = 99;

    static OPERATION_QUEUE = 0;
    static OPERATION_COMPLETED = 10;
    static OPERATION_REJECTED = 99;

    static NOTE_NORMAL = 0;
    static NOTE_GOOD = 10;
    static NOTE_DANGER = 20;

    static TASK_OPEN = 0
    static TASK_CLOSE = 10

    static TASK_STATUSES = {
        [this.TASK_OPEN]:'Açyk',
        [this.TASK_CLOSE]:'Ýapyk',
    }

    static ANALYSE_NOTES = {
        [this.NOTE_NORMAL]:'Kadaly',
        [this.NOTE_GOOD]:'Gowy',
        [this.NOTE_DANGER]:'Howply'
    }

    static NOTE_THEMES = {
        [this.NOTE_NORMAL]:'primary',
        [this.NOTE_GOOD]:'success',
        [this.NOTE_DANGER]:'danger'
    }


    static ANALYSE_STATUSES = {
        [this.ANALYSE_QUEUE]:'Nobata duran',
        [this.ANALYSE_WAIT]:'Netijesi garaşylýan',
        [this.ANALYSE_COMPLETED]:'Netijesi taýýar',
        [this.ANALYSE_REJECTED]:'Ret edilen',
    }

    static ANALYSE_STATUSES_SHORT = {
        [this.ANALYSE_QUEUE]:'Nobata duran',
        [this.ANALYSE_WAIT]:'Garaşylýan',
        [this.ANALYSE_COMPLETED]:'Taýýar',
        [this.ANALYSE_REJECTED]:'Ret edilen',
    }

    static ANALYSE_STATUSES_ICONS = {
        [this.ANALYSE_QUEUE]:'file-earmark-check',
        [this.ANALYSE_WAIT]:'file-earmark-text',
        [this.ANALYSE_COMPLETED]:'file-earmark-check',
        [this.ANALYSE_REJECTED]:'file-earmark-x',
    }

    static ANALYSE_TEHEMES = {
        [this.ANALYSE_QUEUE]:'warning',
        [this.ANALYSE_WAIT]:'primary',
        [this.ANALYSE_COMPLETED]:'success',
        [this.ANALYSE_REJECTED]:'danger',
    }

    static OPERATION_STATUSES = {
        [this.OPERATION_QUEUE]:'Garaşylýan',
        [this.OPERATION_COMPLETED]:'Tamamlanan',
        [this.OPERATION_REJECTED]:'Ret edilen',
    }

    static OPERATION_TEHEMES = {
        [this.OPERATION_QUEUE]:'warning',
        [this.OPERATION_COMPLETED]:'success',
        [this.OPERATION_REJECTED]:'danger',
    }

    static PAYMENT_NOPAYED = 0;
    static PAYMENT_PAYED = 10;
    static PAYMENT_REJECTED = 99;

    static PAYMENT_STATUSES = {
        [this.PAYMENT_NOPAYED]:'Tölenmedik',
        [this.PAYMENT_PAYED]:'Tölenen',
        [this.PAYMENT_REJECTED]:'Ret edilen',
    }

    static PAYMENT_THEMES = {
        [this.PAYMENT_NOPAYED]:'warning',
        [this.PAYMENT_PAYED]:'primary',
        [this.PAYMENT_REJECTED]:'danger'
    }

    static PAYMENT_CARD = 'CARD';
    static PAYMENT_CASH = 'CASH';

    static PAYMENT_METHODS = {
        [this.PAYMENT_CARD]:'Tölenmedik',
        [this.PAYMENT_CASH]:'Tölenen'
    }

    static VISIT_STATUSES = {
        [this.VISIT_OPEN]:'Açyk',
        [this.VISIT_CLOSE]:'Ýapyk',
        [this.VISIT_REJECTED]:'Ret edilen',
    }

    static VISIT_THEMES = {
        [this.VISIT_OPEN]:'primary',
        [this.VISIT_CLOSE]:'warning',
        [this.VISIT_REJECTED]:'danger',
    }

    static LABORATORY = 10
    static RADIOLOGY = 20
    static SPEC = 30
    static INSTRUMENTAL = 40

    static UNITS = {
        [this.LABORATORY] : 'Laboratoriýa',
        [this.RADIOLOGY] : 'Radiologiýa',
        [this.INSTRUMENTAL] : 'Enjamlaýyn',
        [this.SPEC] : 'Inçe hünärmenler'
    }

    static UNITS_MAP = [
        {id:[this.SPEC], name:'Inçe hünärmenler'},
        {id:[this.LABORATORY], name:'Laboratoriýa'},
        {id:[this.RADIOLOGY], name:'Radiologiýa'},
        {id:[this.INSTRUMENTAL], name:'Enjamlaýyn'},
    ]

    static UNIT_THEMES = {
        [this.LABORATORY] : 'primary',
        [this.RADIOLOGY] : 'success',
        [this.SPEC] : 'warning'
    }

    static UNIT_ROUTES = {
        [this.LABORATORY] : 'laboratory',
        [this.RADIOLOGY] : 'radiology',
        [this.SPEC] : 'specializations',
        [this.INSTRUMENTAL] : 'instrumental-analyses',
    }

    static CL_DEPARTMENT = 10
    static CL_HOME = 20
    static CL_AMBULATOR = 30
    static CL_ANOTHER_HOSPITAL = 40

    static CLYNIC_ACTIVITIES = {
        [this.CL_DEPARTMENT] : 'Ýatymlaýyn bejergi',
        [this.CL_HOME] : 'Öýünde bejergi',
        [this.CL_AMBULATOR] : 'Gatnawly bejergi',
        [this.CL_ANOTHER_HOSPITAL] : 'Başga hassahana geçir'
    }
    
    static APPOINTMENT_PRESCRIPTION = 0
    static APPOINTMENT_ANLYSE = 10

    
    static PRESCRIPTION_WAIT = 0;
    static PRESCRIPTION_COMPLETED = 10;
    static PRESCRIPTION_REJECTED = 99;

    static PRESCRIPTION_STATUSES = {
        [this.PRESCRIPTION_WAIT]:{
            title:'Garaş.',
            theme:'warning'
        },
        [this.PRESCRIPTION_COMPLETED]:{
            title:'Ýer. ýet',
            theme:'success'
        },
        [this.PRESCRIPTION_REJECTED]:{
            title:'Ret ed.',
            theme:'danger'
        },
    }
}