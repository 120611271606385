import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button/button";

export default function NavigateButtons(){
    const navigate = useNavigate()
    return(
        <div className="d-flex align-items-center ms-2">
            <Button theme="light" className="mx-1 rounded-circle border box-shadow" 
                onClick={()=>navigate(-1)} title="Alt+&#8592;">
                <i className="bi bi-arrow-left"/>
            </Button>
            <Button theme="light" className="mx-1 rounded-circle border box-shadow" 
                onClick={()=>navigate(1)} title="Alt+&#8594;">
                <i className="bi bi-arrow-right"/>
            </Button>
        </div>
    )
}