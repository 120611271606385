import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../../../../context/LangContext";
import styles from './style.module.css';
import { AccentButton } from "../../../../../components/button/button";
import { AuthContext } from "../../../../../context/AuthContext";
import Modules from "../../../../../utils/modules";

export default function GlobalNavs(){
    const {langDictionary} = useContext(LangContext);
    const [_departments, setDepartments] = useState([]);
    const {user, isReady} = useContext(AuthContext);
    const ref = useRef();
    const navs = [
        {text:langDictionary?.mainPage, link:'/'},
        {text:langDictionary?.patients, link:'/patients'},
        {text:langDictionary?.visits, link:'/visits'},
        {text:langDictionary?.analyses, link:'/analyses'},
        {text:langDictionary?.settings, link:'/settings'},
    ];
    return (
        <div className={`col-md-5 col-lg-7 ${styles.wrapper}`}>
        {
            isReady ?
            <ul className={styles.globalNavs} ref={ref}>
                {
                    navs.map((nav)=>{
                        if(nav && !nav.key || (user && user._role?.modules && user._role?.modules.includes(nav?.key))){
                            return (
                                <Link to={nav.link} className={styles.globalNavsItem}>
                                    {nav.text}
                                </Link>
                            )
                        }
                    })
                }
            </ul>
            :
            null
        }
        </div>
    )
}