export default class DashboardElements{
    static PATIENTS = 1
    static VISITS = 2
    static LAB = 3
    static RAD = 4
    static SPEC = 5
    static PAYMENTS = 6
    static RECEPTION = 7
    static INSTRUMENTAL = 8
    static NURSE = 9
}