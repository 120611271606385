import { useContext, useEffect, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { AuthContext } from "../../../context/AuthContext"
import AxiosHelper from "../../../utils/API/AxiosHelper"
import { handleErrors } from "../../../utils/error-handler"
import Sort from "../../../components/sort/sort"
import { getFormattedDate, getFullname } from "../../../utils/main"
import Filter from "./filter"
import { LangContext } from "../../../context/LangContext"
import Button, { AccentButton } from "../../../components/button/button"
import SettingsBlock from "../../../components/settings-block/settings-block"
import CopyableText from "../../../components/copyable-text/copyable-text"
import { ViewMetadata, viewVisitMetadataModalID, viewVisitModalId } from "../patients/view/_visits"
import ViewAlternateVisit from "./viewer"


export default function Visits(){
    const location = useLocation()
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [count, setCount] = useState(0)
    const [errors, setErrors] = useState({})
    const [filter, setFilter] = useState(location.state?.filter || {page:1, limit:20, epids:[], states:[], actions:[]})
    const {isReady, token, logout, user} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    const [selected, setSelected] = useState(null)
    const modalTogglerRef = useRef()
    function refresh(){
        if(isReady){
            setIsLoading(true)
            AxiosHelper.getVisits(filter).then((result)=>{
                if(result.status == 200){
                    setData(result.data?.data)
                    setCount(result.data?.count)
                }
            }).catch((e)=>handleErrors(e, logout, errors, setErrors))
            .finally(()=>setIsLoading(false))
        }
    }
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1
            }
        },
        {
            text:'ID', content:(item, index)=>{
                return <CopyableText text={item?.id} isIcon={false} className="text-primary" textAppendix="#"/>
            }
        },
        {
            text:<Sort text={langDictionary?.patient} filter={filter} setFilter={setFilter} targetAttribute='patient_id'/>, content:(item)=>{
                return (
                    <span className="text-dark fw-bold">
                        {getFullname(item?._patient, true)}
                    </span>
                )
            }
        },
        {
            text:langDictionary?.visit_office, content:(item)=>{
                return (
                    <span>
                        {item?._hospital?.name || 'Näbelli'}
                    </span>
                )
            }
        },
        {
            text:<Sort text={langDictionary?.visit_date} filter={filter} setFilter={setFilter} targetAttribute={"date"}/>, content:(item)=>{
                const date = new Date(item?.visit_date)
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '-')}
                    </span>
                )
            }
        },
        {
            text:langDictionary?.visit_type, content:(item)=>{
                return (item?.metadata?._type?.name || '-//-')
            }
        },
        {
            text:langDictionary?.visit_method, content:(item)=>{
                return (item?.metadata?._method?.name || '-//-')
            }
        },
        {
            text:langDictionary?.department, content:(item)=>{
                return (item?.metadata?._department?.name || '-//-')
            }
        },
        {
            text:langDictionary?.doctor, content:(item)=>{
                return (item?.metadata?._doctor ? getFullname(item?.metadata?._doctor) : '-//-')
            }
        },
        {
            text:<Sort text={langDictionary?.created_at} filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt)
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '-')}
                    </span>
                )
            }
        },
        
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <Button theme="light" className="bg-gray text-dark me-2 border" 
                            data-bs-toggle="modal" data-bs-target={`#${viewVisitMetadataModalID}`} 
                            onClick={()=>setSelected(item)}>
                            <i className="bi bi-eye"/>
                        </Button>
                        <AccentButton theme="danger" data-bs-toggle="modal" data-bs-target={`#${viewVisitModalId}`} 
                            onClick={()=>setSelected(item)}>
                            <i className="bi bi-file-earmark-pdf-fill"/>
                        </AccentButton>
                    </div>
                )
            }
        }
    ]
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    return (
        <>
        <SettingsBlock
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh} onDoubleClick={(item)=>{
                if(modalTogglerRef?.current){
                    setSelected(item)
                    modalTogglerRef.current.click()
                }
            }} pageTitleEndComponent={
                <Filter filter={filter} setFilter={setFilter}/>
            }
            pageKey={'visits-page'} pageTitle={langDictionary?.visits}
            startBlock={
                <Link to={'/visits/create'} className="btn btn-sm btn-primary text-nowrap px-3">
                    <i className="bi bi-plus-lg me-2"/>
                    {langDictionary?.add_new}
                </Link>
            } />
            
            <ViewAlternateVisit modalId={viewVisitModalId} model={selected}/>
            <ViewMetadata model={selected}/>
            <button className="d-none" data-bs-toggle="modal" data-bs-target={`#${viewVisitMetadataModalID}`} ref={modalTogglerRef}/>
        </>
    )
}