import { useEffect, useState } from "react";
import Chart from 'react-apexcharts';
import { COLORS } from "../../utils/main";

export default function SemiDonutChart({text, labels, series, height = 250, colors=COLORS, summLabel = 'Jemi ýazgylar', appendix = '', labelShow = true, toolbarShow = true}){
    const [options, setOptions] = useState({
        title: {
            text: "Edaralar boýunça",
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            color:  '#263238',
            class:"text-slatgray"
            },
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        chart: {
            type: 'donut',
            // width: 300,
            height:height,
            toolbar: {
              show: toolbarShow,
              offsetX: 0,
              offsetY: 0,
            },
            redrawOnParentResize:true
        },

        series: series,
        colors,
        dataLabels: {
            enabled: false
        },
        labels: labels,
        legend: {
            show: false,
        },
        fill: {
            type: '',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        plotOptions: {
            pie: {
                expandOnClick: false,
                startAngle: -90,  // Start at -90 degrees (top center)
                endAngle: 90, 
                donut: {
                    size: '60%',
                    labels: {
                        show: labelShow,
                        name: {
                            show: labelShow,
                            fontSize: '22px',
                            offsetY: -5
                        },
                        value: {
                            show: labelShow,
                            fontSize: '16px',
                            color: undefined,
                            offsetY: +5,
                            formatter: function (val) {
                                return val+appendix
                            }
                        },
                        total: {
                            show: labelShow,
                            label: summLabel,
                            color: '#ffa500',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                            }
                        }
                    }
                }
            }
        }
    });
    useEffect(()=>{
        setOptions({
            ...options,
            title:{
                ...options.title,
                text
            },
            series:series,
            labels:labels,
            colors,
            plotOptions:{
                pie:{
                    ...options.plotOptions.pie,
                    donut:{
                        labels:{
                            ...options.plotOptions.pie.donut.labels,
                            total:{
                                ...options.plotOptions.pie.donut.labels.total,
                                label:summLabel
                            }
                        }
                    }
                }
            }
        })
    }, [text, series, summLabel]);
    return (
        <Chart options={options} series={options.series} height={height} type="donut"/>
    );
}