import Breadcrumbs from "../breadcrumbs/breadcrumbs";

export default function Topbar({pages, endContent = null, className='', ...props}){
    return (
        <div className={"d-flex align-items-center p-1 border-bottom mb-1 "+className} {...props}>
            <Breadcrumbs pages={pages} />
            <div className="d-flex align-items-center ms-auto">
                {endContent}
            </div>
        </div>
    )
}