import { useContext } from "react";
import { LangContext } from "../../context/LangContext";
import Modal from "../modal/modal";
import { AccentButton } from "../button/button";
export default function ModalWithButton({modalId, title, closeRef, isCloseButton = true,
                                        onButtonClick = ()=>{}, buttonProps=null,
                                        modalPosition='modal-dialog-centered', modalSize='modal-lg', isBackdropStatic = true,
                                        isSaveEnable=false, saveFunction=()=>{}, buttonContent = null, endButtons=null,
                                        ...props}){
    const {langDictionary} = useContext(LangContext);

    return (
        <>
            <button className="btn btn-sm btn-primary px-3 text-nowrap" 
                data-bs-toggle="modal" data-bs-target={`#${modalId}`} 
                onClick={onButtonClick} {...buttonProps} >
                {
                    buttonContent ?
                    buttonContent 
                    :
                    <>
                        <i className="bi bi-plus-lg me-2"/>
                        {langDictionary?.add_new}
                    </>
                }
            </button>
            <Modal id={modalId} isFooter={false} isHeader={true} modalPosition={modalPosition} modalSize={modalSize} isBackdropStatic={isBackdropStatic}
            headerContent={
                <>
                    <span className="fs-5 fw-500 text-themed">
                        {title}
                    </span>
                    <div className="d-flex align-items-center">
                        {
                            isCloseButton && 
                            <AccentButton theme="secondary" data-bs-dismiss="modal" btnRef={closeRef}>
                                <i className="bi bi-x-lg me-2"/>
                                {langDictionary?.close}
                            </AccentButton>
                        }
                        {
                            isSaveEnable ?
                            <AccentButton theme="primary ms-2" onClick={saveFunction}>
                                <i className="bi bi-check2-square me-2"/>
                                {langDictionary?.save}
                            </AccentButton>
                            : null
                        }
                        {endButtons}
                    </div>
                </>
            }
            headerClassName="d-flex align-items-center justify-content-between"
            >
                {props.children}
            </Modal>
        </>
    )
}