import { Link, useLocation } from "react-router-dom"

export default function Breadcrumbs({pages}){
    const location = useLocation();
    return (
        <ol class="breadcrumb mb-0">
            {
                Array.from(pages).map((item)=>{
                    return (
                        item?.link == location.pathname ?
                        <span class="breadcrumb-item active">{item?.text}</span>
                        :
                        <Link to={item?.link} class="breadcrumb-item">{item?.text}</Link>
                    )
                })
            }
        </ol> 
    )
}