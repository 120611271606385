import { useRef } from "react";
import BarcodeViewer from "../barcode-viewer/barcode-viewer";
import Modal from "../modal/modal";
import PrintButton from "./_print";
import SaveButton from "./_save";
import img from './barcode_white.png';

export default function BarcodeModal({value='123456789', id='barcode-modal', height=50, buttonOptions={}}){
    const ref = useRef()
    return (
        <>
            <img src={img} alt="" className="cursor-pointer" data-bs-toggle="modal" data-bs-target={`#${id}`} height={height} {...buttonOptions}/>
            <Modal id={id} isFooter={false} isHeader={false} isBody={false}>
                <div className="d-grid w-100 text-dark">
                    <div className="card p-3 position-relative">
                        <button className="btn btn-little btn-secondary position-absolute top-0 end-0 m-1 rounded-1" data-bs-dismiss="modal">
                            <i className="bi bi-x-lg"/>
                        </button>
                        <div className="my-2">
                            <BarcodeViewer value={value} _ref={ref} height={100}/>
                        </div> 
                        <div className="row">
                            <div className="col-md-6">
                                <SaveButton _ref={ref}/>
                            </div>
                            <div className="col-md-6">
                                <PrintButton _ref={ref}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}