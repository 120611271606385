import { useContext, useEffect, useRef, useState } from "react";
import ModalWithButton from "../../../components/modal-button.jsx/modal-btn";
import { LangContext } from "../../../context/LangContext";
import { AuthContext } from "../../../context/AuthContext";
import AxiosHelper from "../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../utils/error-handler";
import InputBlock from "../../../components/input-block/input-block";
import { districtTypes, gendersArr, maxDate, minBirthDate, regionTypes, villageTypes } from "../../../utils/main";
import Select from "../../../components/select/select";
import InputWrapper from "../../../components/input-wrapper/input-wrapper";
import CheckableCard from "../../../components/checkable-card/checkable-card";
import { AccentButton } from "../../../components/button/button";

export default function Filter({filter, setFilter}){
    const closeRef = useRef();
    const modalId = "admin-patients-filter-modal";
    const {langDictionary} = useContext(LangContext);
    const [filterElements, setFilterElements] = useState({countries:[], regions:[], villages:[], districts:[], benefits:[], doc_types:[],serial_keys:[]});
    const {isReady, token, logout} = useContext(AuthContext);
    const [shadowFilter, setShadowFilter] = useState({});
    const clearFilter = {
        text:'', 
        country:'', 
        birth_date:'', 
        gender:'', 
        benefit:'', 
        doc_number:'', 
        doc_type:'', 
        region:'', 
        village:'', 
        city:'', 
        birth_region:'', 
        birth_village:'', 
        birth_city:'',
        isAnonim:false
    }
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getPatientsFilter(token).then((result)=>{
                if(result.status == 200){
                    setFilterElements({...result.data});
                }
            }).catch((e)=>handleErrors(e, logout));
        }
    }, [isReady]);
    useEffect(()=>setShadowFilter(filter), [filter])
    return (
        <ModalWithButton closeRef={closeRef} modalId={modalId}  title={langDictionary?.filter_of_patients} modalSize="modal-xl"
        buttonContent={
            <>
                <i className="bi bi-sliders me-2"/>
                {langDictionary?.filter}
            </>
        } buttonProps={{
            className:'btn btn-sm bg-accent-secondary text-secondary fw-500'
        }}
        endButtons={
            <>
                <AccentButton theme="warning mx-1 ms-2"
                    onClick={
                        ()=>{
                            setFilter({...filter, ...clearFilter})
                            if(closeRef.current) closeRef.current.click()
                        }
                    }>
                    <i className="bi bi-arrow-repeat me-2"/>
                    {langDictionary?.clear}
                </AccentButton>
                <AccentButton theme="primary mx-1" 
                    onClick={
                        ()=>{
                            setFilter({...filter, ...shadowFilter})
                            if(closeRef.current) closeRef.current.click()
                        }
                    }>
                    <i className="bi bi-check2-square me-2"/>
                    {langDictionary?.save}
                </AccentButton>
            </>
        } 
        >
        <div className="row">
            <div className="col-md-12 d-flex align-items-center">
                <div className="w-100">
                    <InputBlock error={null} id={'patient-text-filter'} label={langDictionary?.FIO} value={shadowFilter?.text} 
                        setValue={(e)=>setShadowFilter({...shadowFilter, text:e})} icon={"bi bi-keyboard"} />
                </div>
                <InputWrapper error={null} id={'patient-isScience-wrapper'} label={langDictionary?.isAnonim}>
                    <CheckableCard title={shadowFilter?.isAnonim ? langDictionary?.yes : langDictionary?.no} isActive={shadowFilter?.isAnonim} 
                    cardClass='py-2' onClick={()=>{
                        setShadowFilter({...shadowFilter, isAnonim:!shadowFilter?.isAnonim})
                    }}/>
                </InputWrapper>
            </div>
            <div className="col-md-6">
                <Select error={null} id={'patient-filter-gender'} label={langDictionary?.gender} items={gendersArr} value={shadowFilter?.gender}
                    setValue={(e)=>setShadowFilter({...shadowFilter, gender:e})} itemTitleLabel="title" itemValueLabel="value"/>
            </div>
            <div className="col-md-6">
                <InputBlock error={null} id={'patient-birthday-filter'} label={langDictionary?.birth_date} type="date"
                    value={shadowFilter?.birth_date} setValue={(e)=>setShadowFilter({...shadowFilter, birth_date:e})} 
                    inputProps={{min:minBirthDate, max:maxDate}}/>
            </div>
            <div className="col-md-6">
                <Select error={null} id={'patient-filter-country'} label={langDictionary?.origin} items={filterElements?.countries} value={shadowFilter?.country}
                    setValue={(e)=>setShadowFilter({...shadowFilter, country:e})} />
            </div>
            <div className="col-md-6">
                <Select error={null} id={'patient-filter-benefit'} label={langDictionary?.benefit_category} items={filterElements?.benefits} value={shadowFilter?.benefit}
                    setValue={(e)=>setShadowFilter({...shadowFilter, benefit:e})}/>
            </div>
            <div className="col-md-6">
                <Select error={null} id={'patient-filter-doc-type'} label={langDictionary?.document_type} items={filterElements?.doc_types} value={shadowFilter?.doc_type}
                    setValue={(e)=>setShadowFilter({...shadowFilter, doc_type:e})} />
            </div>
            <div className="col-md-6">
                <InputBlock error={null} id={'patient-filter-doc-number'} label={langDictionary?.document_number} value={shadowFilter?.doc_number}
                    setValue={(e)=>setShadowFilter({...shadowFilter, doc_number:e})} icon={'bi bi-123'} type="number" inputProps={{min:0}}/>
            </div>
            <div className="col-md-4">
                <Select error={null} id={'patient-filter-region'} label={langDictionary?.living_region}
                    items={filterElements?.regions.map((region)=>{return({...region, name:`${region?.name} ${String(regionTypes[region?.type]).substring(0,3).toLocaleLowerCase()}.`})})}
                    value={shadowFilter?.living_region} setValue={(living_region)=>setShadowFilter({...shadowFilter, living_region})} />
            </div>
            <div className="col-md-4">
                <Select error={null} id={'patient-filter-city'} label={langDictionary?.living_district} 
                    items={filterElements?.districts.map((district)=>{return({...district, name:`${district?.name} ${String(districtTypes[district?.type]).substring(0,3).toLocaleLowerCase()}.`})})}
                    value={shadowFilter?.living_district} setValue={(living_district)=>setShadowFilter({...shadowFilter, living_district})} 
                    condition={(item)=>item?.region == shadowFilter?.living_region}/>
            </div>
            <div className="col-md-4">
                <Select error={null} id={'patient-filter-village'} label={langDictionary?.living_village} 
                    items={filterElements?.villages.map((village)=>{return({...village, name:`${village?.name} ${String(villageTypes[village?.type]).substring(0,3).toLocaleLowerCase()}.`})})} 
                    value={shadowFilter?.living_village} setValue={(living_village)=>setShadowFilter({...shadowFilter, living_village})} 
                    condition={(item)=>item?.district == shadowFilter?.living_district}/>
            </div>
            <div className="col-md-4">
                <Select error={null} id={'patient-filter-registered_region'} label={langDictionary?.registered_region} 
                    items={filterElements?.regions.map((region)=>{return({...region, name:`${region?.name} ${String(regionTypes[region?.type]).substring(0,3).toLocaleLowerCase()}.`})})}
                    value={shadowFilter?.registered_region} setValue={(registered_region)=>setShadowFilter({...shadowFilter, registered_region})} />
            </div>
            <div className="col-md-4">
                <Select error={null} id={'patient-filter-registered_district'} label={langDictionary?.registered_district} 
                    items={filterElements?.districts.map((district)=>{return({...district, name:`${district?.name} ${String(districtTypes[district?.type]).substring(0,3).toLocaleLowerCase()}.`})})}
                    value={shadowFilter?.registered_district} setValue={(registered_district)=>setShadowFilter({...shadowFilter, registered_district})} 
                    condition={(item)=>item?.region == shadowFilter?.registered_region}/>
            </div>
            <div className="col-md-4">
                <Select error={null} id={'patient-filter-registered_village'} label={langDictionary?.registered_village} 
                    items={filterElements?.villages.map((village)=>{return({...village, name:`${village?.name} ${String(villageTypes[village?.type]).substring(0,3).toLocaleLowerCase()}.`})})} 
                    value={shadowFilter?.registered_village} setValue={(registered_village)=>setShadowFilter({...shadowFilter, registered_village})} 
                    condition={(item)=>item?.district == shadowFilter?.registered_district}/>
            </div>
        </div>
        </ModalWithButton>
    )
}