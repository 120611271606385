import LimitSetter from '../limitSetter/limitSetter';
import SearchInput from '../search-input/search-input';
export default function FilterBlock({filter, setFilter, isLimitSetter=true, isSearchInput = true, filterBottomBlock = null,
        isFilterable = false, filterBlock = null, startBlock=null, isRefreshable = false, refresh=()=>{}}){
    return (
        <div className={`mb-${filterBottomBlock ? 1 : 3}`}>
            <div className="d-flex align-items-center justify-content-between">
            {
                startBlock
            }
            {
                isSearchInput ?
                <SearchInput filter={filter} setFilter={setFilter} />
                :
                null
            }
                <div className="d-flex align-items-center">
                    {
                        isLimitSetter ?
                        <LimitSetter filter={filter} setFilter={setFilter}/>
                        :
                        null
                    }
                    {
                        isRefreshable ?
                        <button class="btn btn-sm btn-light me-2" type="button" onClick={refresh}>
                            <i className="bi bi-arrow-repeat"/>
                        </button>
                        :
                        null
                    }
                    {
                        isFilterable ?
                        <div class="dropdown">
                            <button class="btn btn-sm btn-light me-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-sliders"/>
                            </button>
                            <ul class="dropdown-menu">
                                {filterBlock}
                            </ul>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            <div className="overflow--auto">
                {filterBottomBlock}
            </div>
        </div>
    )
}