import { genders, getFormattedDate } from "../../../../utils/main"
import { useContext } from "react"
import { LangContext } from "../../../../context/LangContext"
import CONSTANTS from "../../../../utils/constants"

export default function PatientMainData({model}){
    const {langDictionary} = useContext(LangContext)
    const list = [
        {title:langDictionary?.birth_date, value:getFormattedDate(model?.birth_date), condition:model?.birth_date},
        {title:langDictionary?.gender, value:genders[model?.gender], condition:model?.gender},
        {title:langDictionary?.blood_group, value:<span className="bg-accent-danger text-danger p-1 px-2 rounded-pill fw-500">{CONSTANTS.BLOODS[model?.blood_group]}</span>, condition:model?.blood_group},
        {title:langDictionary?.benefit_category, value:model?._benefit?.name, condition:model?._benefit},
        {title:langDictionary?.health_book, value:model?.health_book, condition:model?.health_book},
        {title:langDictionary?.life_anamnezes, value:model?.life_anamnezes, condition:model?.life_anamnezes},
        {title:langDictionary?.drug_allergy, value:model?.drug_allergy, condition:model?.drug_allergy},
        {title:langDictionary?.food_allergy, value:model?.food_allergy, condition:model?.food_allergy},
        {title:langDictionary?.bad_habits, value:Array.from(model?.habits).join(', '), condition:model?.habits && model?.habits?.length},
        {title:langDictionary?.relation_ills, value:Array.from(model?.ills).join(', '), condition:model?.ills && model?.ills?.length},
        {title:langDictionary?.created_at, value:getFormattedDate(model?.createdAt), condition:model?.createdAt},
    ]
    return(
        <div className="p-2 rounded-3 mb-3 box-shadow border">
            <div className="d-grid text-secondary">
                <div className="d-flex align-items-top mb-1">
                    <span className="text-dark fw-500">
                        {langDictionary?.main_data}
                    </span>
                </div>
                <div className="text-small fw-normal">
                {
                    list.map((item)=>{
                        if(item?.condition){
                            return(
                                <div className="d-flex align-items-top my-1">
                                    <div className="col-md-7">
                                        {item?.title} 
                                    </div>
                                    <div className="col-md-5 text-end">
                                        {item?.value}
                                    </div>
                                </div>
                            )
                        }
                    })
                }
                </div>
            </div>
        </div>
    )
}