import { useContext, useEffect, useState } from "react";
import Topbar from "../../../../components/topbar/topbar";
import AdminWrapper from "../../Wrapper";
import { LangContext } from "../../../../context/LangContext";
import { AccentButton } from "../../../../components/button/button";
import { useNavigate, useParams } from "react-router-dom";
import Permissions from "../../../../utils/permissions";
import TreeView from "../../../../components/tree/tree";
import AdminRolesForm from "./form";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../../utils/error-handler";
import { AuthContext } from "../../../../context/AuthContext";
import { appendNotification } from "../../../../components/notification";

export default function AdminRolesUpdate(){
    const {langDictionary} = useContext(LangContext)
    const {logout} = useContext(AuthContext)
    const [form, setForm] = useState({permissions:[], start_page:[], modules:[]})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const params = useParams()
    const navigate = useNavigate()
    const pages = [
        {text:langDictionary?.user_roles, link:"/settings/roles"},
        {text:(!isLoading && form?.name) || langDictionary?.update, link:`/settings/roles/${params?.id}`}
    ]
    useEffect(()=>getModel(), [params?.id])
    function getModel(){
        if(!isLoading && params?.id){
            AxiosHelper.getRole(params?.id).then((result)=>{
                if(result.status == 200){
                    setForm(result.data?.model)
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    function save(){
        if(!validate()){
            if(window.confirm('Siz hakykatdan hem bu roly ýatda saklamakçymy?')){
                AxiosHelper.updateRole(form?.id, form).then((result)=>{
                    if(result.status == 200){
                        appendNotification('Ulanyjy roly üstünlikli ýatda saklandy!', 'success')
                        navigate(-1)
                    }
                }).catch((e)=>handleErrors(e, logout, errors, setErrors))
            }
        }else{
            appendNotification(langDictionary.attempt_retry_after_fix, 'danger')
        }
    }
    function validate() {
        const err = {}
        if(!form?.name) err.name = 'Ulanyjy rolunyň adyny giriziň!'
        if(!form?.start_page) err.start_page = 'Başlangyç sahypany saýlaň'
        setErrors(err)
        return err.name || err.start_page
    }
    return(
        <AdminWrapper isSidebar={false}>
            <Topbar pages={pages} endContent={
                <div className="d-flex align-items-center">
                    <AccentButton theme="danger" className="me-2" onClick={()=>{
                        if(window.confirm('Siz hakykatdan hem ulanyjy roluny döretmegi ret etmekçimi?')){
                            navigate(-1)
                        }
                    }}>
                        <i className="bi bi-x-lg me-2"/>
                        {langDictionary?.cancel}
                    </AccentButton>
                    <AccentButton theme="primary" onClick={save}>
                        <i className="bi bi-check2-square me-2"/>
                        {langDictionary?.save}
                    </AccentButton>  
                </div>
            } className="position-absolute start-0 w-100 bg-white px-2" style={{top:51+'px', zIndex:20}}/>
            <div className="container">
                <div className="row">
                    <div className="p-2 box-shadow rounded-3">
                        <AdminRolesForm form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save}/>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    )
}