import AdminWrapper from "../Wrapper";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import HexagonGrid from "./hexagon-grid";

export default function AdminDashboard(){
    const {user, isReady} = useContext(AuthContext)
    return (
        <AdminWrapper isSidebar={false} mainClass="m-0 p-0" isHeader={false}>
            <HexagonGrid/>
        </AdminWrapper>
    )
}