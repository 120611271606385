import { useContext, useState } from "react";
import Button, { AccentButton } from "../../../../components/button/button";
import LoadingIcon from "../../../../components/loading-icon/loading-icon";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { AuthContext } from "../../../../context/AuthContext";
import { handleErrors } from "../../../../utils/error-handler";
import { appendNotification } from "../../../../components/notification";

export default function IndexAllButton(){
    const [isLoading, setIsLoading] = useState(false)
    const {logout} = useContext(AuthContext)
    function indexAll(){
        if(!isLoading){
            setIsLoading(true)
            AxiosHelper.indexAllDiagnoses().then((result)=>{
                if(result.status == 200){
                    appendNotification('Kesel kesgitleri indeksirlendi')
                }
            }).catch((e)=>handleErrors(e, logout))
            .finally(()=>setIsLoading(false))
        }
    }
    return(
        <AccentButton theme={"warning"} onClick={indexAll} className="mx-1">
            <LoadingIcon isAnimate={isLoading}/>
        </AccentButton>
    )
}