import { useEffect } from "react";
import { useState } from "react";
import tm from './tm.json';
import ru from './ru.json';
import eng from './eng.json';

export const useLang = (isReady) => {
    const [lang, setLang] = useState('tm');
    const [langDictionary, setLangDictionary]  = useState(tm);
    const langs = {
        tm:'Türkmen', 
        eng:'English', 
        ru:'Pусский'
    };
    useEffect(()=>{
        switch (lang) {
            case 'ru':
                setLangDictionary(ru);
                break;
            case 'eng':
                setLangDictionary(eng);
                break;
            default:
                setLangDictionary(tm);
                break;
        }
    }, [lang])

    // useEffect(()=>{
    //     if(isReady){
    //         setLang(localStorage.getItem(CONSTANTS.LANG_KEY) || 'tm')
    //     }
    // }, [isReady])

    return {lang, setLang, langs, langDictionary}; 
}