import React, { useRef } from "react";

export function appendNotification(text="", type="", timeout = 3000){
    const id = "notification-"+(new Date().getTime());
    const img = document.createElement("img");
    img.setAttribute("src", "/static/img/logo.png");
    img.setAttribute("class", "img-alert");
    const innerTxt = document.createElement("div");
    innerTxt.setAttribute("class", "col-10");
    innerTxt.innerHTML = text;
    const btn = document.createElement("i");
    btn.setAttribute("class", "bi bi-x fs-4 pointer")
    btn.onclick = ()=>{removeChild(id)}
    const col2 = document.createElement("div");
    col2.setAttribute("class", "col-2");
    col2.appendChild(btn);
    const node = document.createElement("div",)
    node.setAttribute('class', "notification row align-items-center p-1 "+type)
    node.setAttribute('id', id)
    node.appendChild(innerTxt);
    node.appendChild(col2);
    document.getElementById("alertContainer").appendChild(node);
    setTimeout(()=>{
        removeChild(id)
    }, timeout)
};
function removeChild(id=null){
    if(id != null && document.getElementById(id)){
        document.getElementById(id).remove()
    }
}
export default function Notifications(){
    const ref = useRef();
    return(
    <div className="notifications" id="alertContainer" ref={ref}>
    </div>
    )
}