export default class Permissions {
    static PAGE_DASH = 'dash'
    static PAGE_LAB = 'lab'
    static PAGE_RAD = 'rad'
    static PAGE_SPEC = 'spec'
    static PAGE_CASH = 'cash'


    static PAGES = {
        [this.PAGE_DASH]:'mainPage',
        [this.PAGE_LAB]:'laboratory',
        [this.PAGE_RAD]:'radiology',
        [this.PAGE_SPEC]:'specializations',
        [this.PAGE_CASH]:'cashier'
    }

    static PAGES_RED = {
        [this.PAGE_DASH]:'/',
        [this.PAGE_LAB]:'/laboratory',
        [this.PAGE_RAD]:'/radiology',
        [this.PAGE_SPEC]:'/specializations',
        [this.PAGE_CASH]:'/payments'
    }

    static ROLE_GET = 1
    static ROLE_POST = 2
    static ROLE_PUT = 3
    static ROLE_DELETE = 4
    
    static USER_GET = 5
    static USER_POST = 6
    static USER_PUT = 7
    static USER_DELETE = 8
    
    static USER_POSITION_GET = 9
    static USER_POSITION_POST = 10
    static USER_POSITION_PUT = 11
    static USER_POSITION_DELETE = 12
    
    static REGION_GET =  13
    static REGION_POST = 14
    static REGION_PUT = 15
    static REGION_DELETE = 16

    static VILLAGE_GET = 17
    static VILLAGE_POST = 18
    static VILLAGE_PUT = 19
    static VILLAGE_DELETE = 20

    static COUNTRY_GET = 21
    static COUNTRY_POST = 22
    static COUNTRY_PUT = 23
    static COUNTRY_DELETE = 24

    static DOC_TYPE_GET = 25
    static DOC_TYPE_POST = 26
    static DOC_TYPE_PUT = 27
    static DOC_TYPE_DELETE = 28

    static DOC_KEY_GET = 29
    static DOC_KEY_POST = 30
    static DOC_KEY_PUT = 31
    static DOC_KEY_DELETE = 32

    static V_TYPE_GET = 33
    static V_TYPE_POST = 34
    static V_TYPE_PUT = 35
    static V_TYPE_DELETE = 36

    static V_METHOD_GET = 37
    static V_METHOD_POST = 38
    static V_METHOD_PUT = 39
    static V_METHOD_DELETE = 40

    static V_RESULT_GET = 41
    static V_RESULT_POST = 42
    static V_RESULT_PUT = 43
    static V_RESULT_DELETE = 44


    static E_ANAMNEZ_GET = 45
    static E_ANAMNEZ_POST = 46
    static E_ANAMNEZ_PUT = 47
    static E_ANAMNEZ_DELETE = 48

    static DIAGNOSE_GET = 49
    static DIAGNOSE_POST = 50
    static DIAGNOSE_PUT = 51
    static DIAGNOSE_DELETE = 52

    static DEPARTMENT_GET = 53
    static DEPARTMENT_POST = 54
    static DEPARTMENT_PUT = 55
    static DEPARTMENT_DELETE = 56

    static ROOM_GET = 57
    static ROOM_POST = 58
    static ROOM_PUT = 59
    static ROOM_DELETE = 60


    static BED_GET = 61
    static BED_POST = 62
    static BED_PUT = 63
    static BED_DELETE = 64

    static PATIENT_GET = 65
    static PATIENT_POST = 66
    static PATIENT_PUT = 67
    static PATIENT_DELETE = 68

    static VISIT_GET = 69
    static VISIT_POST = 70
    static VISIT_PUT = 71
    static VISIT_DELETE = 72

    static BOOK_GET = 73
    static BOOK_POST = 74
    static BOOK_PUT = 75
    static BOOK_DELETE = 76

    static ANALYSE_TYPE_GET = 77
    static ANALYSE_TYPE_POST = 78
    static ANALYSE_TYPE_PUT = 79
    static ANALYSE_TYPE_DELETE = 80

    static ANALYSE_GET = 81
    static ANALYSE_POST = 82
    static ANALYSE_PUT = 83
    static ANALYSE_DELETE = 84

    static ANALYSE_ATTRIBUTE_GET = 85
    static ANALYSE_ATTRIBUTE_POST = 86
    static ANALYSE_ATTRIBUTE_PUT = 87
    static ANALYSE_ATTRIBUTE_DELETE = 88

    static ANALYSE_PROBE_GET = 89
    static ANALYSE_PROBE_POST = 90
    static ANALYSE_PROBE_PUT = 91
    static ANALYSE_PROBE_DELETE = 92

    static PROBE_GET = 93
    static PROBE_POST = 94
    static PROBE_PUT = 95
    static PROBE_DELETE = 96

    static PAYMENT_GET = 97
    static PAYMENT_POST = 98
    static PAYMENT_PUT = 99
    static PAYMENT_DELETE = 100

    static BENEFIT_GET = 101
    static BENEFIT_POST = 102
    static BENEFIT_PUT = 103
    static BENEFIT_DELETE = 104

    static REG_TYPE_GET = 105
    static REG_TYPE_POST = 106
    static REG_TYPE_PUT = 107
    static REG_TYPE_DELETE = 108

    static REG_GET = 109
    static REG_POST = 110
    static REG_PUT = 111
    static REG_DELETE = 112

    static OPER_TYPE_GET = 113
    static OPER_TYPE_POST = 114
    static OPER_TYPE_PUT = 115
    static OPER_TYPE_DELETE = 116

    static OPER_GET = 117
    static OPER_POST = 118
    static OPER_PUT = 119
    static OPER_DELETE = 120

    static SPEC_GET = 121
    static SPEC_POST = 122
    static SPEC_PUT = 123
    static SPEC_DELETE = 124

    static RD_TYPE_GET = 125
    static RD_TYPE_POST = 126
    static RD_TYPE_PUT = 127
    static RD_TYPE_DELETE = 128

    static RD_ANALYSE_GET = 129
    static RD_ANALYSE_POST = 130
    static RD_ANALYSE_PUT = 131
    static RD_ANALYSE_DELETE = 132

    static SP_TYPE_GET = 133
    static SP_TYPE_POST = 134
    static SP_TYPE_PUT = 135
    static SP_TYPE_DELETE = 136

    static SP_ANALYSE_GET = 137
    static SP_ANALYSE_POST = 138
    static SP_ANALYSE_PUT = 139
    static SP_ANALYSE_DELETE = 140

    static SP_QUESTION_GET = 141
    static SP_QUESTION_POST = 142
    static SP_QUESTION_PUT = 143
    static SP_QUESTION_DELETE = 144

    static SP_ANSWER_GET = 145
    static SP_ANSWER_POST = 146
    static SP_ANSWER_PUT = 147
    static SP_ANSWER_DELETE = 148

    static TR_PARAM_GET = 149
    static TR_PARAM_POST = 150
    static TR_PARAM_PUT = 151
    static TR_PARAM_DELETE = 152

    static TR_ORDER_GET = 153
    static TR_ORDER_POST = 154
    static TR_ORDER_PUT = 155
    static TR_ORDER_DELETE = 156


    static PR_ITEM_GET = 157
    static PR_ITEM_POST = 158
    static PR_ITEM_PUT = 159
    static PR_ITEM_DELETE = 160

    static PR_GET = 161
    static PR_POST = 162
    static PR_PUT = 163
    static PR_DELETE = 164

    static ARB_PARAM_GET = 165
    static ARB_PARAM_POST = 166
    static ARB_PARAM_PUT = 167
    static ARB_PARAM_DELETE = 168

    static ORIGIN_GET = 169
    static ORIGIN_POST = 170
    static ORIGIN_PUT = 171
    static ORIGIN_DELETE = 172

    static EDU_GRADE_GET = 173
    static EDU_GRADE_POST = 174
    static EDU_GRADE_PUT = 175
    static EDU_GRADE_DELETE = 176

    static EDU_INS_GET = 177
    static EDU_INS_POST = 178
    static EDU_INS_PUT = 179
    static EDU_INS_DELETE = 180

    static EDU_TYPE_GET = 181
    static EDU_TYPE_POST = 182
    static EDU_TYPE_PUT = 183
    static EDU_TYPE_DELETE = 184

    static SC_SPEC_GET = 185
    static SC_SPEC_POST = 186
    static SC_SPEC_PUT = 187
    static SC_SPEC_DELETE = 188

    static SC_LAB_GET = 189
    static SC_LAB_POST = 190
    static SC_LAB_PUT = 191
    static SC_LAB_DELETE = 192

    static SC_GRADE_GET = 193
    static SC_GRADE_POST = 194
    static SC_GRADE_PUT = 195
    static SC_GRADE_DELETE = 196

    static SC_JOB_GET = 197
    static SC_JOB_POST = 198
    static SC_JOB_PUT = 199
    static SC_JOB_DELETE = 200

    static SC_SOURCE_GET = 201
    static SC_SOURCE_POST = 202
    static SC_SOURCE_PUT = 203
    static SC_SOURCE_DELETE = 204

    static SC_ASPIRANT_GET = 205
    static SC_ASPIRANT_POST = 206
    static SC_ASPIRANT_PUT = 207
    static SC_ASPIRANT_DELETE = 208

    static SC_ANIMAL_GET = 209
    static SC_ANIMAL_POST = 210
    static SC_ANIMAL_PUT = 211
    static SC_ANIMAL_DELETE = 212
    
    static DISCOUNT_TYPE_GET = 213
    static DISCOUNT_TYPE_POST = 214
    static DISCOUNT_TYPE_PUT = 215
    static DISCOUNT_TYPE_DELETE = 216
    
    static Cl_SYMPTOM_GET = 217
    static Cl_SYMPTOM_POST = 218
    static Cl_SYMPTOM_PUT = 219
    static Cl_SYMPTOM_DELETE = 220
    
    static Cl_OPTION_GET = 221
    static Cl_OPTION_POST = 222
    static Cl_OPTION_PUT = 223
    static Cl_OPTION_DELETE = 224
    
    static V_SECTION_GET = 225
    static V_SECTION_POST = 226
    static V_SECTION_PUT = 227
    static V_SECTION_DELETE = 228
    
    static V_COUNT_GET = 229
    static V_COUNT_POST = 230
    static V_COUNT_PUT = 231
    static V_COUNT_DELETE = 232
    
    static BAD_HABIT_GET = 233
    static BAD_HABIT_POST = 234
    static BAD_HABIT_PUT = 235
    static BAD_HABIT_DELETE = 236
    
    static LIKE_ILL_GET = 237
    static LIKE_ILL_POST = 238
    static LIKE_ILL_PUT = 239
    static LIKE_ILL_DELETE = 240

    static INSTRUMENTAL_ANALYSE_TYPE_GET = 241
    static INSTRUMENTAL_ANALYSE_TYPE_POST = 242
    static INSTRUMENTAL_ANALYSE_TYPE_PUT = 243
    static INSTRUMENTAL_ANALYSE_TYPE_DELETE = 244

    static INSTRUMENTAL_ANALYSE_GET = 245
    static INSTRUMENTAL_ANALYSE_POST = 246
    static INSTRUMENTAL_ANALYSE_PUT = 247
    static INSTRUMENTAL_ANALYSE_DELETE = 248

    static INSTRUMENTAL_ANALYSE_QUESTION_GET = 249
    static INSTRUMENTAL_ANALYSE_QUESTION_POST = 250
    static INSTRUMENTAL_ANALYSE_QUESTION_PUT = 251
    static INSTRUMENTAL_ANALYSE_QUESTION_DELETE = 252

    static INSTRUMENTAL_ANALYSE_ANSWER_GET = 253
    static INSTRUMENTAL_ANALYSE_ANSWER_POST = 254
    static INSTRUMENTAL_ANALYSE_ANSWER_PUT = 255
    static INSTRUMENTAL_ANALYSE_ANSWER_DELETE = 256
    
    static RD_QUESTION_GET = 265
    static RD_QUESTION_POST = 266
    static RD_QUESTION_PUT = 267
    static RD_QUESTION_DELETE = 268

    static RD_ANSWER_GET = 269
    static RD_ANSWER_POST = 270
    static RD_ANSWER_PUT = 271
    static RD_ANSWER_DELETE = 272
    
    static LOG_GET = 273
    static LOG_DELETE = 274
    
    static PAYMENT_TARIFF_CATEGORY_GET = 279
    static PAYMENT_TARIFF_CATEGORY_POST = 280
    static PAYMENT_TARIFF_CATEGORY_PUT = 281
    static PAYMENT_TARIFF_CATEGORY_DELETE = 282

    static PAYMENT_TARIFF_GET = 283
    static PAYMENT_TARIFF_POST = 284
    static PAYMENT_TARIFF_PUT = 285
    static PAYMENT_TARIFF_DELETE = 286
}