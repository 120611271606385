import { useNavigate } from "react-router-dom";
import NoResult from "../../../components/noResult/noResult";
import { useContext } from "react";
import { LangContext } from "../../../context/LangContext";

export default function UnreachedMessage(){
    const navigate = useNavigate();
    const {langDictionary} = useContext(LangContext)
    return(
        <NoResult 
            icon={<i className="bi bi-exclamation-triangle-fill fs-1"/>} 
            text={langDictionary?.uncreated}
            isRefreshable={'true'}
            refreshFunction={()=>navigate(-1)}
            refreshContent={
                <span className="fw-500 px-2">
                    <i className="bi bi-chevron-double-left me-2"/>
                    {langDictionary?.back}
                </span>
            }
            />
    )
}