import { useEffect, useRef, useState } from "react";
import pako from 'pako';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import ModalWithButton from "../../../components/modal-button.jsx/modal-btn";

function ViewAlternateVisit({model, modalId}) {
    const [pdfUrl, setPdfUrl] = useState(null)
    useEffect(()=>{
        if(model) setPdfUrl(createPdfUrlFromDecompressedData(decompressGzip(model?.file)))
    }, [model])
    const closeRef = useRef()
    const {height} = useWindowDimensions()
    return (
        <ModalWithButton closeRef={closeRef} modalId={modalId} title={`${model?._hospital?.name || 'Näbelli'} #`+model?.visitId}
            buttonProps={{className:'d-none'}} modalSize="modal-xl">
            {
                pdfUrl ? 
                <embed
                    src={pdfUrl}
                    width="100%"
                    height={(height-200)+'px'}
                    type="application/pdf"
                />
                :
                null
            }
        </ModalWithButton>
    );
}

function base64ToUint8Array(base64String) {
    const binaryString = atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
}

function decompressGzip(base64GzippedString) {
    const compressedData = base64ToUint8Array(base64GzippedString);
    const decompressedData = pako.ungzip(compressedData);
    return decompressedData;
}

function uint8ArrayToBase64(uint8Array) {
    let binary = '';
    const len = uint8Array.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binary);
}

function createPdfUrlFromDecompressedData(decompressedData) {
    const pdfBlob = new Blob([decompressedData], { type: 'application/pdf' });
    return URL.createObjectURL(pdfBlob);
}

export default ViewAlternateVisit;