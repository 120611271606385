import { useContext } from "react";
import { LangContext } from "../../context/LangContext";
import { AccentButton } from "../button/button";
export default function NoResult({text=null, isRefreshable = false, refreshFunction = ()=>{}, 
    icon=<i className="bi bi-exclamation-triangle fs-1"/>, refreshContent = null, ...props}){
    const {langDictionary} = useContext(LangContext);
    const c = <>
        <i className="bi bi-arrow-repeat me-2"/>
        {langDictionary?.refresh}
    </>;
    return (
        <div className="position-relative w-100 p-2 justify-content-center align-items-center text-center">
            <div className="d-flex align-items-center justify-content-center fs-6 p-3">
                <p className="text-center text-secondary mb-2">
                    {icon}
                    <br/>
                        {text || langDictionary?.no_data}
                    <br /> 
                    {
                        isRefreshable ?
                        <AccentButton theme="secondary" onClick={refreshFunction} className="mt-3">
                            {refreshContent || c}
                        </AccentButton>
                        :
                        null
                    }
                </p>
                {
                    props.children
                }
            </div>
        </div>
    )
}