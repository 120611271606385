import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { checkRequire, districtTypes, getFormattedDate, getString, villageTypes } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import { handleErrors } from "../../../../utils/error-handler";
import TableStatusItem from "../../../../components/status-item/status-item";

export default function AdminHomes(){
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({districts:[], villages:[], streets:[]});
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({title:''});
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:10, regions:[]});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout, user} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-homes-modal';
    const pageKey="admin-homes-page";
    const pageTitle="Jaýlar";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getHome('elements').then((result)=>{
                if(result.status == 200){
                    setOptions(result.data);
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }, [isReady])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Ady" filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.name) ? getString(item?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Köçe" filter={filter} setFilter={setFilter} targetAttribute='street'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?._street?.name || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Etrapça" filter={filter} setFilter={setFilter} targetAttribute='micro_district'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?._micro_district?.name || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Tertip belgisi" filter={filter} setFilter={setFilter} targetAttribute='order'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?.order || '-//-'}
                    </span>
                )
            }
        },
        TableStatusItem(filter, setFilter),
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '/', true)}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu jaý ýok etmekçimi?")){
                                AxiosHelper.deleteHome(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Jaý üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                })
                            }
                        }}>
                            <i className="bi bi-trash2"/>
                        </button>
                        <button className="btn btn-sm bg-accent-primary text-primary m-1" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"/>
                        </button>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getHomes(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateHome(form?.id, form, token) : AxiosHelper.createHome(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Jaý üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, logout, errors, setErrors)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)) err.name='Ady boş bolup bilmez!'

        if(!checkRequire(form?.street)) errors.street = 'Köçäni saýlaň!'

        setErrors({...errors, ...err})
        return err.name || err.street;
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh} pageKey={pageKey} pageTitle={pageTitle} 
            startBlock={
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Jaý üýtgetmek' :"Täze jaý goşmak"}
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} onButtonClick={()=>{setIsUpdate(false); setForm({title:'', short_name:'', region:''}); setErrors({})}}>
                    <Form form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save} options={options}/>
                </ModalWithButton>
            } />
    )
}