import { Link, useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../../context/LangContext";
import { genders, getAge, getFullname, toggleSidebar } from "../../../../utils/main";
import CopyableText from "../../../../components/copyable-text/copyable-text";
import BarcodeModal from "../../../../components/barcode-modal/barcode-modal";
import ProfileImg from "../../../../components/profile-img/profile-img";
import Configurations from "../../../../config/configurations";
import Modules from "../../../../utils/modules";
import { AuthContext } from "../../../../context/AuthContext";
import STATUSES from "../../../../utils/statuses";
import { RedFlagModal } from "../../../../components/red-flags/red-flags";

export default function AdminSidebar({model}){
    const {langDictionary} = useContext(LangContext);
    const {user} = useContext(AuthContext)
    const params = useParams();
    const basePath = '/patients/'+params?.patientId;
    const location = useLocation();
    function keyDownHandler(e){
        if(e.altKey && e.code == 'KeyL'){
            toggleSidebar();
        }
    }
    useEffect(()=>{
        document.addEventListener('keyup', keyDownHandler);
        return ()=>document.removeEventListener('keyup', keyDownHandler);
    }, []);
    const items = [
        {icon:"bi bi-grid", title:langDictionary?.common_info, link:basePath, isExtensable:false, key:Modules.PATIENTS_VIEW},
        {icon:"bi bi-journal-text", title:langDictionary?.visits, link:`${basePath}/visits`, isExtensable:true, key:Modules.PATIENTS_VISITS},
        {icon:"bi bi-file-earmark-medical", title:langDictionary?.analyses, link:`${basePath}/analyse-results`, isExtensable:true, key:Modules.PATIENTS_ANALYSE_RESULTS,
            children:[
                {title:langDictionary?.specializations, link:`${basePath}/analyse-results/${STATUSES.SPEC}`},
                {title:langDictionary?.laboratory, link:`${basePath}/analyse-results/${STATUSES.LABORATORY}`},
                {title:langDictionary?.radiology, link:`${basePath}/analyse-results/${STATUSES.RADIOLOGY}`},
                {title:langDictionary?.instrumental_analyses, link:`${basePath}/analyse-results/${STATUSES.INSTRUMENTAL}`}
            ]
        },
        {icon:'bi bi-receipt', title:langDictionary?.registers, link:basePath+'/registers', key:Modules.PATIENTS_REGISTERS}
    ];
    return (
        <aside className="sidebar">
            <div className="p-2">
                {
                    !model?.id ?
                    null
                    :
                    <div className="d-grid align-items-center justify-content-center bg-accent-primary text-slatgray rounded-2 text-center position-relative mb-1" 
                        id="profile-block">
                        <div className="mx-auto position-relative">
                            <ProfileImg 
                                imagePosition={model?.thumbnail_position}
                                src={model?.thumbnail ? `${Configurations.getStaticServer()}patient/${model?.id}/${model?.thumbnail}` : undefined} 
                                height={40} id="profile-img"/>
                            {
                                model?.red_flags && model?.red_flags?.length ?
                                <span className="red-flag-indicator">
                                    {model?.red_flags?.length}
                                </span>
                                :
                                null
                            }
                        </div>
                        <div className="infos">
                            <div className="text-center fw-500 text-medium text-wrap">{getFullname(model, true)}</div>
                            <div className="fw-500 text-small text-primary mb-1">
                                <CopyableText text={model?.id} textAppendix="Saglyk ID:"/>
                            </div>
                            <div className="text-secondary text-small fw-500">
                                <span className="me-2">
                                    <i className="bi bi-calendar2 me-1"/>
                                    {getAge(new Date(model?.birth_date), (model?.post_mortem ? model?.post_mortem?.date : undefined))} {langDictionary?.age}
                                </span>
                                <span>
                                    <i className="bi bi-gender-male me-1"/>
                                    {genders[model?.gender]}
                                </span>
                            </div>
                            {
                                model?.post_mortem &&
                                <div className="position-absolute top-0 start-0 m-1">
                                    <span className="bg-accent-violett text-violett rounded-pill p-1 px-2 text-small fw-500">
                                        {langDictionary?.death}
                                    </span>
                                </div>
                            }
                            <div className="d-flex position-absolute top-0 end-0 m-2">
                                <BarcodeModal height={'25'} id="patient-barcode-viewer-modal" value={model?.id}/> 
                            </div>
                            <div className="d-flex position-absolute top-0 start-0 m-2">
                                <RedFlagModal data={model?.red_flags}/>
                            </div>
                        </div>
                    </div>
                }
                <div className="mb-auto">
                    <ul className="sidebar-navs flex-column">
                        {
                            items.map((item)=>{
                                if(item && user?._role && user?._role?.modules && user?._role?.modules?.includes(item?.key)){
                                    if(item?.children){
                                        return(<ExtendedItem item={item}/>)
                                    }else{
                                        return (
                                            <Link to={item.link} className={`nav-item ${(item?.link === location?.pathname || item?.isExtensable && location.pathname.match('^'+item?.link+'/')) ? 'active' : ''}`}>
                                                <i className={item?.icon}/>
                                                <span>
                                                    {item.title}
                                                </span>
                                            </Link>
                                        )
                                    }
                                }
                            })
                        }
                    </ul>
                </div>
            </div>
        </aside>
    )
}

function ExtendedItem({item}) {
    const location = useLocation()
    const [isActive, setIsActive] = useState(false) 
    return(
        <div className={`extended ${isActive && 'show'}`}>
            <div className={`nav-item ${(item?.link === location?.pathname || item?.isExtensable && location.pathname.match('^'+item?.link+'/')) ? 'active' : ''}`}
                onClick={()=>setIsActive(!isActive)}>
                <i className={item?.icon}/>
                <span>
                    {item.title}
                </span>
                <i className={`bi bi-chevron-down ms-auto showIndicator`}/>
            </div>
            <div className={'childrenBlock'}>
                <div className={'verticalLine'}/>
                {
                    Array.from(item?.children).map((child)=>{
                        return(
                            <Link to={child?.link} className={`${'childNavtem'} ${child?.link === location?.pathname ? 'active' : ''}`}>
                                <i className={`bi bi-circle-fill nodeIndicator`}/>
                                {child?.title}
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}