import { useContext, useEffect, useRef, useState } from "react";
import { appendNotification } from "../../../../components/notification";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../../utils/error-handler";
import { AuthContext } from "../../../../context/AuthContext";
import Loading from "../../../../components/loading/Loading";
import { LangContext } from "../../../../context/LangContext";
import Select from "../../../../components/select/select";
import InputBlock from "../../../../components/input-block/input-block";
import Modal from "../../../../components/modal/modal";
import Button, { AccentButton } from "../../../../components/button/button";
import { checkRequire, getString, isDateEqualOrLessThanMin, isDateEqualOrLessThanPresent } from "../../../../utils/main";

const MODAL_ID = 'document-modal'

export default function PatientDocuments({_documents=[], modelId = null, isRaw = false, isCreate = false}){
    const {isReady, logout} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    const [documents, setDocuments] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [form, setForm] = useState({})
    const toggler = useRef(null)
    useEffect(()=>{
        setDocuments(_documents)
    }, [_documents])
    function refresh(){
        if(isReady && modelId){
            setIsLoading(true)
            AxiosHelper.getPatientDocuments({patient_id:modelId}).then((result)=>{
                if(result.status == 200){
                    setDocuments(result.data?.data)
                }
            }).catch((e)=>handleErrors(e, logout)).finally(()=>setIsLoading(false))
        }
    }
    return (
        isRaw ?
        <MainContent form={form} setForm={setForm} isLoading={isLoading} documents={documents} modelId={modelId} refresh={refresh} toggler={toggler}/>
        :
        <div className="p-2 rounded-3 mb-3 box-shadow border">
            <div className="d-grid text-secondary">
                <div className="d-flex align-items-center mb-1">
                    <span className="text-dark fw-500">
                        {langDictionary?.documents} ({documents?.length})
                    </span>
                    <button className="btn btn-sm ms-auto" onClick={refresh}>
                        <i className="bi bi-arrow-repeat"/>
                    </button>
                </div>
                <MainContent form={form} setForm={setForm} isLoading={isLoading} documents={documents} modelId={modelId} refresh={refresh} toggler={toggler}/>
            </div>
        </div>
    )
}

function MainContent({isLoading, documents, modelId, form, setForm, refresh, toggler, isCreate = false}) {
    const {langDictionary} = useContext(LangContext)
    const {isReady, logout} = useContext(AuthContext)
    return(
        <>
            {
                isLoading ?
                <Loading/>
                :
                documents && documents?.length ?
                <div className="rounded-3 overflow-hidden mb-2">
                    <table className="table table-hover text-small table-sm m-0">
                        <tbody>
                        {
                            documents.map((document, index)=>{
                                return(
                                    <tr key={`patient-document-${document?.id}`}>
                                        <td>{index+1}.</td>
                                        <td>{document?._doc_type?.name}</td>
                                        <td>{document?._serial_key?.name} {document?.number}</td>
                                        <td className="text-end">
                                            <AccentButton theme="primary" className="me-2" buttonSize="little"
                                                onClick={()=>{
                                                    setForm({...document})
                                                    if(toggler.current) toggler.current.click()
                                                }}>
                                                <i className="bi bi-pencil"/>
                                            </AccentButton>
                                            <button className="btn btn-little bg-accent-danger text-danger" onClick={()=>{
                                                if(window.confirm(langDictionary?.confirm_document_delete)){
                                                    AxiosHelper.deletePatientDocument(document?.id).then((result)=>{
                                                        if(result.status == 200){
                                                            appendNotification(`${langDictionary?.document} ${(langDictionary?.successfully_deleted+'').toLowerCase()}`)
                                                            refresh()
                                                        }
                                                    }).catch((e)=>handleErrors(e, logout))
                                                }
                                            }}>
                                                <i className="bi bi-trash2"/>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                :
                null
            }
            {
                isCreate && modelId ?
                <div className="d-flex align-items-center justify-content-center">
                    <Button theme="light" className="px-3 text-primary" onClick={()=>setForm({type:'', number:'', serial_key:'', given_date:''})} 
                        data-bs-toggle='modal' data-bs-target={`#${MODAL_ID}`}>
                        <i className="bi bi-plus-lg me-2"/>
                        {langDictionary?.add_new}
                    </Button>
                </div>
                :
                null
            }
            <DocumentModal form={form} setForm={setForm} refresh={refresh} patient_id={modelId}/>
            <button className="d-none" data-bs-toggle='modal' data-bs-target={`#${MODAL_ID}`} ref={toggler}/>
        </>
    )
}

function DocumentModal({form={}, setForm=()=>{}, refresh=()=>{}, patient_id=''}){
    const {langDictionary} = useContext(LangContext)
    const {logout} = useContext(AuthContext)
    const [errors, setErrors] = useState({})
    const closeRef = useRef(null)
    function save(){
        const {isError, errors} = validate();
        if(isError){
            setErrors(errors)
            appendNotification(langDictionary?.attempt_retry_after_fix, 'danger')
        }else{
            if(window.confirm('Siz hakykatdan hem bu resminamany ýatda saklamakçymy?')){
                (form?.id ? AxiosHelper.updatePatientDocument(form?.id, form) : AxiosHelper.createPatientDocument({patient_id,...form})).then((result)=>{
                    if(result.status == 200){
                        appendNotification(`${langDictionary?.document} ${getString(langDictionary?.successfully_saved).toLowerCase()}`, 'success')
                        if(closeRef?.current) closeRef.current.click()
                        refresh()
                    }
                }).catch((e)=>handleErrors(e, logout))
            }
        }
    }

    useEffect(()=>setErrors({}), [form?.id])

    function validate(){
        let errors = {}

        if(!checkRequire(form?.type)) errors.type='Resminama görnüşini saýlaň'
        
        if(form?._type && form?._type?.isSerialKeyRequired) errors.serial_key='Resminama seriýa belgisini saýlaň'
    
        if(!checkRequire(form?.number)){
            errors.number='Resminama belgisini giriziň'
        }else if(String(form?.number).length > 6){
            errors.number='Resminama belgisini uzynlygy 6 sifr bolmaly'
        }
    
        if(checkRequire(form?.given_date) && (!isDateEqualOrLessThanPresent(form?.given_date) || !isDateEqualOrLessThanMin(form?.given_date))) errors.given_date='Resminama berlen senesini dogry  giriziň'
    
        return {isError: errors.type || errors.number || errors.given_date || errors?.serial_key, errors}
    }
    return(
        <Modal id={MODAL_ID} isFooter={false} isHeader={false}
            modalSize="modal-lg">
            <div className="d-flex align-items-center fs-5 fw-500">
                <span className="text-slatgray">
                    {form?.id ? langDictionary?.update_document : langDictionary?.add_new_document}
                </span>
                <div className="d-flex align-items-center ms-auto">
                    <Button theme="light" className="px-2" data-bs-dismiss="modal" ref={closeRef}>
                        <i className="bi bi-x-lg me-2"/>
                        {langDictionary?.close}
                    </Button>
                    <Button theme="primary" className="ms-2 px-2" onClick={save}>
                        <i className="bi bi-check2-square me-2"/>
                        {langDictionary?.save}
                    </Button>
                </div>
            </div>
            <hr className="my-2" />
            <DocumentForm errors={errors} form={form} setForm={setForm}/>
        </Modal>
    )
}

function DocumentForm({form={}, setForm=()=>{}, errors={}}){
    const {langDictionary} = useContext(LangContext)
    const {isReady, logout} = useContext(AuthContext)
    const [options, setOptions] = useState({doc_types:[], serial_keys:[]})
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getPatientDocumentsFilter().then((result)=>{
                if(result.status == 200){
                    setOptions({...result.data})
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }, [isReady])
    return(
        <div className="row">
            <div className="col-md-12">
                <Select error={errors?.type} id={'admin-type'} items={options?.doc_types} label={langDictionary?.document_type} value={form?.type} 
                    setValue={(e)=>{
                        const _type = options.doc_types?.filter((_)=>_?.id == e)[0]
                        setForm({...form, type:e, _type, serial_key:''})
                    }} isRequired={true}/>
            </div>
            <div className="col-md-6">
                <Select error={errors?.serial_key} id={'admin-serial_key'} items={options?.serial_keys} label={langDictionary?.serial_number} value={form?.serial_key} 
                    setValue={(e)=>setForm({...form, serial_key:e})} condition={(item)=>item?.type == form?.type} isRequired={form?._type?.isSerialKeyRequired}/>
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.given_date} id={'admin-patients-form-given_date'} label={langDictionary?.given_date} 
                    value={form?.given_date} setValue={(e)=>setForm({...form, given_date:e})} type="date"/>
            </div>
            <div className="col-md-12">
                <InputBlock error={errors?.number} id={'admin-patients-form-doc-number'} label={langDictionary?.document_number} 
                    value={form?.number} setValue={(e)=>setForm({...form, number:e})} type="number" icon={'bi bi-123'} isRequired={true}/>
            </div>
        </div>
    )
}