import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import AxiosHelper from "../../../utils/API/AxiosHelper";
import SettingsBlock from "../../../components/settings-block/settings-block";
import { Link, useNavigate } from "react-router-dom";
import { handleErrors } from "../../../utils/error-handler";
import { LangContext } from "../../../context/LangContext";
import Filter from "./filter";
import { PatientTableItems } from "../../../utils/table-items";
import { AccentButton } from "../../../components/button/button";
import { appendNotification } from "../../../components/notification";

export default function AdminPatients({isRegister = false, isComing = false}){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:10});
    const [isLoading, setIsLoading] = useState(false);
    const {isReady, token, logout} = useContext(AuthContext);
    const {langDictionary} = useContext(LangContext);
    const pageKey="admin-patients-page";
    const pageTitle= isRegister ? langDictionary?.registration : isComing ? langDictionary?.reception : langDictionary?.patients;
    const navigate = useNavigate();
    useEffect(()=>{
        refresh()
    }, [isReady, filter]);
    const items = PatientTableItems(filter, setFilter, isRegister, isComing, refresh)
    function refresh(){
        if(isReady){
            setIsLoading(true);
            AxiosHelper.getPatients(filter, token).then((result)=>{
                if(result.status == 200){
                    setData(result.data?.data);
                    setCount(result.data?.count);
                }
            }).catch((e)=>{
                handleErrors(e, logout, errors, setErrors)
            }).finally(()=>setIsLoading(false));
        }
    }
    return (
        <SettingsBlock
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh} onDoubleClick={(item)=>{
                navigate(`/patients/${item?.id}`)
            }} pageTitleEndComponent={
                <div className="d-flex align-items-center">
                    <AccentButton theme="violett" className="me-2" onClick={()=>{
                        if(window.confirm("Siz hakykatdan hem ahli rayatlkary indeksirlemekcimi?")){
                            setIsLoading(true)
                            AxiosHelper.indexPatients().then((result)=>{
                                appendNotification('All indexed')
                            }).catch((e)=>handleErrors(e, logout))
                            .finally(()=>setIsLoading(false))
                        }
                    }}>
                        <i className="bi bi-list-check me-2"/>
                        Indeksirle
                    </AccentButton>
                <Filter filter={filter} setFilter={setFilter}/>
                </div>
            }
            pageKey={pageKey} pageTitle={pageTitle}
            startBlock={
                <Link to={!isRegister && !isComing ? '/patients/create' : '/visits/create'} className="btn btn-sm btn-primary text-nowrap px-3">
                    <i className="bi bi-plus-lg me-2"/>
                    {langDictionary?.add_new}
                </Link>
            } 
            filterBottomBlock={<FilterRow filter={filter} setFilter={setFilter}/>}/>
    )
}

function FilterRow({filter={}, setFilter=()=>{}}) {
    return(
        <div className="d-flex align-items-center">
            
        </div>
    )
}