import InputBlock from "../../../../components/input-block/input-block";
import Select from "../../../../components/select/select";
import { epidStatuses } from "../../../../utils/main";
export default function Form({form, setForm, errors, saveFunction}){
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
            <div className="row">
                <div className="col-md-12">
                    <InputBlock error={errors?.name} id={'admin-epids-form-name'} label={'Ady'} 
                        value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                        icon={'bi bi-keyboard'} />
                </div>
                <div className="col-md-6">
                    <InputBlock error={errors?.order} id={'admin-bed-form-order'} label={'Tertip belgisi'} 
                        value={form?.order} setValue={(e)=>setForm({...form, order:e})} type="number"
                        icon={'bi bi-123'} />
                </div>
                <div className="col-md-6">
                    <Select error={errors?.status} id={'analyse-type-status'} items={epidStatuses} label={"Statusy"}
                        value={form?.status} setValue={(e)=>setForm({...form, status:e})} itemTitleLabel="title" itemValueLabel="value"/>
                </div>
            </div>
        </form>
    )
}