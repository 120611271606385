import { useContext } from "react";
import { LangContext } from "../../../../context/LangContext";
import Modules from "../../../../utils/modules";
import { arrayIncluding } from "../../../../utils/main";
import Button from "../../../../components/button/button";
import Settings from "../../../../utils/settings";

export default function ModulesForm({form, setForm, errors, setErrors}){
    const {langDictionary} = useContext(LangContext)
    const arr = [
        {
            title:langDictionary?.patients, id:'patients', 
            children:[
                {title:langDictionary?.patients, value:Modules.PATIENTS},
                {title:langDictionary?.common_info, value:Modules.PATIENTS_VIEW},
                {title:langDictionary?.update, value:Modules.PATIENTS_UPDATE},
                {title:langDictionary?.add_new, value:Modules.PATIENTS_CREATE},
                {title:langDictionary?.delete, value:Modules.PATIENTS_DELETE},
                {title:langDictionary?.visits, value:Modules.PATIENTS_VISITS},
                {title:langDictionary?.analyse_plans, value:Modules.PATIENTS_ANALYSE_PLANS},
                {title:langDictionary?.analyse_results, value:Modules.PATIENTS_ANALYSE_RESULTS},
                {title:langDictionary?.payments, value:Modules.PATIENTS_PAYMENTS},
                {title:langDictionary?.registers, value:Modules.PATIENTS_REGISTERS}
            ]
        },
        {
            title:langDictionary?.visits, id:'visits', 
            children:[
                {title:langDictionary?.visits, value:Modules.VISITS},
                {title:langDictionary?.patient_acceptance, value:Modules.VISITS_VIEW},
                {title:langDictionary?.add_new, value:Modules.VISITS_CREATE},
                {title:langDictionary?.update, value:Modules.VISITS_UPDATE},
                {title:langDictionary?.delete, value:Modules.VISITS_DELETE},
                {title:langDictionary?.analyse_plans, value:Modules.VISITS_ANALYSE_PLANS},
                {title:langDictionary?.laboratory_analyses, value:Modules.VISITS_LAB},
                {title:langDictionary?.radiological_analyses, value:Modules.VISITS_RAD},
                {title:langDictionary?.special_analyses, value:Modules.VISITS_SPEC},
                {title:langDictionary?.instrumental_analyses, value:Modules.VISITS_INSTRUMENTAL},
                {title:langDictionary?.diagnose, value:Modules.VISITS_DIAGNOSE},
                {title:langDictionary?.clynical_epikris, value:Modules.VISITS_CLYNIC},
                {title:langDictionary?.doctor_analyse, value:Modules.VISITS_DOCTOR_ANALYSE},
                {title:langDictionary?.treatment_order, value:Modules.VISITS_TR_ORDER},
                {title:langDictionary?.treatment_plan, value:Modules.VISITS_TR_PLAN},
                {title:langDictionary?.treatment_diary, value:Modules.VISITS_TR_DIARY},
                {title:langDictionary?.operations, value:Modules.VISITS_OPERATIONS},
                {title:langDictionary?.arb, value:Modules.VISITS_ARB},
                {title:langDictionary?.consiliums, value:Modules.VISITS_CONSILIUMS},
                {title:langDictionary?.arb_diary, value:Modules.VISITS_ARB_DIARY},
                {title:langDictionary?.out_epikris, value:Modules.VISITS_OUT},
                {title:langDictionary?.pm_epikris, value:Modules.VISITS_PM},
                {title:langDictionary?.payments, value:Modules.VISITS_PAYMENTS},
                {title:langDictionary?.illnes_history, value:Modules.VISITS_HISTORY}
            ]
        },
        {
            title:langDictionary?.radiology, id:'radiology', 
            children:[
                {title:langDictionary?.radiology, value:Modules.RADIOLOGY},
                {title:langDictionary?.radiological_analyses, value:Modules.RADIOLOGY_ANALYSES},
                {title:langDictionary?.view, value:Modules.RADIOLOGY_ANALYSE_VIEW},
            ]
        },
        {
            title:langDictionary?.laboratory, id:'laboratory', 
            children:[
                {title:langDictionary?.laboratory, value:Modules.LABORATORY},
                {title:langDictionary?.laboratory_analyses, value:Modules.LABORATORY_ANLAYSES},
                {title:langDictionary?.view, value:Modules.LABORATORY_ANLAYSE_VIEW},
            ]
        },
        {
            title:langDictionary?.specializations, id:'specializations', 
            children:[
                {title:langDictionary?.specializations, value:Modules.SPEC},
                {title:langDictionary?.special_analyses, value:Modules.SPEC_ANALYSES},
                {title:langDictionary?.view, value:Modules.SPEC_ANALYSE_VIEW}
            ]
        },
        {
            title:langDictionary?.instrumental_analyses, id:'instrumental_analyses', 
            children:[
                {title:langDictionary?.instrumental_analyses + ' bolum', value:Modules.INSTRUMENTAL},
                {title:langDictionary?.instrumental_analyses, value:Modules.INSTRUMENTAL_ANALYSES},
                {title:langDictionary?.view, value:Modules.INSTRUMENTAL_ANALYSE_VIEW}
            ]
        },
        {
            title:langDictionary?.cashier, id:'cashier', 
            children:[
                {title:langDictionary?.cashier, value:Modules.CASHIER},
                {title:langDictionary?.reports, value:Modules.CASHIER_REPORTS}
            ]
        },
        {
            title:langDictionary?.settings, id:'settings', 
            children:[
                {title:'Ulanyjylar', value:Settings.USERS},
                {title:'Ulanyjy rollary', value:Settings.ROLES},
                {title:'Hünärler', value:Settings.SPECIALIZATIONS},
                {title:'Wezipeler', value:Settings.POSITIONS},
                {title:'Resminama görnüşleri', value:Settings.DOCUMENT_TYPES},
                {title:'Seriýa belgileri', value:Settings.DOCUMENT_SERIAL_KEYS},
                {title:'Ýeňillik kategoriýalary', value:Settings.BENEFITS},
                {title:'Garyndaşlyk görnüşleri', value:Settings.RELATION_TYPES},
                {title:'Döwletler', value:Settings.COUNTRIES},
                {title:'Welaýatlar', value:Settings.REGIONS},
                {title:'Etraplar', value:Settings.VILLAGES},
                {title:'Şäherler', value:Settings.DISTRICTS},
                {title:'Bölümler', value:Settings.DEPARTMENTS},
                {title:'Otaglar', value:Settings.ROOMS},
                {title:'Bejergi ýeri', value:Settings.BEDS},
                {title:'Ýüztutma elementleri', value:Settings.VISIT_ELEMENTS},
                {title:'Ýüztutma görnüşi', value:Settings.VISIT_TYPES},
                {title:'Ýüztutma usuly', value:Settings.VISIT_METHODS},
                {title:'Ýüztutma netijesi', value:Settings.VISIT_RESULTS},
                {title:'Kesel kesgidi', value:Settings.DIAGNOSES},
                {title:'Epidemiologiki anamnezler', value:Settings.EPID_ANAMNEZES},
                {title:'Ýüztutma sorag bölümleri', value:Settings.VISIT_SECTIONS},
                {title:'Şol bir kesel bilen ýüz tutan sany', value:Settings.VISIT_COUNTS},
                {title:'Zyýanly endikler', value:Settings.BAD_HABITS},
                {title:'Mahsus keseller', value:Settings.LIKE_ILLS},
                {title:'Register görnüşi', value:Settings.REGISTRATION_TYPE},
                {title:'Operasiýa görnüşleri', value:Settings.OPERATION_TYPES},
                {title:'Operasiýa otaglary', value:Settings.OPERATION_ROOM},
                {title:'ARB otaglary', value:Settings.ARB_ROOMS},
                {title:'ARB ýerleri', value:Settings.ARB_BED},
                {title:'Lab. barl. görnüşi', value:Settings.ANALYSE_TYPES},
                {title:'Barlag attributlary', value:Settings.ANALYSE_ATTRIBUTES},
                {title:'Barlag nusgalary', value:Settings.ANALYSE_PROBES},
                {title:'Rad. barl. görnüşi', value:Settings.RD_TYPES},
                {title:'Rad. barl. sowallary', value:Settings.RD_QUESTIONS},
                {title:'Rad. jogap wariantlary', value:Settings.RD_ANSWERS},
                {title:'Radiologiki kesel kesgidi', value:Settings.RD_TYPE_DIAGNOSES},
                {title:'Inçe hün. barl. görnüşi', value:Settings.SP_TYPES},
                {title:'Inçe hün. barl. sowallary', value:Settings.SP_QUESTIONS},
                {title:'Inçe hün. jogap wariantlary', value:Settings.SP_ANSWERS},
                {title:'Inçe hün. barlag netijeleri', value:Settings.SP_DIAGNOSE_TYPES},
                {title:'Enjamlaýyn barlag görnüşi', value:Settings.INSTRUMENTAL_ANALYSE_TYPES},
                {title:'Enjamlaýyn barlag sowallary', value:Settings.INSTRUMENTAL_ANALYSE_QUESTIONS},
                {title:'Enjamlaýyn barlag jogap wariantlary', value:Settings.INSTRUMENTAL_ANALYSE_ANSWERS},
                {title:'Bejergi görnüşi', value:Settings.TREATMENT_TYPES},
                {title:'Gündelik parametrleri', value:Settings.TR_PARAMS},
                {title:'ARB parametrleri', value:Settings.ARB_PARAMS},
                {title:'Bejergi elementleri', value:Settings.PR_ITEMS},
                {title:'Töleg kategoriýalary', value:Settings.PAYMENT_CATEGORIES},
                {title:'Nyrhnama', value:Settings.PAYMENT_TARIFFS},
                {title:'Arzanladyş görnüşleri', value:Settings.DISCOUNT_TYPES},
                {title:'Bilim derejeleri', value:Settings.EDU_GRADES},
                {title:'Ylmy derejeler', value:Settings.SC_GRADES},
                {title:'Okuw jaýlary', value:Settings.EDU_INSTITUTES},
                {title:'Aspirantura okuw görnüşleri', value:Settings.EDU_TYPES},
                {title:'Ylmy hünärler', value:Settings.SC_SPECS},
                {title:'Ylmy barlaghanalar', value:Settings.SC_LABS},
                {title:'Ylmy ugurlar', value:Settings.SC_DIRECTIONS},
                {title:'Faýl görnüşleri', value:Settings.SC_FILE_TYPES},
                {title:'Loglar', value:Settings.LOGS},
                {title:'Dumplar', value:Settings.DUMPS},
            ]
        },
        {
            title:langDictionary?.others, id:'others', 
            children:[
                {title:langDictionary?.registration, value:Modules.REGISTERS},
                {title:langDictionary?.reception, value:Modules.COMINGS},
                {title:langDictionary?.ambulator, value:Modules.AMBULATOR},
                {title:langDictionary?.treatments, value:Modules.TREATMENTS},
                {title:langDictionary?.reports, value:Modules.REPORTS},
                {title:langDictionary?.science, value:Modules.SCIENCE},
                {title:langDictionary?.statistics, value:Modules.STATISTICS},
                {title:langDictionary?.settings, value:Modules.SETTINGS},
                {title:langDictionary?.aspirants, value:Modules.ASPIRANTS},
                {title:langDictionary?.library, value:Modules.LIBRARY},
                {title:langDictionary?.room_management, value:Modules.ROOMS}
            ]
        },
    ]
    return(
        <div className="border rounded-3 overflow-hidden mb-3">
            <div className="w-100 text-start text-slatgray d-flex align-items-center bg-light p-2 rounded-3 fw-500 cursor-pointer bottom-radius-0 border-bottom collapsed"
                data-bs-toggle="collapse" aria-expanded="" data-bs-target={`#admin-roles-form-modules`} aria-controls="contents-collapse">
                {langDictionary?.enabled_pages}
                <Button theme="light" className="ms-auto">
                    <i className="bi bi-chevron-compact-down"/>
                </Button>
            </div>
            <ul id={'admin-roles-form-modules'} className="nav-content list-unstyled tree m-0 px-2 collapse pb-2">
                {
                    arr.map((item)=>{
                        const all = item.children.map(_=>_.value) 
                        return(
                            <li className="nav-item">
                                {/* <div className={'d-flex align-items-top mb-2 p-1 text-slatgray fw-500 rounded-2 px-3'}>
                                </div> */}
                                <button class="btn d-inline-flex align-items-center border-0 w-100" data-bs-toggle="collapse"
                                    aria-expanded="" data-bs-target={`#${item?.id}`} aria-controls="contents-collapse">
                                    {item?.title}
                                </button>
                                <ul id={item?.id} className="nav-content list-unstyled collapse ms-5">
                                    <li className="form-check">
                                        <input className={'form-check-input'} type="checkbox" name={`${item?.id}-all`} id={`${item?.id}-all`} 
                                            checked={arrayIncluding(form.modules, all)}
                                            onClick={(e)=>{
                                                let arr = [...form.modules]
                                                arr = arr.filter((_)=>!all.includes(_))
                                                if(e.target.checked) arr.push(...all)
                                                setForm({...form, modules:arr})
                                            }}/>
                                        <label htmlFor={`${item?.id}-all`} className="form-check-label">
                                            {langDictionary?.all}
                                        </label>
                                    </li>
                                {
                                    item.children.map((_item, index)=>{
                                        return(
                                            <li className="form-check">
                                                <input className={'form-check-input'} type="checkbox" name={`${item?.id}-${index}`} id={`${item?.id}-${index}`} onClick={(e)=>{
                                                    
                                                }} checked={form?.modules.includes(_item.value)} onChange={(e)=>{
                                                    let arr = [...form.modules]
                                                    if(e.target.checked){
                                                        arr.push(_item.value)
                                                    }else{
                                                        arr = arr.filter((_)=>_!=_item?.value)
                                                    }
                                                    setForm({...form, modules:arr})
                                                }}/>
                                                <label htmlFor={`${item?.id}-${index}`} className="form-check-label">
                                                    {_item.title}
                                                </label>
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}