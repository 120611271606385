import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function BarChart({id, height=350, text, titleColor="#263238", colors=[], series=[], toolbarShow=true, showYLabels = true, showXLabels = true, isHorizontal = false}){
    const [options, setOptions] = useState({
        options:{
            chart:{
                id:id,
                type: "bar",
                height: height,
                toolbar: {
                  show: toolbarShow
                }
            },
            title: {
                text: text,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize:  '16px',
                  fontWeight:  'bold',
                  fontFamily:  undefined,
                  color:  titleColor
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: isHorizontal,
                    columnWidth: "70%",
                    barHeight: '100%'
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2
            },
            states: {
                hover: {
                    filter: {
                    type: "none",
                    value: 0.1
                    }
                }
            },
            fill: {
                opacity: 1
            },
            colors: colors,
            legend: {
                show: false
            },
            yaxis: {
                labels: {
                    show: showYLabels, // Hide y-axis values
                    style: {
                        padding: 0, // Remove padding around y-axis labels
                    }
                },
            },
            xaxis: {
                labels: {
                    show: showXLabels, // Hide y-axis values
                    style: {
                        padding: 0, // Remove padding around y-axis labels
                    }
                },
            },
            grid: {
                yaxis: {
                    lines: {
                        show: true, // Hide y-axis grid lines
                    },
                },
                strokeDashArray: 5,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            }
        },
        series:series,
    });
    useEffect(()=>{
        setOptions({
            ...options, 
            options:{
                ...options.options, 
                chart:{
                    ...options.options.chart,
                    id, height,
                },
                title:{
                    ...options.options.title,
                    text
                },
                colors:colors
            },
            series:series
        })
    }, [id, height, text, titleColor, colors, series])
    return (
        <div className={id}>
            <Chart options={options.options} series={options.series} height={options.options.chart.height} type={options.options.chart.type}/>
        </div>
    );
}