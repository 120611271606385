import { appendNotification } from "../components/notification";

export const HOSPITAL_CODE = "AG-ON";
export function toggleSearchbar(){
    document.querySelector(".search-bar").classList.toggle("search-bar-show");
}

function select(el, all = false) {
  el = el.trim()
  if (all) {
    return [...document.querySelectorAll(el)]
  } else {
    return document.querySelector(el)
  }
}

export const LIMIT_OWN = 0
export const LIMIT_DEPARTMENT = 10
export const LIMIT_ALL = 20

export const LIMITS = {
  [LIMIT_OWN]:'Özüniňki',
  [LIMIT_DEPARTMENT]:'Bölüm',
  [LIMIT_ALL]:'Ählisi'
}

export function copyToClipboard(text, message = 'Nusgalandy'){
  text += ''
  if(typeof text === 'string'){
    navigator.clipboard.writeText(text)
    appendNotification(message)
  }
}

export function toggleSidebar(){
  select('body').classList.toggle('sidebar-active');
}

export function cropString(str, maxLength = 100) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...'; // Add ellipsis if needed
  }
  return str;
}

export function toggleRightbar(){
  select('#rightbar').classList.toggle('active');
}

export function openSidebar(){
  if(!select('body').classList.contains('sidebar-active')){
    select('body').classList.add('sidebar-active')
  }
}

export function toggleFastLinks(){
  select('li.fast-links>ul').classList.toggle('show');
}

export function toDate(timestamp){
  let date = new Date(timestamp);
  return date.getFullYear()+"-"+getMonth(date)+"-"+getDay(date);
}

export function getDay(date){
  let day = date.getUTCDate();
  if(day<10){
    return "0"+day;
  }else{
    return day;
  }
}

export function getMonth(date){
  let month = date.getUTCMonth()+1;
  if(month<10){
    return "0"+month;
  }else{
    return month;
  }
}

export function toTimestamp(date){
  let arr = date.split("-");
  let newDate = new Date(arr[0], arr[1]-1, arr[2]);
  return newDate.getTime();
}


export function getStatuses(){
  const statuses = {
    0 : "Aktiw däl",
    10 : "Aktiw",
    90 : "Bloklanan",
    99 :"Ýok edilen" 
  };
  return statuses;
}

export function getStatusesArr(){
  const statuses = [
    {value: 0, txt : "Aktiw däl"},
    {value: 10, txt : "Aktiw"},
    {value: 90, txt : "Bloklanan"},
    {value: 99, txt : "Ýok edilen"}
  ];
  return statuses;
}

export const userStatus = 0;
export const adminStatus = 10;

export function getRoles(){
  return {
    0 : "User",
    10 : "Admin"
  };
}

export function getRolesArr(){
  return [
    {value:0, txt:"User"},
    {value:10, txt : "Admin"}
  ];
}

export function getMessageTypes(){
  return {
    0 : "Autorizasiýa",
    10 : "Paroly dikelt",
    20 : "Sargydy tassykla"
  };
}

export function getMessageTypesArr(){
  return [
    {value:0, txt:"Autorizasiýa"},
    {value:10, txt:"Paroly dikelt"},
    {value:20, txt:"Sargydy tassykla"}
  ];
}


export function getMessageStatuses(){
  return {
    0 : "Nobata duran",
    10 : "Iberilen",
    20 : "Ret edilen"
  };
}

export function getMessageStatusesArr(){
  return [
    {value:0, txt:"Nobata duran"},
    {value:10, txt:"Iberilen"},
    {value:20, txt:"Ret edilen"}
  ];
}

export function setSizes(){
  let main = document.querySelector("main");
  let header = document.querySelector("header");
  main.setAttribute("style", "height:"+(window.innerHeight-header.clientHeight)+"px; margin-top:"+header.clientHeight+"px;")
}

export const folders = {
  brand:"brand_images/",
  shop:"shop_images/",
  banner:"banner_images/",
  user:"user_images/",
  product:"product_images/",
  category:"category_images/",
  type:"type_images/",
  marketplace:"marketplace_images/",
  country:"country_images/",
  song:"song_images/"
}


export const basePath = '';

export function getPrice(product){
  return ((product?.discount ? (product?.selling_price-product?.selling_price*product?.discount/100) : (product?.selling_price))*1).toFixed(2);
}

export function getString(str){
  if(str !== null && str !== undefined){
    return(str+'')
  }else{
    return "";
  }
} 

export function getLatin(str){
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, "")
}

export function firstLetterUpperCase(str){
  if((typeof str) == 'string'){
    str = `${str.substr(0,1).toLocaleUpperCase()}${str.substr(1)}`
  }
  return str;
}

export function toUppercase(str){
  if((typeof str) == 'string'){
    str = str?.toLocaleUpperCase();
  }
  return str;
}

export function reformatDate(date){
  if(date && date != null){
    const dateArr = date.split("-");
    return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
  }else{
    return "";
  }
}

export function toggleTreeItem(e){
  e?.target?.parentElement?.classList?.toggle('expanded');
}

export function addZero(number){
  if(number < 10){
    return "0"+number;
  }else{
    return number;
  }
}

export function getAge(date, end_date = undefined){
  if(date instanceof Date){
    let newDate;
    if(end_date){
      newDate = new Date(end_date)
    }else{
      newDate = new Date()
    }
    let age = newDate.getFullYear()-date?.getFullYear();
    if(age && !((newDate.getMonth() > date?.getMonth()) || (newDate.getMonth() === date?.getMonth() && newDate.getDay()>date.getDay() && newDate.getDay() !== date.getDay()))){
      age -=1
    }
    return age;
  }
  return false;
}

export function formatDate(date){
  if(date instanceof Date){
    let yyyy = date.getFullYear();
    let mm = date.getMonth()+1;
    let dd = date.getDate();
    if(dd < 10){
      dd = '0' + dd;
    }
    if(mm < 10){
      mm = '0' + mm;
    }
    return yyyy+'-'+mm+'-'+dd;
  }
  return false;
}

export function closeModal(){
  document.querySelector('.btn-close').click();
}

export function checkRequire(value){
  return value && ((value+"").trim() ? true : false);
}

export function checkPhone(phone){
  phone *=1;
  return(!isNaN(phone) && (61000000 <= phone && phone <= 99999999));
}

export function checkOnlyTmLetters(value){
  return (/^(?:(?![CcVvXxQq])[A-Za-zÝýÜüÄäŞşÖöŇňŽžÇç\s\-])+$/).test(value);
}

export function checkOnlyLetters(value){
  return (/^[A-Za-zÝýÜüÄäŞşÖöŇňŽžÇç\s\-]+$/).test(value);
}

export function checkOnlyDigit(value){
  return (/^[0123456789]+$/).test(value);
}

export const certificateTypes = [
  {id:10, name:"Kepilnama"},
  {id:20, name:"Rugsatnama"}
];

export const certificateTypesObj = {
  10 : "Kepilnama",
  20 : "Rugsatnama"
};

export const documentTypes = [
  {id:10, name:"Passport"},
  {id:20, name:"Dogluş hakynda şahadatnama"},
  {id:30, name:"Başga"}
];

export const origins = [
  {id:10, name:"Türkmenistanyň raýaty"},
  {id:20, name:"Daşary ýurt raýaty"}
];

export const gendersArr = [
  {title:"Erkek", value:'M', img:'boy.png'},
  {title:"Aýal", value:'F', img:'girl.png'},
];

export const genders = {
  M:'Erkek',
  F:'Aýal',
  U:'Näbelli'
}

export const patientCategoriesArr = [
  {title:"Maýyp I", value:'invalidI'},
  {title:"Maýyp II", value:'invalidII'},
  {title:"Maýyp III", value:'invalidIII'},
  {title:"Çagalykdan maýyp", value:'invalidFromChild'},
  {title:"Uruş weterany", value:'veteran'},
  {title:"Respondent (ylym)", value:'respondent'},
];

export const patientCategories = {
  invalidI:"Maýyp I",
  invalidII:"Maýyp II",
  invalidIII:"Maýyp III",
  invalidFromChild:"Çagalykdan maýyp",
  veteran:"Uruş weterany",
  respondent:"Respondent (ylym)"
};

export const epidsArr = [
  {title:"Holera", value:10},
  {title:"Inçekesel", value:20},
  {title:"Gepatit", value:30},
  {title:"Doňuz gripi", value:40},
  {title:"Covid-19", value:50},
];

export const opsArr = [
  {title:"0-4", value:10},
  {title:"5-14", value:10},
  {title:"15-29", value:10},
  {title:"30-44", value:10},
  {title:"45+", value:10},
];

export const libItems = [
  {title:"Gollanmalar", value:10},
  {title:"Kitaplar", value:20},
  {title:"Görkezmeler", value:30},
  {title:"Buýruklar", value:40}
];
export const libItemsObj = {
  10:"Gollanmalar",
  20:"Kitaplar",
  30:"Görkezmeler",
  40:"Buýruklar"
};


export const officeTypes = [
  {title:'Etrap', value:0},
  {title:'Welaýat', value:10},
  {title:'Döwlet', value:20},
];

export const officeTypesObj ={
  0:'Etrap',
  10:'Welaýat',
  20:'Döwlet',
};

export const booleanVals = [
  {title:'Hawa', value:true},
  {title:'Ýok', value:false}
];

export const dataTypes = [
  {title:'Tekst', value:'text'},
  {title:'San', value:'number'}
];

export const dataTypesObj = {
  text:'Tekst',
  number:'San'
};

export const epidStatuses = [
  {title:'Aktiw', value:0},
  {title:'Arhiwlenen', value:99},
];

export const habits = [
  {name:'Çilimkeşlik', id:10},
  {name:'Alkogolly içgiler', id:20},
  {name:'Neşekeşlik', id:30},
  {name:'Aşa iýermenlik', id:40}
];

export const habitsObj = {
  10:'Çilimkeşlik',
  20:'Alkogolly içgiler',
  30:'Neşekeşlik',
  40:'Aşa iýermenlik'
};

export const ills = [
  {name:'Gepatit', id:30},
  {name:'Bruselýoz', id:20},
  {name:'AIDS', id:10},
  {name:'Inçekesel', id:40}
];

export const illsObj = {
  10:'AIDS',
  20:'Bruselýoz',
  30:'Gepatit',
  40:'Inçekesel'
};

export const optionTypes = [
  {title:'Ýaş topary', value:10},
  {title:'Barlag çäre', value:20},
  {title:'Geçirilen çäre', value:30},
];

export const virusOptionTypes = [
  {title:'Ýaş topary', value:10},
  {title:'Netijesi', value:20}
];

export const virusOptionTypesObj = {
  10 : 'Ýaş topary',
  20 :'Netijesi'
};

export const epidStatusesObj = {
  0:'Aktiw',
  99:'Arhiwlenen'
};

export const professions = {
  0:'Başga',
  10:'Talyp',
  20:'Maldar',
  30:'Lukman',
  40:'Laborator işgär',
  50:'Transport işgär',
  60:'Näbelli'
};

export const passportSeries = ['I-AG', 'I-AH', 'I-MR', 'I-BN', 'I-LB', 'I-DZ', 'II-AG', 'II-AH', 'II-MR', 'II-BN', 'II-LB', 'II-DZ'];

export const personifiedRecordTypes = {
  0:'Näbelli',
  10:'Çak edilýän', 
  20:'Tassyklanan'
};

export const common_options = {
  10:'Ysgynsyzlyk',
  20:'Beden gyzgynlygy', 
  30:'Huş peselme',
  40:'Derleme'
};

export const cardio_options = {
  10:'Ysgynsyzlyk',
  20:'Ýürek agyry', 
  30:'Baş aýlanma',
  40:'Kelle agyry'
};

export const respirator_options = {
  10:'Dem gysma',
  20:'Üsgülewük', 
  30:'Ganly üsgülewük',
  40:'Howa ýetmezçiligi',
  50:'Hyžžyldy',
  60:'Döş kapasasynyň içinde agyry',
};

export const peptic_options = {
  10:'Iç geçme',
  20:'Iç agyry', 
  30:'Ganly uly täret',
  40:'Ýürek bulanma',
  50:'Gusma',
  60:'Işdäsizlik'
};

export const personRegisterActions = {
  10:'Hassa öýüne iberildi',
  20:'Hassahana ýatyryldy',
  30:'Karantine alyndy',
  40:'Barlaga iberildi',
  50:'Hassa ýogaldy',
};

export const contactActions = {
  0:'Näbelli',
  10:'Ýok',
  20:'Işde',
  30:'Öýde',
  40:'Okuwda',
  50:'Ulagda',
  50:'Başga',
};

export const analysePlanStatuses = {
  0:'Tölenmedik',
  10:'Tölenen',
  20:'Tamamlanan',
  99:'Ret edilen'
}

export const analyseStatuses = {
  0:'Tölenmedik',
  10:'Netijä garaşylýar',
  20:'Netije taýýar',
  99:'Ret edilen'
}

export const paymentStatuses = {
  0:'Tölenmedik',
  10:'Tölenen',
  99:'Ret edilen'
}

export const paymentMethods = {
  'CASH':'Nagt',
  'CARD':'Kart'
}

export function getArrayFromObject(obj, itemKey = null){
  let arr = [];
  Object.keys(obj).map((key)=>{
    if(itemKey){
      arr.push({id:key, ...obj[key]})
    }else{
      arr.push({id:key, name:obj[key]})
    }
  });
  return arr;
}


export const minBirthDate = '1900-01-01'
export const minDate = '2023-01-01'
export const maxDate  = `${new Date().getFullYear()}-${addZero(new Date().getMonth()+1)}-${addZero(new Date().getDate())}`;

export const tmMonths = ['Ýanwar', 'Fewral', 'Mart', 'Aprel', 'Maý', 'Iýun', 'Iýul', 'Awgust', 'Sentýabr', 'Oktýabr', 'Noýabr', 'Dekabr'];
export const tmShortMonths = ['Ýan', 'Few', 'Mrt', 'Apr', 'Maý', 'Iýn', 'Iýl', 'Awg', 'Sen', 'Okt', 'Noý', 'Dek'];

export function validatePatient(form, errors, setErrors, isUpdate = false){
    let err = {};
    if(!form?.isAnonim){

      if(!checkRequire(form?.name)){
          err.name='Raýat adyny giriziň'
      }else if(form?.name?.length < 2){
          err.name='Raýat ady 2 harpdan gysga bolmaly däl'
      }else if(form?.name?.length > 100){
          err.name='Raýat ady 100 harpdan uzyn bolmaly däl'
      }else if(form?._country?.code != 'TKM' && !checkOnlyLetters(form?.name)){
          err.name='Raýat ady diňe harplardan ybarat bolmaly';
      }else if((!form?._country?.code || form?._country?.code == 'TKM') && !checkOnlyTmLetters(form?.name)){
          err.name='Raýat ady diňe türkmen harplaryndan ybarat bolmaly';
      }
  
      if(!checkRequire(form?.surname)){
          err.surname='Raýat familiýasyny giriziň'
      }else if(form?.surname?.length < 2){
          err.surname='Raýat familiýasy 2 harpdan gysga bolmaly däl'
      }else if(form?.surname?.length > 100){
          err.surname='Raýat familiýasy 100 harpdan uzyn bolmaly däl'
      }else if(form?._country?.code != 'TKM' && !checkOnlyLetters(form?.surname)){
          err.surname='Raýat familiýasy diňe harplardan ybarat bolmaly';
      }else if((!form?._country?.code || form?._country?.code == 'TKM') && !checkOnlyTmLetters(form?.surname)){
          err.surname='Raýat familiýasy diňe türkmen harplaryndan ybarat bolmaly';
      }
  
      if(form?.second_name?.length > 100){
          err.second_name='Raýat atasynyň ady 100 harpdan uzyn bolmaly däl'
      }else if(form?.second_name && form?.second_name?.length < 2){
          err.second_name='Raýat atasynyň ady 2 harpdan uzyn bolmaly däl'
      }else if(form?.second_name && form?._country?.code != 'TKM' && !checkOnlyLetters(form?.second_name)){
          err.second_name='Raýat atasynyň ady diňe harplardan ybarat bolmaly';
      }else if(form?.second_name && (!form?._country?.code || form?._country?.code == 'TKM') && !checkOnlyTmLetters(form?.second_name)){
          err.second_name='Raýat atasynyň ady diňe türkmen harplaryndan ybarat bolmaly';
      }

      if(!form?.country) err.country = 'Raýatlygy saýlaň' 
      if(form?._country?.code == 'TKM'){
        if(!form?.birth_region) err.birth_region = 'Raýat hasaba alnan welaýatyny saýlaň' 
        if(!form?.birth_village && !form?.birth_city){
          err.birth_village = 'Raýat hasaba alnan etrabyny saýlaň' 
          err.birth_city = 'Raýat hasaba alnan şäherini saýlaň' 
        }
  
        if(!form?.region) err.region = 'Raýat ýaşaýan welaýatyny saýlaň' 
        if(!form?.village && !form?.city){
          err.village = 'Raýat ýaşaýan etrabyny saýlaň' 
          err.city = 'Raýat ýaşaýan şäherini saýlaň' 
        }
      }

      if(!isUpdate){
        if(!checkRequire(form?.docType)) err.docType='Resminama görnüşini saýlaň'
        
        if(!checkRequire(form?.docNumber)){
          err.docNumber='Resminama belgisini giriziň'
        }else if(String(form?.docNumber).length > 6){
          err.docNumber='Resminama belgisini uzynlygy 6 sifr bolmaly'
        }

        if(checkRequire(form?._docType) && Boolean(form?._docType?.isSerialKeyRequired) && !checkRequire(form?.serialKey)) err.serialKey='Resminama seriýa belgisini saýlaň'

        // if(!checkRequire(form?.given_date)){
        //   err.given_date='Resminama berlen senesini giriziň'
        // }else 
        if(checkRequire(form?.given_date) && (new Date(form?.given_date) < new Date(minBirthDate) || new Date(form?.given_date).getTime() > new Date().getTime())) err.given_date = 'Resminama berlen senesini dogry saýlaň'
        
        if(!checkRequire(form?.birth_date)) err.birth_date='Raýatyň doglan günini giriziň'
        if(new Date(form?.birth_date) < new Date(minBirthDate) || new Date(form?.birth_date).getTime() > new Date().getTime()) err.birth_date='Raýatyň doglan günini dogry giriziň'
      }
    }

    if(!checkRequire(form?.gender) || !['M', 'F', 'U'].includes(form?.gender)) err.gender='Raýat jynsyny saýlaň'
  
    if(form?.phone_number && !checkPhone(form?.phone_number)) err.phone_number='Raýatyň telefon belgisini dogry giriziň'

    // if(form?.home_phone_number && !checkPhone(form?.home_phone_number)) err.home_phone_number='Raýatyň telefon belgisini dogry giriziň'

    setErrors(err)
    return err.name || err.surname || err.second_name || err.birth_date || err.gender || err.docType || err.docNumber || err.country || 
            err.birth_date || err.phone_number || err.given_date || err.serialKey || err.birth_region || err.birth_village || err.birth_city
}

export function getFullname(model, isSecondName = false){
  if(model?.isAnonim){
    return 'Näbelli'
  }else{
    const label = `${firstLetterUpperCase(getString(model?.surname))} ${firstLetterUpperCase(getString(model?.name))}`+ (isSecondName ? ` ${firstLetterUpperCase(getString(model?.second_name))}` : '') 
    return String(label).trim() || `#${model?.id || ''}`
  }
}

export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function isDateEqualOrLessThanPresent(dateString){
  return (new Date(dateString).getTime() <= new Date().getTime());
}

export function isDateEqualOrLessThanMin(dateString){
  return (new Date(dateString) >= new Date(minBirthDate));
}

export function getOneMonthAgoDate() {
  const currentDate = new Date();
  
  // Subtract one month
  currentDate.setMonth(currentDate.getMonth() - 1);
  
  return currentDate;
}


export function getFormattedDate(dateStr, delimeter='/', isTime=false, isYear = true){
  if(!dateStr) return ''
  let date;
  if(isTime){
    date = new Date(convertTMZ(dateStr))
  }else{
    date = new Date(dateStr)
  }
  const day = addZero(date.getDate());
  const month = addZero(date.getMonth()+1);
  const year = date.getFullYear();
  let appendix='';
  if(isTime){
    appendix = `${addZero(date.getHours())}:${addZero(date.getMinutes())}`
  }
  return `${day}${delimeter}${month}${isYear ? delimeter + year : ''} ${appendix}`;
}

export function getFilterDate(dateStr, isTime = false){
  const date = new Date(dateStr);
  const day = addZero(date.getDate());
  const month = addZero(date.getMonth()+1);
  const year = date.getFullYear();
  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());
  return `${year}-${month}-${day}`+ (isTime ? `T${hours}:${minutes}` : '');
}

export function getFormattedTime(time){
  let arr = (time+'').split(':');
  return arr[0]+':'+arr[1];
}

// export function getAge(dateString) {
//   var today = new Date();
//   var birthDate = new Date(dateString);
//   var age = today.getFullYear() - birthDate.getFullYear();
//   var m = today.getMonth() - birthDate.getMonth();
//   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//       age--;
//   }
//   return age;
// }

export const departments = [
  {id:1, name:"Radiologiýa", isSpec:false},
  {id:2, name:"Laboratoriýa", isSpec:false},
  {id:3, name:"Neýrohirurgiýa", isSpec:true},
  {id:4, name:"Oftalmologiýa", isSpec:true},
  {id:5, name:"Terapewt", isSpec:true}
]

export function convertTZ(date, tzString){
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {timeZone:tzString}))
}

export function convertTMZ(date){
  return convertTZ(date)
  // return convertTZ(date, 'Asia/Ashgabat')
}


export const COLORS = ['#08a4a7', '#ffbe98',  '#f4141c',  '#6c6377', '#e25372', '#0091e4', '#7d7eec', '#73a3c1', '#d0c59b', '#416aa3', '#1c1c84', '#f8ad9d', '#11f3af', '#ffff00', '#4b0082', '#0091e4', '#c48b9b'];

export function getColor(index){
  if(index < COLORS.length){
    return COLORS[index];
  }
  return COLORS[index%COLORS.length];
}


export const analyseNotifyMethods = {
  0:'Ýok',
  10:"Sms habar",
  20:"E-poçta"
}

export const analyseIndicators = {
  10:'VIP-mi',
  20:"Aýratyn register",
  30:"Allergiýaly"
}

export function listToMap(list){
    let map = {}, node, output = [], i
    if(list && list?.length){
        for(i = 0; i<list.length; i++){
            map[list[i].id] = i
            list[i].children = []
        }
        for(i=0; i<list.length; i++){
            node = list[i]
            if(node?.parent != null){
                if(list[map[node?.parent]]){
                  list[map[node.parent]].children.push(node)
                }
            }else{
                output.push(node)
            }
        }
    }
    return output
}

export const modalitiesObj = {
  10:'Rentgen', 
  20:'KT', 
  30:'MRT', 
  40:'Ultrases'
}

export const modalities = [
  {id:'10', name:'Rentgen'}, 
  {id:'20', name:'KT'}, 
  {id:'30', name:'MRT'}, 
  {id:'40', name:'Ultrases'}
]

export const colors = [
  {id:'red', name:'Gyzyl', img:''},
  {id:'yellow', name:'Sary', img:''},
  {id:'green', name:'Ýaşyl', img:''},
  {id:'blue', name:'Gök', img:''},
  {id:'lightblue', name:'Mawy', img:''},
]

export const accentColors = [
  {color:'teal', name:'Ýaşyl', id:'success'},
  {color:'skyblue', name:'Gök', id:'primary'},
  {color:'orange', name:'Sary', id:'warning'},
  {color:'red', name:'Gyzyl', id:'danger'}
]

export const weekDays = ['Ýekşenbe',  'Duşenbe', 'Sişenbe', 'Çarşenbe', 'Penşenbe', 'Anna', 'Şenbe']
export const shortWeekDays = ['Ýş',  'Du', 'Si', 'Çş', 'Pş', 'An', 'Şn']

export const treatmentTypes = [
  {id:10, name:'Ýatymlaýyn bejergi'},
  {id:20, name:'Öýünde bejergi'},
  {id:30, name:'Gatnawly bejergi'},
  {id:40, name:'Başga hassahaan geçir'}
]

export const hospitals = [
  {id:10, name:'Fiz'},
  {id:20, name:'Rehab'}
]


export const USER_TYPE_DOCTORS = 10
export const USER_TYPE_MIDDLE = 20
export const USER_TYPE_LITTLE = 30
export const USER_TYPE_PHARMACY = 40
export const USER_TYPE_OTHERS = 50

export const positionTypes = {
  [USER_TYPE_DOCTORS]:'Lukmançylyk wezipeleri',
  [USER_TYPE_MIDDLE]:'Orta SG wezipeleri',
  [USER_TYPE_LITTLE]:'Kiçi SG wezipeleri',
  [USER_TYPE_PHARMACY]:'Prowizorlar we farmasewtler',
  [USER_TYPE_PHARMACY]:'Beýleki wezipeler'
}

export const userTypes = {
  [USER_TYPE_DOCTORS]:'Lukmanlar',
  [USER_TYPE_MIDDLE]:'Orta SG işgärler',
  [USER_TYPE_LITTLE]:'Kiçi SG işgärler',
  [USER_TYPE_PHARMACY]:'Prowizorlar we farmasewtler',
  [USER_TYPE_OTHERS]:'Beýleki işgärler'
}

export const animalTypes = {
  10:'Alaka',
  20:'Syçan',
  30:'Towşan',
  40:'Belka',
  50:'Pişik',
  60:'It'
}

export const animalColors = {
  1:'white',
  2:'black',
  3:'gray',
  4:'brown',
  5:'yellow'
}

export const arrayIncluding = (arr, target) => target.every(v => arr.includes(v)) 

export function getMonthArr(start_date){
  const day = 24*60*60*1000
  const month = new Date(start_date).getMonth()
  const year = new Date(start_date).getFullYear()
  let start = new Date(`${year}/${addZero(month+1)}/01`).getTime()
  let end_date = ''
  if(month != 11){
      end_date = `${year}/${addZero(month+2)}/01`
  }else{
      end_date = `${year+1}/01/01/`
  }
  let end = new Date(end_date).getTime()
  let startWeekDay = new Date(start).getDay()
  start -= startWeekDay*day
  let arr = []
  while(start<end){
      let i = 0
      let weekStart = start
      let _arr = []
      while (i<7) {
          _arr.push(getFilterDate(weekStart))
          weekStart+=day
          i++
      }
      arr.push(_arr)
      start=weekStart
  }
  return arr
}

export function isToday(date){
  const today = new Date()
  date = new Date(date)
  return checkDateEquality(date, today)
}

export function checkDateEquality(date, date1){
  return date.getFullYear() == date1.getFullYear() && date.getMonth() == date1.getMonth() && date.getUTCDate() == date1.getUTCDate()
}

export const DISCOUNT_PERCENTAGE = 0
export const DISCOUNT_MONEY = 10

export const discountTypes = {
  [DISCOUNT_PERCENTAGE]:"Göterim",
  [DISCOUNT_MONEY]:"Pul möçberi"
}

export function focusNextElement(){
  const focusabelElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'
  if(document.activeElement && document.activeElement.form){
    let focussable = Array.prototype.filter.call(
      document.activeElement.form.querySelectorAll(focusabelElements),
      function(element){
        return(
          element.offsetWidth > 0 || element.offsetHeight || element === document.activeElement
        )
      }
    )
    let index = focussable.indexOf(document.activeElement)
    if(index > -1){
      let nextElement = focussable[index + 1] || focussable[0]
      nextElement.focus()
    }
  }
}


export function formatFileSize(sizeInBytes){
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (sizeInBytes === 0) return '0 Bytes';
  const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  return parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
};

export const regionTypes = {
  0:'Welaýat',
  10:'Şäher',
};

export const districtTypes = {
  0:'Etrap',
  10:'Şäher',
};

export const villageTypes = {
  0:'Etrapdaky şäher',
  10:'Şäherçe',
  20:'Geňeşlik'
};