import InputWrapper from "../input-wrapper/input-wrapper"

export default function InputGroupBlock({
    id, label, type='text', value, setValue, placeholder="...", icon=null, 
    error, inputProps=null, onIconClick=()=>{}, onEnter=()=>{},
    addon='', isRequired = false, isEnd=false
}){
    return (
        <InputWrapper id={id} label={label} key={'input-wrapper-'+id} error={error} isRequired={isRequired}>
            <div class="input-group">
                {
                    !isEnd ? 
                    <span class="input-group-text">{addon}</span>
                    : null
                }
                <input type={type} name={id} id={id} className="form-control number-input bg-transparent mb-0" placeholder={placeholder}
                    value={value} onChange={(e)=>setValue(e.target.value)} {...inputProps} onKeyDown={(e)=>{
                        if(e.code === 'Enter'){
                            onEnter()
                        }
                    }}
                />
                {
                    isEnd ? 
                    <span class="input-group-text">{addon}</span>
                    : null
                }
            </div>
            <i className={`${icon} position-absolute top-50 end-0 translate-middle-y me-2`} onClick={onIconClick}/>
        </InputWrapper>
    )
}