export default function Dropdown({buttonOptions = {}, btnClass='', dropdownOptions = {}, dropdownClass='', id=`dropdown-${Math.random()*10}`, title, isButton = true, ...props}){
    return (
        <div class="dropdown">
        {
            isButton ?
            <button class={`btn btn-secondary dropdown-toggle ${btnClass}`} type="button" id={id} data-bs-toggle="dropdown" aria-expanded="false" {...buttonOptions}>
                {title}
            </button>
            :
            title
        }
            <ul class={`dropdown-menu dropdown-menu-end ${dropdownClass}`} aria-labelledby={id} {...dropdownOptions}>
            {props.children}
            </ul>
        </div>
    )
}