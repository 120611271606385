import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { formatFileSize, getFormattedDate } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import { handleErrors } from "../../../../utils/error-handler";
import { AccentButton } from "../../../../components/button/button";
import 'react-json-pretty/themes/monikai.css';
import { LangContext } from "../../../../context/LangContext";
import InputBlock from "../../../../components/input-block/input-block";
import { appendNotification } from "../../../../components/notification";
import useDownload from "../../../../hooks/useDownload";
import Configurations from "../../../../config/configurations";
import LoadingIcon from "../../../../components/loading-icon/loading-icon";
import { useNavigate } from "react-router-dom";

export default function AdminDumps(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:50});
    const [isLoading, setIsLoading] = useState(false);
    const {isReady, logout} = useContext(AuthContext);
    const {langDictionary} = useContext(LangContext)
    const navigate = useNavigate()
    const pageKey="admin-dumps-page";
    const pageTitle="Dumplar";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter]) 
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getDumps(filter).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    function handleRemove(id) {
        if(window.confirm("Siz hakykatdan hem bu dumpy ýok etmekçimi?")){
            AxiosHelper.deleteDump(id).then((result)=>{
                if(result.status == 200){
                    appendNotification("Dump üstünlikli ýok edildi!")
                    refresh()
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="ID" filter={filter} setFilter={setFilter} targetAttribute='id'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?.id || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Faýl" filter={filter} setFilter={setFilter} targetAttribute='filename'/>, content:(item)=>{
                return (item?.filename)
            }
        },
        {
            text:<Sort text="Faýl ölçegi" filter={filter} setFilter={setFilter} targetAttribute='filesize'/>, content:(item)=>{
                return (formatFileSize(item?.filesize))
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '/', true)}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <AccentButton theme="danger" className="me-2" onClick={()=>handleRemove(item?.id)}>
                            <i className="bi bi-trash2"/>
                        </AccentButton>
                        <DownloadButton item={item}/>
                    </div>
                )
            }
        }
    ];
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle} 
            pageTitleEndComponent={
                <CreateBlock refresh={refresh}/>
            }
            startBlock={<FilterBlock filter={filter} setFilter={setFilter}/>}/>
    )
}

function FilterBlock({filter, setFilter}) {
    const closeRef = useRef()
    const modalId = 'filter-modal'
    const [shadowFilter, setShadowFilter] = useState({})
    return(
        <ModalWithButton closeRef={closeRef} modalId={modalId} title={'Filter'} 
            buttonProps={{className:'btn btn-sm btn-light border fw-500 text-nowrap px-3'}} 
            buttonContent={<><i className="bi bi-sliders me-2"/>Filter</>}
            isSaveEnable={true} saveFunction={()=>setFilter({...filter, ...shadowFilter})}>
            <div className="row">
                <div className="col-md-6">
                    <InputBlock error={null} id={'filter-start_date'} label={'Başlangyç sene'} type="date"
                        value={shadowFilter?.start_date} setValue={(start_date)=>setShadowFilter({...shadowFilter, start_date})}/>
                </div>
                <div className="col-md-6">
                    <InputBlock error={null} id={'filter-end-date'} label={'Ahyrky sene'} type="date"
                        value={shadowFilter?.end_date} setValue={(end_date)=>setShadowFilter({...shadowFilter, end_date})}/>
                </div>
            </div>
        </ModalWithButton>
    )
}

function CreateBlock({refresh = ()=>{}}) {
    const modalId = 'dump-create-modal'
    const closeRef = useRef()
    const {logout} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    function handleCreate() {
        if(String(password).trim()){
            setError('')
            AxiosHelper.createDump({password}).then((result)=>{
                if(result.status == 200){
                    appendNotification("Dump created successfully", 'success')
                    refresh()
                }
            }).catch((e)=>handleErrors(e, logout))
        }else{
            setError("Açar sözüni giriziň")
        }
    }
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    return(
        <ModalWithButton closeRef={closeRef} modalId={modalId} title={"Dump faýlyny döretmek"} 
            modalSize="modal-md" isCloseButton={false} isBackdropStatic={false}
            onButtonClick={()=>{setPassword(''); setError('')}}
            buttonProps={{className:'btn btn-sm btn-accent-primary fw-500 px-3'}}>
                <InputBlock error={error} id={'dump-create-password-input'} label={langDictionary?.password}
                    value={password} setValue={(e)=>setPassword(e)} type="password"/>
                <div className="d-flex justify-content-center text-center">
                    <AccentButton theme="secondary" className="me-2" data-bs-dismiss="modal">
                        <i className="bi bi-x-lg me-2"/>{langDictionary?.close}
                    </AccentButton>
                    <AccentButton onClick={handleCreate}>
                        <i className="bi bi-check2-square me-2"/>{langDictionary?.save}
                    </AccentButton>
                </div>
        </ModalWithButton>
    )
}

function DownloadButton({item}) {
    const { downloading, progress, downloadFile } = useDownload();
    const handleDownload = () => {
        const fileUrl = [Configurations.getStaticServer(), `dumps/${item?.filename}`].join('');
        const filename = item?.filename;
        downloadFile(fileUrl, filename);
    };
    return(
        <>
            <AccentButton theme="primary ms-2" 
                onClick={()=>handleDownload(item)} 
                disabled={downloading}>
                {
                    downloading ?
                    <LoadingIcon isAnimate={downloading}/>
                    :
                    <i className="bi bi-download"/>
                }
            </AccentButton>
        </>
    )
}