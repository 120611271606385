import { useContext, useState } from "react"
import { LangContext } from "../../../../../context/LangContext"
import Button, { AccentButton } from "../../../../../components/button/button"
import SemiDonutChart from "../../../../../components/semi-donut-chart"
import { COLORS } from "../../../../../utils/main"
import DonutChart from "../../../../../components/donut-chart"
import Chart from "react-apexcharts";

export default function ReportVisitsBlock(){
    const {langDictionary} = useContext(LangContext)
    return(
        <div className="p-2 rounded-3 box-shadow bg-white">
            <div className="d-flex align-items-center">
                <span className="text-slatgray fw-500">
                    {langDictionary?.visits}
                </span>
                <div className="ms-auto">
                    <AccentButton theme="primary" className="me-2">
                        <i className="bi bi-file-earmark-medical"/>
                    </AccentButton>
                    <Button theme="light">
                        <i className="bi bi-arrow-repeat"/>
                    </Button>
                </div>
            </div>
            <hr className="my-2" />
            <div className="row">
                <div className="col-md-8 border-end">
                    <ByDiagnose/>
                </div>
                <div className="col-md-4">
                    <ByStatus/>
                    <ByType/>
                </div>
            </div>
        </div>
    )
}

function ByStatus() {
    return(
        <div className="p-1 pb-0 text-medium ">
            <div className="d-flex align-items-center">
                <div className="col-6 pe-1">
                    <span className="text-slatgray text-end fw-500">
                        <i className="bi bi-square-fill text-warning me-1"/>
                        Ýapyk - 65%
                    </span>
                </div>
                <div className="col-6 ps-1 text-end">
                    <span className="text-slatgray text-end fw-500">
                        <i className="bi bi-square-fill text-primary me-1"/>
                        Açyk - 35%
                    </span>
                </div>
            </div>
            <div className="overflow-hidden" style={{maxHeight:75+'px'}}>
                <SemiDonutChart 
                    labels={['Ýapyk', 'Açyk']}
                    series={[65, 35]}
                    height={150}
                    labelShow={false}
                    toolbarShow={false}
                    colors={['#FF824A', '#0D6EFD']}
                    />
            </div>
        </div>
    )
}

function ByType() {
    const data = [
        {x:'Bejergi almak', y:1250},
        {x:'Barlag geçmek', y:1360},
        {x:'Maslahat almak', y:750},
        {x:'Bionusga', y:150},
        {x:'Tiz kömek', y:980}
    ]

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'radar',
            toolbar: {
                show: false
            }
        },
        title: {
            text: undefined,
        },
        xaxis: {
            categories: data.map((_)=>_.x),
            labels: {
                show: true,
                style: {
                    colors: ["#a8a8a8"],
                    fontSize: "11px",
                    fontFamily: 'Arial'
                }
            }
        },
        stroke: {
            width: 1,
        },
        markers: {
            size: 3,
        },
        colors:COLORS,
        plotOptions: {
            radar: {
            polygons: {
                strokeColor: '#e8e8e8',
                fill: {
                    colors: ['#f8f8f8', '#fff']
                }
            }
            }
        }
    });

    const [chartSeries, setChartSeries] = useState([
        {
            name: 'Ýüztutma görnüşleri',
            data: data.map((_)=>_.y),
        }
    ]);
    return(
        <div className="w-100">
            {/* <san className="text-slatgray fw-500">
                Ýüztutma görnüşleri
            </san> */}
            <div className="overflow-hidden" style={{maxHeight:200+'px', marginTop:-35+'px'}}>
                <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="radar"
                    width="100%"
                    height="270"/>
            </div>
        </div>
    )
}

function ByDiagnose(){
    const data = [
        {x:'Grip', y:12321},
        {x:'Allergiýa', y:8652},
        {x:'Gepatit B', y:7500},
        {x:'Gipowitaminoz', y:7450},
        {x:'Flýurokok', y:6325},
        {x:'Gelmintler', y:4700},
        {x:'Salmonellýoz', y:4500},
        // {x:'Garahassalyk', y:12321}
    ] 
    return(
        <div className="d-flex align-items-top">
            <div className="col-4">
                <div className="mb-2 d-flex align-items-center">
                    <span className="p-1 px-2 rounded-2 bg-light text-slatgray fs-4 border">
                        <i className="bi bi-file-earmark-text me-2"/>
                        <span className="text-slatgray fw-500"> 
                            512 475
                        </span>
                    </span>
                </div>
                <div className="border rounded-2 overflow-hidden">
                    <table className="table table-sm table-striped p-0 m-0">
                        <tbody>
                            {
                                data.map((item, index)=>{
                                    return(
                                        <tr>
                                            <td>
                                                <i className="bi bi-square-fill me-1" style={{color:COLORS[index]}}/>
                                                {item.x}
                                            </td>
                                            <td>
                                                {item.y}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-8">
                <DonutChart 
                    labels={data.map((_)=>_.x)}
                    series={data.map((_)=>_.y)}
                    height={283}
                    labelShow={false}
                    toolbarShow={false}
                    />
            </div>
        </div>
    )
}