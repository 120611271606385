import { Link } from "react-router-dom";
import Configurations from "../../../../config/configurations";
import { toggleSidebar } from "../../../../utils/main";
import Button from "../../../../components/button/button";
import NavigateButtons from "./navigate-buttons";

export default function AdminLogo({isSidebar}){
    return (
        <div className="d-flex align-items-center">
            <Button theme="light" onClick={toggleSidebar} title="Alt+B" className={`me-2 rounded-circle ${!isSidebar && 'd-none'}`}>
                <i className="bi bi-list fs-6"/>
            </Button>
            <Link to="/" className={`admin-logo`} style={{marginRight:(!isSidebar ? 42+'px' : 0)}}>
                <img src="/img/logo.png" alt="ADMSES logo"/>
                <span>{Configurations.APPNAME}</span>
            </Link>
            <NavigateButtons/>
        </div>
    )
}