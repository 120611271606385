export default function FormStep({id, title, content, isActive=false, 
        isTitleIcon = true, titleIcon = 'bi bi-bookmark-plus', wrapIndicator=true, 
        isHeaderTouchable=true, headerEndContent=null,
        contentClassName = ''
    }){
    return (
        <div className={`step mb-3 ${isActive ? 'active' : ''}`} id={id}>
            
            <div className={`content ${contentClassName}`}>
                <div className={`step-header mb-2 ${isHeaderTouchable ? 'cursor-pointer' : ''}`} onClick={()=>{
                    if(isHeaderTouchable) toggle(id)
                }}>
                {
                    isTitleIcon &&
                    <div className="icon-container text-primary me-2">
                        <i className={titleIcon}/>
                    </div>
                }
                    {title}
                    <div className="d-flex align-items-center ms-auto">
                        {
                            headerEndContent
                        }
                        {
                            wrapIndicator ?
                            <button className="btn btn-sm ms-2" onClick={()=>toggle(id)}>
                                <i className="bi bi-chevron-down toggler-btn"/>
                            </button>
                            : null
                        }
                    </div>
                </div>
                <div className="px-2 content-container">
                    {content}
                </div>
            </div>
        </div>
    )
}

function toggle(id){
    let dom = document.getElementById(id);
    dom.classList.toggle('active');
}