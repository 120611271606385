import { Link } from "react-router-dom"
import { getFullname } from "../../../../utils/main"
import { useContext, useEffect, useState } from "react"
import { LangContext } from "../../../../context/LangContext"
import Button, { AccentButton } from "../../../../components/button/button"
import AxiosHelper from "../../../../utils/API/AxiosHelper"
import { appendNotification } from "../../../../components/notification"
import { handleErrors } from "../../../../utils/error-handler"
import { AuthContext } from "../../../../context/AuthContext"

export default function PatientParents({model={}, setModel = ()=>{}, isRaw = false}){
    const {isReady, logout} = useContext(AuthContext)
    const [options, setOptions] = useState({relation_types:[]})
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getRelationType('filter').then((result)=>{
                if(result.status == 200){
                    setOptions({...options, relation_types:result.data?.data})
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }, [isReady])
    function refresh() {
        if(isReady){
            AxiosHelper.getPatientParents({patient_id:model?.id}).then((result)=>{
                if(result.status == 200){
                    let parents = []
                    let re_parents = []
                    Array.from(result.data?.data).map((parent)=>{
                        if(parent?.patient_id == model?.id){
                            parents.push(parent)
                        }else{
                            re_parents.push(parent)
                        }
                    })
                    setModel({...model, parents, re_parents})
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    return(
        <Wrapper isRaw={isRaw} refresh={refresh}>
            <div className="text-medium fw-normal">
                {
                    model?.parents && model?.parents?.length ?
                    <div className="d-grid">
                        <ul className="list-unstyled m-0 p-0">
                            {
                                model?.parents.map((parent)=>{
                                    return(
                                        <Item id={parent?.id} patientId={parent?.parent_id} name={getFullname(parent?._parent)} type={parent?._type?.name} refresh={refresh}/>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    :
                    null
                }
                {
                    model?.re_parents && model?.re_parents?.length ?
                    <div className="d-grid">
                        <ul className="list-unstyled m-0 p-0">
                            {
                                model?.re_parents.map((parent)=>{
                                    return(
                                        <Item id={parent?.id} patientId={parent?.patient_id} name={getFullname(parent?._patient)} type={parent?._type?.alternate_name} refresh={refresh}/>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    :
                    null
                }
            </div>
        </Wrapper>
    )
}

function Wrapper({isRaw=false, refresh=()=>{}, ...params}) {
    const {langDictionary} = useContext(LangContext)
    if(isRaw){
        return(
            <div className="container">
                <div className="rounded-2 border p-2 box-shadow">
                    {params.children}
                </div>
            </div>
        )
    }
    return(
        <div className="p-2 rounded-3 mb-3 box-shadow border">
            <div className="d-grid text-secondary">
                <div className="d-flex align-items-center">
                    <span className="text-dark fw-500">
                        {langDictionary?.parents}
                    </span>
                    <Button theme="light" className="ms-auto" onClick={refresh}>
                        <i className="bi bi-arrow-repeat"/>
                    </Button>
                </div>
                <hr className="my-2" />
                {params?.children}
            </div>
        </div>
    )
}


function Item({id, type='', name='', patientId, refresh = ()=>{}}) {
    const {isReady, logout} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    function deleteRelation(){
        if(isReady && window.confirm('Siz hakykatdan hem bu garyndaşlygy ýok etmekçimi?')){
            AxiosHelper.deletePatientParent(id).then((result)=>{
                if(result.status == 200){
                    appendNotification(langDictionary?.successfully_deleted)
                    refresh()
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    return(
        <div className="d-flex align-items-center justify-content-between fst-italic w-100">
            <span>
                {type}
            </span>
            <span>
                <Link to={'/patients/'+patientId} className="text-decoration-none fw-500">
                    <i className="bi bi-link-45deg me-1"/>
                    {name}
                </Link>
                <AccentButton theme="danger" className="ms-2" buttonSize="little" onClick={deleteRelation}>
                    <i className="bi bi-trash2"/>
                </AccentButton>
            </span>
        </div>
    )
}