//#region Imports
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Notifications from "./components/notification";
import { AuthContext } from "./context/AuthContext";
import { useAuth } from "./hooks/useAuth";
import AdminDashboard from "./pages/admin/dashboard/dashboard";
import Visits from "./pages/admin/visits/visits";
import AdminSettings from "./pages/admin/settings/settings";
import Login from "./pages/auth/login";
import AdminReports from "./pages/admin/reports/index/reports";
import ConfirmLogin from "./pages/auth/confirm";
import AdminRoles from "./pages/admin/settings/roles/roles";
import AdminRegions from "./pages/admin/settings/regions/regions";
import AdminVillages from "./pages/admin/settings/villages/villages";
import AdminDiagnoses from "./pages/admin/settings/diagnoses/diagnoses";
import AdminUsers from "./pages/admin/settings/users/users";
import AdminUsersCreate from "./pages/admin/settings/users/create";
import AdminUsersUpdate from "./pages/admin/settings/users/update";
import AdminPatients from "./pages/admin/patients/index";
import AdminPassportSeries from "./pages/admin/settings/passport-series";
import AdminCountries from "./pages/admin/settings/countries";
import { StrictMode, useEffect, useState } from "react";
import { useLang } from "./langs";
import { LangContext } from "./context/LangContext";
import AdminDepartments from "./pages/admin/settings/departments/departments";
import AdminDocumentTypes from "./pages/admin/settings/document-types/document-types";
import Unreached from "./pages/admin/uncreated";
import AdminBenefits from "./pages/admin/settings/benefits/benefits";
import AdminRegistrationTypes from "./pages/admin/settings/registration-type/registration-types";
import { LoadContext } from "./context/LoadContext";
import AdminPositions from "./pages/admin/settings/positions/positions";
import { socket } from './socket';
import AdminRolesCreate from "./pages/admin/settings/roles/create";
import AdminRolesUpdate from "./pages/admin/settings/roles/update";
import AdminBadHabits from "./pages/admin/settings/bad-habits";
import AdminLikeIlls from "./pages/admin/settings/like-ills";
import AdminLogs from "./pages/admin/settings/logs/logs";
import { ConfigContext } from "./context/ConfigContext";
import { useLocalSave } from "./hooks/useLocalSave";
import { LocalSaveContext } from "./context/LocalSaveContext";
import AdminRelationTypes from "./pages/admin/settings/relation-types/relation-types";
import AdminDumps from "./pages/admin/settings/dumps/dumps";
import AdminDistricts from "./pages/admin/settings/districts/districts";
import AdminApartments from "./pages/admin/settings/apartments/apartments";
import AdminHomes from "./pages/admin/settings/homes/homes";
import AdminStreets from "./pages/admin/settings/streets/streets";
import AdminHospitals from "./pages/admin/settings/hospitals";
import AdminEducationGrades from "./pages/admin/settings/education-grades/education-grades";
import PatientView from "./pages/admin/patients/view/view";
import Analyses from "./pages/admin/analyses/analyses";
//#endregion

export default function App() {
  const {login, logout, token, user, setUser, isReady} = useAuth();
  const {lang, setLang, langs, langDictionary} = useLang(isReady);
  const {localRecords, setLocalRecords, addRecord, removeRecord, recordsId, clearRecords, fetchRecords} = useLocalSave()
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [config, setConfig] = useState({});
  const isLogin = !!token;
  
  useEffect(() => {
    function onConnect() {
      socket.emit("join", user)
      setIsConnected(true);
    }
    
    function onDisconnect() {
      setIsConnected(false)
    }
    
    function onOnlineUsers(params) {
      setOnlineUsers(params)
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('online-users', onOnlineUsers);
    
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('online-users', onOnlineUsers);
    };
  }, [user]);

  useEffect(()=>{
    if(isReady){
      if(user && user != null){
        socket.connect();
      }else{
        socket.disconnect();
      }
    }
  }, [isReady, socket, user])
  return (
    <StrictMode>
      <LoadContext.Provider value={{isLoading, setIsLoading}}>
        <AuthContext.Provider value={{login, logout, token, user, setUser, isReady, isLogin, isConnected, onlineUsers}}>
          <ConfigContext.Provider value={{config}}>
            <LangContext.Provider value={{lang, setLang, langs, langDictionary}}>
              <LocalSaveContext.Provider value={{localRecords, setLocalRecords, addRecord, removeRecord, recordsId, clearRecords, fetchRecords}}>
                <Notifications />
                <BrowserRouter basename="/">
                  <Routes>
                    <Route exact path="/" element={<AdminDashboard/>}/>

                    <Route exact path="/settings" element={<AdminSettings/>}/>
                    <Route exact path="/settings/sorted/:type" element={<AdminSettings/>}/>

                    <Route exact path="/settings/users" element={<AdminUsers/>}/>
                    <Route exact path="/settings/users/create" element={<AdminUsersCreate/>}/>
                    <Route exact path="/settings/users/update/:id" element={<AdminUsersUpdate/>}/>
                    <Route exact path="/settings/positions" element={<AdminPositions/>}/>
                    <Route exact path="/settings/departments" element={<AdminDepartments/>}/>

                    <Route exact path="/settings/roles" element={<AdminRoles/>}/>
                    <Route exact path="/settings/roles/create" element={<AdminRolesCreate/>}/>
                    <Route exact path="/settings/roles/:id" element={<AdminRolesUpdate/>}/>

                    <Route exact path="/settings/countries" element={<AdminCountries/>}/>
                    <Route exact path="/settings/regions" element={<AdminRegions/>}/>
                    <Route exact path="/settings/districts" element={<AdminDistricts/>}/>
                    <Route exact path="/settings/villages" element={<AdminVillages/>}/>
                    <Route exact path="/settings/streets" element={<AdminStreets/>}/>
                    <Route exact path="/settings/homes" element={<AdminHomes/>}/>
                    <Route exact path="/settings/apartments" element={<AdminApartments/>}/>
                    <Route exact path="/settings/hospitals" element={<AdminHospitals/>}/>
                    
                    <Route exact path="/settings/diagnoses" element={<AdminDiagnoses/>}/>
                    <Route exact path="/settings/benefits" element={<AdminBenefits/>}/>
                    <Route exact path="/settings/education-grades" element={<AdminEducationGrades/>}/>
                    <Route exact path="/settings/document-serial-keys" element={<AdminPassportSeries/>}/>
                    <Route exact path="/settings/document-types" element={<AdminDocumentTypes/>}/>
                    <Route exact path="/settings/registration-types" element={<AdminRegistrationTypes/>}/>
                    <Route exact path="/settings/bad-habits" element={<AdminBadHabits/>}/>
                    <Route exact path="/settings/like-ills" element={<AdminLikeIlls/>}/> 
                    <Route exact path="/settings/relation-types" element={<AdminRelationTypes/>}/>
                    <Route exact path="/settings/logs" element={<AdminLogs/>}/>
                    <Route exact path="/settings/dumps" element={<AdminDumps/>}/>
                    
                    <Route exact path="/patients" element={<AdminPatients/>}/>
                    <Route exact path="/patients/:id" element={<PatientView/>}/>
                    <Route exact path="/patients/update" element={<AdminPatients/>}/>
                    <Route exact path="/visits" element={<Visits/>}/>
                    <Route exact path="/analyses" element={<Analyses/>}/>
                    <Route exact path="/reports" element={<AdminReports/>}/>
                    
                    <Route exact path="/login" element={<Login/>}/>
                    <Route exact path="/confirm" element={<ConfirmLogin/>}/>
                    <Route path="*" element={<Unreached/>}/>
                  </Routes>
                </BrowserRouter>
              </LocalSaveContext.Provider>
            </LangContext.Provider>
          </ConfigContext.Provider>
        </AuthContext.Provider>
      </LoadContext.Provider>
    </StrictMode>
  );
}