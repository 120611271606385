import NoResult from "../noResult/noResult"

export default function AdminTable({items, data, onDoubleClick=()=>{}, onClick=()=>{}, isRefreshable=false, refreshFunction=()=>{}}){
    return (
        <div className="rounded-3 overflow-hidden mb-2">
            <table class="table table-hover text-small mb-0">
                <thead className="bg-accent-primary text-primary">
                {
                    items ?
                    <tr>
                        {
                            Array.from(items).map((item)=>{
                                if(item?.condition === undefined || item?.condition){
                                    return (
                                        <th scope="col">{item?.text}</th>
                                    )
                                }
                            })
                        }
                    </tr>
                    : 
                    <tr></tr>
                }
                </thead>
                {
                    <tbody>
                        {
                            Array.from(data).map((model, index)=>{
                                return (
                                    <tr className="align-items-center" onDoubleClick={()=>onDoubleClick(model)} onClick={()=>onClick(model)}>
                                        {
                                            items?.map((item)=>{
                                                if(item?.condition === undefined || item?.condition){
                                                    return (
                                                        <td className="p-1">{item.content(model, index)}</td>
                                                    )
                                                }
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                }

            </table>
        </div>
    )
}