import { useEffect, useState } from "react";
import { DOTS, usePagination } from "../../hooks/usePagination";

export default function Pagination({filter, setFilter, count}){
    const [max, setMax] = useState(0);
    useEffect(()=>{
        setMax(Math.ceil(count/filter?.limit));
    }, [count, filter?.limit])
    useEffect(()=>{
        if(max > 0 && filter.page > max){
            setFilter({...filter, page:1})
        }
    }, [max])
    const paginationRange = usePagination({
        currentPage:(filter.page || 1),
        pageSize:(filter?.limit || 20),
        totalCount:(count || 0),
        siblingCount:1
    })
    const onNext = ()=>{
        if(filter?.page < max){
            setFilter({...filter, page:filter.page+1})
        }
    }
    const onPrevious = ()=>{
        if(filter.page > 1){
            setFilter({...filter, page:filter.page-1})
        }
    }
    return (
        <div className="d-flex align-items-center">
            <ul class="pagination m-0">
                <li class={`page-item ${filter.page === 1 && 'disabled'}`} onClick={onPrevious}>
                    <span class="page-link" aria-label="Previous">
                        <span aria-hidden="true">
                            <i className="bi bi-chevron-left"/>
                        </span>
                    </span>
                </li>
                {
                    paginationRange.map(pageNumber => {
                        if(pageNumber === DOTS){
                            return (
                                <li className="page-item">
                                &#8230;
                                </li>
                            )
                        } 
                        return(
                            <li class={"page-item "+(filter?.page == pageNumber ? 'active' : '')} onClick={()=>setFilter({...filter, page:pageNumber})}>
                                <span class="page-link">{pageNumber}</span>
                            </li>
                        )   
                    })
                }
                <li class={`page-item ${filter.page === max && 'disabled'}`} onClick={onNext}>
                    <span class="page-link" aria-label="Previous">
                        <span aria-hidden="true">
                            <i className="bi bi-chevron-right"/>
                        </span>
                    </span>
                </li>
            </ul>
        </div>
    )
}