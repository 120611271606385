import { useContext } from "react";
import Modal from "../modal/modal";
import { LangContext } from "../../context/LangContext";
import { AccentButton } from "../button/button";

export default function ReportsFilter({title="Hasabat filteri", clearFilter, closRef, modalId, isSaveable=false, saveFunction=()=>{}, btnTheme = 'warning', ...props}){
    const {langDictionary} = useContext(LangContext);
    return(
        <>
         <AccentButton theme={btnTheme} className="px-3 fw-500" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>
            <i className="bi bi-sliders me-2"/> {langDictionary?.filter}
         </AccentButton>
         <Modal id={modalId} isHeader={false} isFooter={false} modalSize="modal-lg">
            <div className="d-flex align-items-center justify-content-between fw-500">
                <span className="fs-5">{title}</span>
                <div className="d-flex align-items-center">
                    <AccentButton theme="secondary" className="me-2" data-bs-dismiss="modal" ref={closRef}>
                        <i className="bi bi-x me-2"/>
                        <span className="collapse-text">{langDictionary?.close}</span>
                    </AccentButton>
                    <AccentButton theme="warning" className="me-2" onClick={clearFilter}>
                        <i className="bi bi-arrow-repeat me-2"/>
                        <span className="collapse-text">{langDictionary?.clear}</span>
                    </AccentButton>
                    {
                        isSaveable ?
                        <AccentButton className="me-2" theme="primary" onClick={saveFunction}>
                            <i className="bi bi-check2 me-2"/>
                            <span className="collapse-text">{langDictionary?.save}</span>
                        </AccentButton>
                        :
                        null
                    }
                </div>
            </div>
            <hr className="my-2" />
            {props.children}
         </Modal>
        </>
    )
}