import { useContext } from "react";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import Permissions from "../../../../utils/permissions";
import { LangContext } from "../../../../context/LangContext";
import { arrayIncluding } from "../../../../utils/main";
import Button from "../../../../components/button/button";

export default function PermissionForm({form, setForm, errors, setErrors}){
    const {langDictionary} = useContext(LangContext)
    const arr = [
        {
            title:langDictionary?.users, id:'users', 
            all:[Permissions.USER_GET, Permissions.USER_POST, Permissions.USER_PUT, Permissions.USER_DELETE], 
            children:[
                {title:langDictionary?.view, value:Permissions.USER_GET},
                {title:langDictionary?.add_new, value:Permissions.USER_POST},
                {title:langDictionary?.update, value:Permissions.USER_PUT},
                {title:langDictionary?.delete, value:Permissions.USER_DELETE},
            ]
        },
        {
            title:langDictionary?.user_roles, id:'user-roles', 
            all:[Permissions.ROLE_GET, Permissions.ROLE_POST, Permissions.ROLE_PUT, Permissions.ROLE_DELETE],
            children:[
                {title:langDictionary?.view, value:Permissions.ROLE_GET},
                {title:langDictionary?.add_new, value:Permissions.ROLE_POST},
                {title:langDictionary?.update, value:Permissions.ROLE_PUT},
                {title:langDictionary?.delete, value:Permissions.ROLE_DELETE},
            ]
        },
        {
            title:langDictionary?.user_positions, id:'user-positions', 
            all:[
                Permissions.USER_POSITION_GET,
                Permissions.USER_POSITION_POST,
                Permissions.USER_POSITION_PUT,
                Permissions.USER_POSITION_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.USER_POSITION_GET},
                {title:langDictionary?.add_new, value:Permissions.USER_POSITION_POST},
                {title:langDictionary?.update, value:Permissions.USER_POSITION_PUT},
                {title:langDictionary?.delete, value:Permissions.USER_POSITION_DELETE},
            ]
        },
        {
            title:langDictionary?.regions, id:'user-regions', 
            all:[
                Permissions.REGION_GET,
                Permissions.REGION_POST,
                Permissions.REGION_PUT,
                Permissions.REGION_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.REGION_GET},
                {title:langDictionary?.add_new, value:Permissions.REGION_POST},
                {title:langDictionary?.update, value:Permissions.REGION_PUT},
                {title:langDictionary?.delete, value:Permissions.REGION_DELETE},
            ]
        },
        {
            title:langDictionary?.villages, id:'user-villages', 
            all:[
                Permissions.VILLAGE_GET,
                Permissions.VILLAGE_POST,
                Permissions.VILLAGE_PUT,
                Permissions.VILLAGE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.VILLAGE_GET},
                {title:langDictionary?.add_new, value:Permissions.VILLAGE_POST},
                {title:langDictionary?.update, value:Permissions.VILLAGE_PUT},
                {title:langDictionary?.delete, value:Permissions.VILLAGE_DELETE},
            ]
        },
        {
            title:langDictionary?.countries, id:'user-countries', 
            all:[
                Permissions.COUNTRY_GET,
                Permissions.COUNTRY_POST,
                Permissions.COUNTRY_PUT,
                Permissions.COUNTRY_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.COUNTRY_GET},
                {title:langDictionary?.add_new, value:Permissions.COUNTRY_POST},
                {title:langDictionary?.update, value:Permissions.COUNTRY_PUT},
                {title:langDictionary?.delete, value:Permissions.COUNTRY_DELETE}
            ]
        },
        {
            title:langDictionary?.document_type, id:'user-document_type', 
            all:[
                Permissions.DOC_TYPE_GET,
                Permissions.DOC_TYPE_POST,
                Permissions.DOC_TYPE_PUT,
                Permissions.DOC_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.DOC_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.DOC_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.DOC_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.DOC_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.serial_number, id:'user-serial_number', 
            all:[
                Permissions.DOC_KEY_GET,
                Permissions.DOC_KEY_POST,
                Permissions.DOC_KEY_PUT,
                Permissions.DOC_KEY_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.DOC_KEY_GET},
                {title:langDictionary?.add_new, value:Permissions.DOC_KEY_POST},
                {title:langDictionary?.update, value:Permissions.DOC_KEY_PUT},
                {title:langDictionary?.delete, value:Permissions.DOC_KEY_DELETE}
            ]
        },
        {
            title:langDictionary?.visit_type, id:'user-visit_type', 
            all:[
                Permissions.V_TYPE_GET,
                Permissions.V_TYPE_POST,
                Permissions.V_TYPE_PUT,
                Permissions.V_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.V_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.V_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.V_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.V_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.visit_method, id:'user-visit_method', 
            all:[
                Permissions.V_METHOD_GET,
                Permissions.V_METHOD_POST,
                Permissions.V_METHOD_PUT,
                Permissions.V_METHOD_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.V_METHOD_GET},
                {title:langDictionary?.add_new, value:Permissions.V_METHOD_POST},
                {title:langDictionary?.update, value:Permissions.V_METHOD_PUT},
                {title:langDictionary?.delete, value:Permissions.V_METHOD_DELETE}
            ]
        },
        {
            title:langDictionary?.count_with_same_ill, id:'user-count_with_same_ill', 
            all:[
                Permissions.V_COUNT_GET,
                Permissions.V_COUNT_POST,
                Permissions.V_COUNT_PUT,
                Permissions.V_COUNT_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.V_COUNT_GET},
                {title:langDictionary?.add_new, value:Permissions.V_COUNT_POST},
                {title:langDictionary?.update, value:Permissions.V_COUNT_PUT},
                {title:langDictionary?.delete, value:Permissions.V_COUNT_DELETE}
            ]
        },
        {
            title:langDictionary?.visit_result, id:'user-visit_result', 
            all:[
                Permissions.V_RESULT_GET,
                Permissions.V_RESULT_POST,
                Permissions.V_RESULT_PUT,
                Permissions.V_RESULT_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.V_RESULT_GET},
                {title:langDictionary?.add_new, value:Permissions.V_RESULT_POST},
                {title:langDictionary?.update, value:Permissions.V_RESULT_PUT},
                {title:langDictionary?.delete, value:Permissions.V_RESULT_DELETE}
            ]
        },
        {
            title:langDictionary?.visit_sections, id:'user-visit_sections', 
            all:[
                Permissions.V_SECTION_GET,
                Permissions.V_SECTION_POST,
                Permissions.V_SECTION_PUT,
                Permissions.V_SECTION_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.V_SECTION_GET},
                {title:langDictionary?.add_new, value:Permissions.V_SECTION_POST},
                {title:langDictionary?.update, value:Permissions.V_SECTION_PUT},
                {title:langDictionary?.delete, value:Permissions.V_SECTION_DELETE}
            ]
        },
        {
            title:langDictionary?.bad_habits, id:'user-bad_habits', 
            all:[
                Permissions.BAD_HABIT_GET,
                Permissions.BAD_HABIT_POST,
                Permissions.BAD_HABIT_PUT,
                Permissions.BAD_HABIT_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.BAD_HABIT_GET},
                {title:langDictionary?.add_new, value:Permissions.BAD_HABIT_POST},
                {title:langDictionary?.update, value:Permissions.BAD_HABIT_PUT},
                {title:langDictionary?.delete, value:Permissions.BAD_HABIT_DELETE}
            ]
        },
        {
            title:langDictionary?.relation_ills, id:'user-relation_ills', 
            all:[
                Permissions.LIKE_ILL_GET,
                Permissions.LIKE_ILL_POST,
                Permissions.LIKE_ILL_PUT,
                Permissions.LIKE_ILL_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.LIKE_ILL_GET},
                {title:langDictionary?.add_new, value:Permissions.LIKE_ILL_POST},
                {title:langDictionary?.update, value:Permissions.LIKE_ILL_PUT},
                {title:langDictionary?.delete, value:Permissions.LIKE_ILL_DELETE}
            ]
        },
        {
            title:langDictionary?.clynical_symptom_types, id:'user-clynical_symptom_types', 
            all:[
                Permissions.Cl_SYMPTOM_GET,
                Permissions.Cl_SYMPTOM_POST,
                Permissions.Cl_SYMPTOM_PUT,
                Permissions.Cl_SYMPTOM_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.Cl_SYMPTOM_GET},
                {title:langDictionary?.add_new, value:Permissions.Cl_SYMPTOM_POST},
                {title:langDictionary?.update, value:Permissions.Cl_SYMPTOM_PUT},
                {title:langDictionary?.delete, value:Permissions.Cl_SYMPTOM_DELETE}
            ]
        },
        {
            title:langDictionary?.clynical_symptom_options, id:'user-clynical_symptom_options', 
            all:[
                Permissions.Cl_OPTION_GET,
                Permissions.Cl_OPTION_POST,
                Permissions.Cl_OPTION_PUT,
                Permissions.Cl_OPTION_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.Cl_OPTION_GET},
                {title:langDictionary?.add_new, value:Permissions.Cl_OPTION_POST},
                {title:langDictionary?.update, value:Permissions.Cl_OPTION_PUT},
                {title:langDictionary?.delete, value:Permissions.Cl_OPTION_DELETE}
            ]
        },
        {
            title:langDictionary?.epidemiological_anamnez, id:'user-epidemiological_anamnez', 
            all:[
                Permissions.E_ANAMNEZ_GET,
                Permissions.E_ANAMNEZ_POST,
                Permissions.E_ANAMNEZ_PUT,
                Permissions.E_ANAMNEZ_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.E_ANAMNEZ_GET},
                {title:langDictionary?.add_new, value:Permissions.E_ANAMNEZ_POST},
                {title:langDictionary?.update, value:Permissions.E_ANAMNEZ_PUT},
                {title:langDictionary?.delete, value:Permissions.E_ANAMNEZ_DELETE}
            ]
        },
        {
            title:langDictionary?.diagnose, id:'user-diagnose', 
            all:[
                Permissions.DIAGNOSE_GET,
                Permissions.DIAGNOSE_POST,
                Permissions.DIAGNOSE_PUT,
                Permissions.DIAGNOSE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.DIAGNOSE_GET},
                {title:langDictionary?.add_new, value:Permissions.DIAGNOSE_POST},
                {title:langDictionary?.update, value:Permissions.DIAGNOSE_PUT},
                {title:langDictionary?.delete, value:Permissions.DIAGNOSE_DELETE}
            ]
        },
        {
            title:langDictionary?.department, id:'user-department', 
            all:[
                Permissions.DEPARTMENT_GET,
                Permissions.DEPARTMENT_POST,
                Permissions.DEPARTMENT_PUT,
                Permissions.DEPARTMENT_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.DEPARTMENT_GET},
                {title:langDictionary?.add_new, value:Permissions.DEPARTMENT_POST},
                {title:langDictionary?.update, value:Permissions.DEPARTMENT_PUT},
                {title:langDictionary?.delete, value:Permissions.DEPARTMENT_DELETE}
            ]
        },
        {
            title:langDictionary?.room, id:'user-room', 
            all:[
                Permissions.ROOM_GET,
                Permissions.ROOM_POST,
                Permissions.ROOM_PUT,
                Permissions.ROOM_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.ROOM_GET},
                {title:langDictionary?.add_new, value:Permissions.ROOM_POST},
                {title:langDictionary?.update, value:Permissions.ROOM_PUT},
                {title:langDictionary?.delete, value:Permissions.ROOM_DELETE}
            ]
        },
        {
            title:langDictionary?.bed, id:'user-bed', 
            all:[
                Permissions.BED_GET,
                Permissions.BED_POST,
                Permissions.BED_PUT,
                Permissions.BED_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.BED_GET},
                {title:langDictionary?.add_new, value:Permissions.BED_POST},
                {title:langDictionary?.update, value:Permissions.BED_PUT},
                {title:langDictionary?.delete, value:Permissions.BED_DELETE}
            ]
        },
        {
            title:langDictionary?.patients, id:'user-patients', 
            all:[
                Permissions.PATIENT_GET,
                Permissions.PATIENT_POST,
                Permissions.PATIENT_PUT,
                Permissions.PATIENT_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.PATIENT_GET},
                {title:langDictionary?.add_new, value:Permissions.PATIENT_POST},
                {title:langDictionary?.update, value:Permissions.PATIENT_PUT},
                {title:langDictionary?.delete, value:Permissions.PATIENT_DELETE}
            ]
        },
        {
            title:langDictionary?.visits, id:'user-visits', 
            all:[
                Permissions.VISIT_GET,
                Permissions.VISIT_POST,
                Permissions.VISIT_PUT,
                Permissions.VISIT_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.VISIT_GET},
                {title:langDictionary?.add_new, value:Permissions.VISIT_POST},
                {title:langDictionary?.update, value:Permissions.VISIT_PUT},
                {title:langDictionary?.delete, value:Permissions.VISIT_DELETE}
            ]
        },
        {
            title:langDictionary?.library, id:'user-library', 
            all:[
                Permissions.BOOK_GET,
                Permissions.BOOK_POST,
                Permissions.BOOK_PUT,
                Permissions.BOOK_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.BOOK_GET},
                {title:langDictionary?.add_new, value:Permissions.BOOK_POST},
                {title:langDictionary?.update, value:Permissions.BOOK_PUT},
                {title:langDictionary?.delete, value:Permissions.BOOK_DELETE}
            ]
        },
        { 
            title:langDictionary?.lab_analyse_types, id:'user-lab_analyse_types', 
            all:[
                Permissions.ANALYSE_TYPE_GET,
                Permissions.ANALYSE_TYPE_POST,
                Permissions.ANALYSE_TYPE_PUT,
                Permissions.ANALYSE_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.ANALYSE_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.ANALYSE_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.ANALYSE_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.ANALYSE_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.laboratory_analyses, id:'user-laboratory_analyses', 
            all:[
                Permissions.ANALYSE_GET,
                Permissions.ANALYSE_POST,
                Permissions.ANALYSE_PUT,
                Permissions.ANALYSE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.ANALYSE_GET},
                {title:langDictionary?.add_new, value:Permissions.ANALYSE_POST},
                {title:langDictionary?.update, value:Permissions.ANALYSE_PUT},
                {title:langDictionary?.delete, value:Permissions.ANALYSE_DELETE}
            ]
        },
        {
            title:langDictionary?.lab_analyse_attributes, id:'user-lab_analyse_attributes', 
            all:[
                Permissions.ANALYSE_ATTRIBUTE_GET,
                Permissions.ANALYSE_ATTRIBUTE_POST,
                Permissions.ANALYSE_ATTRIBUTE_PUT,
                Permissions.ANALYSE_ATTRIBUTE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.ANALYSE_ATTRIBUTE_GET},
                {title:langDictionary?.add_new, value:Permissions.ANALYSE_ATTRIBUTE_POST},
                {title:langDictionary?.update, value:Permissions.ANALYSE_ATTRIBUTE_PUT},
                {title:langDictionary?.delete, value:Permissions.ANALYSE_ATTRIBUTE_DELETE}
            ]
        },
        {
            title:langDictionary?.lab_probe_types, id:'user-lab_probe_types', 
            all:[
                Permissions.ANALYSE_PROBE_GET,
                Permissions.ANALYSE_PROBE_POST,
                Permissions.ANALYSE_PROBE_PUT,
                Permissions.ANALYSE_PROBE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.ANALYSE_PROBE_GET},
                {title:langDictionary?.add_new, value:Permissions.ANALYSE_PROBE_POST},
                {title:langDictionary?.update, value:Permissions.ANALYSE_PROBE_PUT},
                {title:langDictionary?.delete, value:Permissions.ANALYSE_PROBE_DELETE}
            ]
        },
        {
            title:langDictionary?.lab_probes, id:'user-lab_probes', 
            all:[
                Permissions.PROBE_GET,
                Permissions.PROBE_POST,
                Permissions.PROBE_PUT,
                Permissions.PROBE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.PROBE_GET},
                {title:langDictionary?.add_new, value:Permissions.PROBE_POST},
                {title:langDictionary?.update, value:Permissions.PROBE_PUT},
                {title:langDictionary?.delete, value:Permissions.PROBE_DELETE}
            ]
        },
        {
            title:langDictionary?.payments, id:'payments', 
            all:[
                Permissions.PAYMENT_GET,
                Permissions.PAYMENT_POST,
                Permissions.PAYMENT_PUT,
                Permissions.PAYMENT_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.PAYMENT_GET},
                {title:langDictionary?.add_new, value:Permissions.PAYMENT_POST},
                {title:langDictionary?.update, value:Permissions.PAYMENT_PUT},
                {title:langDictionary?.delete, value:Permissions.PAYMENT_DELETE}
            ]
        },
        {
            title:langDictionary?.discount_types, id:'discount_types', 
            all:[
                Permissions.DISCOUNT_TYPE_GET,
                Permissions.DISCOUNT_TYPE_POST,
                Permissions.DISCOUNT_TYPE_PUT,
                Permissions.DISCOUNT_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.DISCOUNT_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.DISCOUNT_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.DISCOUNT_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.DISCOUNT_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.benefit_category, id:'benefit_category', 
            all:[
                Permissions.BENEFIT_GET,
                Permissions.BENEFIT_POST,
                Permissions.BENEFIT_PUT,
                Permissions.BENEFIT_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.BENEFIT_GET},
                {title:langDictionary?.add_new, value:Permissions.BENEFIT_POST},
                {title:langDictionary?.update, value:Permissions.BENEFIT_PUT},
                {title:langDictionary?.delete, value:Permissions.BENEFIT_DELETE}
            ]
        },
        {
            title:langDictionary?.register_type, id:'register_type', 
            all:[
                Permissions.REG_TYPE_GET,
                Permissions.REG_TYPE_POST,
                Permissions.REG_TYPE_PUT,
                Permissions.REG_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.REG_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.REG_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.REG_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.REG_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.registration, id:'registration', 
            all:[
                Permissions.REG_GET,
                Permissions.REG_POST,
                Permissions.REG_PUT,
                Permissions.REG_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.REG_GET},
                {title:langDictionary?.add_new, value:Permissions.REG_POST},
                {title:langDictionary?.update, value:Permissions.REG_PUT},
                {title:langDictionary?.delete, value:Permissions.REG_DELETE}
            ]
        },
        {
            title:langDictionary?.operation_type, id:'operation_type', 
            all:[
                Permissions.OPER_TYPE_GET,
                Permissions.OPER_TYPE_POST,
                Permissions.OPER_TYPE_PUT,
                Permissions.OPER_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.OPER_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.OPER_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.OPER_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.OPER_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.operation, id:'operation', 
            all:[
                Permissions.OPER_GET,
                Permissions.OPER_POST,
                Permissions.OPER_PUT,
                Permissions.OPER_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.OPER_GET},
                {title:langDictionary?.add_new, value:Permissions.OPER_POST},
                {title:langDictionary?.update, value:Permissions.OPER_PUT},
                {title:langDictionary?.delete, value:Permissions.OPER_DELETE}
            ]
        },
        {
            title:langDictionary?.specializations, id:'specializations-permission', 
            all:[
                Permissions.SPEC_GET,
                Permissions.SPEC_POST,
                Permissions.SPEC_PUT,
                Permissions.SPEC_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SPEC_GET},
                {title:langDictionary?.add_new, value:Permissions.SPEC_POST},
                {title:langDictionary?.update, value:Permissions.SPEC_PUT},
                {title:langDictionary?.delete, value:Permissions.SPEC_DELETE}
            ]
        },
        {
            title:langDictionary?.rad_analyse_types, id:'rad_analyse_types', 
            all:[
                Permissions.RD_TYPE_GET,
                Permissions.RD_TYPE_POST,
                Permissions.RD_TYPE_PUT,
                Permissions.RD_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.RD_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.RD_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.RD_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.RD_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.radiological_analyses, id:'radiological_analyses', 
            all:[
                Permissions.RD_ANALYSE_GET,
                Permissions.RD_ANALYSE_POST,
                Permissions.RD_ANALYSE_PUT,
                Permissions.RD_ANALYSE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.RD_ANALYSE_GET},
                {title:langDictionary?.add_new, value:Permissions.RD_ANALYSE_POST},
                {title:langDictionary?.update, value:Permissions.RD_ANALYSE_PUT},
                {title:langDictionary?.delete, value:Permissions.RD_ANALYSE_DELETE}
            ]
        },
        {
            title:langDictionary?.rd_questions, id:'rd_questions', 
            all:[
                Permissions.RD_QUESTION_GET,
                Permissions.RD_QUESTION_POST,
                Permissions.RD_QUESTION_PUT,
                Permissions.RD_QUESTION_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.RD_QUESTION_GET},
                {title:langDictionary?.add_new, value:Permissions.RD_QUESTION_POST},
                {title:langDictionary?.update, value:Permissions.RD_QUESTION_PUT},
                {title:langDictionary?.delete, value:Permissions.RD_QUESTION_DELETE}
            ]
        },
        {
            title:langDictionary?.rd_answers, id:'rd_answers', 
            all:[
                Permissions.RD_ANSWER_GET,
                Permissions.RD_ANSWER_POST,
                Permissions.RD_ANSWER_PUT,
                Permissions.RD_ANSWER_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.RD_ANSWER_GET},
                {title:langDictionary?.add_new, value:Permissions.RD_ANSWER_POST},
                {title:langDictionary?.update, value:Permissions.RD_ANSWER_PUT},
                {title:langDictionary?.delete, value:Permissions.RD_ANSWER_DELETE}
            ]
        },
        {
            title:langDictionary?.sp_analyse_types, id:'sp_analyse_types', 
            all:[
                Permissions.SP_TYPE_GET,
                Permissions.SP_TYPE_POST,
                Permissions.SP_TYPE_PUT,
                Permissions.SP_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SP_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.SP_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.SP_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.SP_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.special_analyses, id:'special_analyses', 
            all:[
                Permissions.SP_ANALYSE_GET,
                Permissions.SP_ANALYSE_POST,
                Permissions.SP_ANALYSE_PUT,
                Permissions.SP_ANALYSE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SP_ANALYSE_GET},
                {title:langDictionary?.add_new, value:Permissions.SP_ANALYSE_POST},
                {title:langDictionary?.update, value:Permissions.SP_ANALYSE_PUT},
                {title:langDictionary?.delete, value:Permissions.SP_ANALYSE_DELETE}
            ]
        },
        {
            title:langDictionary?.sp_questions, id:'sp_questions', 
            all:[
                Permissions.SP_QUESTION_GET,
                Permissions.SP_QUESTION_POST,
                Permissions.SP_QUESTION_PUT,
                Permissions.SP_QUESTION_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SP_QUESTION_GET},
                {title:langDictionary?.add_new, value:Permissions.SP_QUESTION_POST},
                {title:langDictionary?.update, value:Permissions.SP_QUESTION_PUT},
                {title:langDictionary?.delete, value:Permissions.SP_QUESTION_DELETE}
            ]
        },
        {
            title:langDictionary?.sp_answers, id:'sp_answers', 
            all:[
                Permissions.SP_ANSWER_GET,
                Permissions.SP_ANSWER_POST,
                Permissions.SP_ANSWER_PUT,
                Permissions.SP_ANSWER_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SP_ANSWER_GET},
                {title:langDictionary?.add_new, value:Permissions.SP_ANSWER_POST},
                {title:langDictionary?.update, value:Permissions.SP_ANSWER_PUT},
                {title:langDictionary?.delete, value:Permissions.SP_ANSWER_DELETE}
            ]
        },
        {
            title:langDictionary?.instrumental_analyse_types, id:'instrumental_analyse_types', 
            all:[
                Permissions.INSTRUMENTAL_ANALYSE_TYPE_GET,
                Permissions.INSTRUMENTAL_ANALYSE_TYPE_POST,
                Permissions.INSTRUMENTAL_ANALYSE_TYPE_PUT,
                Permissions.INSTRUMENTAL_ANALYSE_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.INSTRUMENTAL_ANALYSE_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.INSTRUMENTAL_ANALYSE_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.INSTRUMENTAL_ANALYSE_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.INSTRUMENTAL_ANALYSE_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.instrumental_analyses, id:'instrumental_analyses', 
            all:[
                Permissions.INSTRUMENTAL_ANALYSE_GET,
                Permissions.INSTRUMENTAL_ANALYSE_POST,
                Permissions.INSTRUMENTAL_ANALYSE_PUT,
                Permissions.INSTRUMENTAL_ANALYSE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.INSTRUMENTAL_ANALYSE_GET},
                {title:langDictionary?.add_new, value:Permissions.INSTRUMENTAL_ANALYSE_POST},
                {title:langDictionary?.update, value:Permissions.INSTRUMENTAL_ANALYSE_PUT},
                {title:langDictionary?.delete, value:Permissions.INSTRUMENTAL_ANALYSE_DELETE}
            ]
        },
        {
            title:langDictionary?.instrumental_questions, id:'instrumental_questions', 
            all:[
                Permissions.INSTRUMENTAL_ANALYSE_QUESTION_GET,
                Permissions.INSTRUMENTAL_ANALYSE_QUESTION_POST,
                Permissions.INSTRUMENTAL_ANALYSE_QUESTION_PUT,
                Permissions.INSTRUMENTAL_ANALYSE_QUESTION_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.INSTRUMENTAL_ANALYSE_QUESTION_GET},
                {title:langDictionary?.add_new, value:Permissions.INSTRUMENTAL_ANALYSE_QUESTION_POST},
                {title:langDictionary?.update, value:Permissions.INSTRUMENTAL_ANALYSE_QUESTION_PUT},
                {title:langDictionary?.delete, value:Permissions.INSTRUMENTAL_ANALYSE_QUESTION_DELETE}
            ]
        },
        {
            title:langDictionary?.instrumental_answers, id:'instrumental_answers', 
            all:[
                Permissions.INSTRUMENTAL_ANALYSE_ANSWER_GET,
                Permissions.INSTRUMENTAL_ANALYSE_ANSWER_POST,
                Permissions.INSTRUMENTAL_ANALYSE_ANSWER_PUT,
                Permissions.INSTRUMENTAL_ANALYSE_ANSWER_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.INSTRUMENTAL_ANALYSE_ANSWER_GET},
                {title:langDictionary?.add_new, value:Permissions.INSTRUMENTAL_ANALYSE_ANSWER_POST},
                {title:langDictionary?.update, value:Permissions.INSTRUMENTAL_ANALYSE_ANSWER_PUT},
                {title:langDictionary?.delete, value:Permissions.INSTRUMENTAL_ANALYSE_ANSWER_DELETE}
            ]
        },
        {
            title:langDictionary?.tr_diary_params, id:'tr_diary_params', 
            all:[
                Permissions.TR_PARAM_GET,
                Permissions.TR_PARAM_POST,
                Permissions.TR_PARAM_PUT,
                Permissions.TR_PARAM_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.TR_PARAM_GET},
                {title:langDictionary?.add_new, value:Permissions.TR_PARAM_POST},
                {title:langDictionary?.update, value:Permissions.TR_PARAM_PUT},
                {title:langDictionary?.delete, value:Permissions.TR_PARAM_DELETE}
            ]
        },
        {
            title:langDictionary?.treatment_order, id:'treatment_order', 
            all:[
                Permissions.TR_ORDER_GET,
                Permissions.TR_ORDER_POST,
                Permissions.TR_ORDER_PUT,
                Permissions.TR_ORDER_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.TR_ORDER_GET},
                {title:langDictionary?.add_new, value:Permissions.TR_ORDER_POST},
                {title:langDictionary?.update, value:Permissions.TR_ORDER_PUT},
                {title:langDictionary?.delete, value:Permissions.TR_ORDER_DELETE}
            ]
        },
        {
            title:langDictionary?.prescription_item, id:'prescription_item', 
            all:[
                Permissions.PR_ITEM_GET,
                Permissions.PR_ITEM_POST,
                Permissions.PR_ITEM_PUT,
                Permissions.PR_ITEM_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.PR_ITEM_GET},
                {title:langDictionary?.add_new, value:Permissions.PR_ITEM_POST},
                {title:langDictionary?.update, value:Permissions.PR_ITEM_PUT},
                {title:langDictionary?.delete, value:Permissions.PR_ITEM_DELETE}
            ]
        },
        {
            title:langDictionary?.prescriptions, id:'prescriptions', 
            all:[
                Permissions.PR_GET,
                Permissions.PR_POST,
                Permissions.PR_PUT,
                Permissions.PR_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.PR_GET},
                {title:langDictionary?.add_new, value:Permissions.PR_POST},
                {title:langDictionary?.update, value:Permissions.PR_PUT},
                {title:langDictionary?.delete, value:Permissions.PR_DELETE}
            ]
        },
        {
            title:langDictionary?.arb_diary_params, id:'arb_diary_params', 
            all:[
                Permissions.ARB_PARAM_GET,
                Permissions.ARB_PARAM_POST,
                Permissions.ARB_PARAM_PUT,
                Permissions.ARB_PARAM_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.ARB_PARAM_GET},
                {title:langDictionary?.add_new, value:Permissions.ARB_PARAM_POST},
                {title:langDictionary?.update, value:Permissions.ARB_PARAM_PUT},
                {title:langDictionary?.delete, value:Permissions.ARB_PARAM_DELETE}
            ]
        },
        {
            title:langDictionary?.origin, id:'origin', 
            all:[
                Permissions.ORIGIN_GET,
                Permissions.ORIGIN_POST,
                Permissions.ORIGIN_PUT,
                Permissions.ORIGIN_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.ORIGIN_GET},
                {title:langDictionary?.add_new, value:Permissions.ORIGIN_POST},
                {title:langDictionary?.update, value:Permissions.ORIGIN_PUT},
                {title:langDictionary?.delete, value:Permissions.ORIGIN_DELETE}
            ]
        },
        {
            title:langDictionary?.edu_grades, id:'edu_grade', 
            all:[
                Permissions.EDU_GRADE_GET,
                Permissions.EDU_GRADE_POST,
                Permissions.EDU_GRADE_PUT,
                Permissions.EDU_GRADE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.EDU_GRADE_GET},
                {title:langDictionary?.add_new, value:Permissions.EDU_GRADE_POST},
                {title:langDictionary?.update, value:Permissions.EDU_GRADE_PUT},
                {title:langDictionary?.delete, value:Permissions.EDU_GRADE_DELETE}
            ]
        },
        {
            title:langDictionary?.edu_institutes, id:'edu_institute', 
            all:[
                Permissions.EDU_INS_GET,
                Permissions.EDU_INS_POST,
                Permissions.EDU_INS_PUT,
                Permissions.EDU_INS_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.EDU_INS_GET},
                {title:langDictionary?.add_new, value:Permissions.EDU_INS_POST},
                {title:langDictionary?.update, value:Permissions.EDU_INS_PUT},
                {title:langDictionary?.delete, value:Permissions.EDU_INS_DELETE}
            ]
        },
        {
            title:langDictionary?.sc_edu_types, id:'sc_edu_types', 
            all:[
                Permissions.EDU_TYPE_GET,
                Permissions.EDU_TYPE_POST,
                Permissions.EDU_TYPE_PUT,
                Permissions.EDU_TYPE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.EDU_TYPE_GET},
                {title:langDictionary?.add_new, value:Permissions.EDU_TYPE_POST},
                {title:langDictionary?.update, value:Permissions.EDU_TYPE_PUT},
                {title:langDictionary?.delete, value:Permissions.EDU_TYPE_DELETE}
            ]
        },
        {
            title:langDictionary?.sc_specs, id:'sc_specs', 
            all:[
                Permissions.SC_SPEC_GET,
                Permissions.SC_SPEC_POST,
                Permissions.SC_SPEC_PUT,
                Permissions.SC_SPEC_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SC_SPEC_GET},
                {title:langDictionary?.add_new, value:Permissions.SC_SPEC_POST},
                {title:langDictionary?.update, value:Permissions.SC_SPEC_PUT},
                {title:langDictionary?.delete, value:Permissions.SC_SPEC_DELETE}
            ]
        },
        {
            title:langDictionary?.sc_labs, id:'sc_labs', 
            all:[
                Permissions.SC_LAB_GET,
                Permissions.SC_LAB_POST,
                Permissions.SC_LAB_PUT,
                Permissions.SC_LAB_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SC_LAB_GET},
                {title:langDictionary?.add_new, value:Permissions.SC_LAB_POST},
                {title:langDictionary?.update, value:Permissions.SC_LAB_PUT},
                {title:langDictionary?.delete, value:Permissions.SC_LAB_DELETE}
            ]
        },
        {
            title:langDictionary?.sc_grades, id:'sc_grades', 
            all:[
                Permissions.SC_GRADE_GET,
                Permissions.SC_GRADE_POST,
                Permissions.SC_GRADE_PUT,
                Permissions.SC_GRADE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SC_GRADE_GET},
                {title:langDictionary?.add_new, value:Permissions.SC_GRADE_POST},
                {title:langDictionary?.update, value:Permissions.SC_GRADE_PUT},
                {title:langDictionary?.delete, value:Permissions.SC_GRADE_DELETE}
            ]
        },
        {
            title:langDictionary?.sc_jobs, id:'sc_jobs', 
            all:[
                Permissions.SC_JOB_GET,
                Permissions.SC_JOB_POST,
                Permissions.SC_JOB_PUT,
                Permissions.SC_JOB_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SC_JOB_GET},
                {title:langDictionary?.add_new, value:Permissions.SC_JOB_POST},
                {title:langDictionary?.update, value:Permissions.SC_JOB_PUT},
                {title:langDictionary?.delete, value:Permissions.SC_JOB_DELETE}
            ]
        },
        {
            title:langDictionary?.sc_sources, id:'sc_sources', 
            all:[
                Permissions.SC_SOURCE_GET,
                Permissions.SC_SOURCE_POST,
                Permissions.SC_SOURCE_PUT,
                Permissions.SC_SOURCE_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SC_SOURCE_GET},
                {title:langDictionary?.add_new, value:Permissions.SC_SOURCE_POST},
                {title:langDictionary?.update, value:Permissions.SC_SOURCE_PUT},
                {title:langDictionary?.delete, value:Permissions.SC_SOURCE_DELETE}
            ]
        },
        {
            title:langDictionary?.sc_aspirants, id:'sc_aspirants', 
            all:[
                Permissions.SC_ASPIRANT_GET,
                Permissions.SC_ASPIRANT_POST,
                Permissions.SC_ASPIRANT_PUT,
                Permissions.SC_ASPIRANT_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SC_ASPIRANT_GET},
                {title:langDictionary?.add_new, value:Permissions.SC_ASPIRANT_POST},
                {title:langDictionary?.update, value:Permissions.SC_ASPIRANT_PUT},
                {title:langDictionary?.delete, value:Permissions.SC_ASPIRANT_DELETE}
            ]
        },
        {
            title:langDictionary?.sc_animals, id:'sc_animals', 
            all:[
                Permissions.SC_ANIMAL_GET,
                Permissions.SC_ANIMAL_POST,
                Permissions.SC_ANIMAL_PUT,
                Permissions.SC_ANIMAL_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.SC_ANIMAL_GET},
                {title:langDictionary?.add_new, value:Permissions.SC_ANIMAL_POST},
                {title:langDictionary?.update, value:Permissions.SC_ANIMAL_PUT},
                {title:langDictionary?.delete, value:Permissions.SC_ANIMAL_DELETE}
            ]
        },
        {
            title:'Töleg kategoriýalary', id:'payment-categories', 
            all:[
                Permissions.PAYMENT_TARIFF_CATEGORY_GET,
                Permissions.PAYMENT_TARIFF_CATEGORY_POST,
                Permissions.PAYMENT_TARIFF_CATEGORY_PUT,
                Permissions.PAYMENT_TARIFF_CATEGORY_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.PAYMENT_TARIFF_CATEGORY_GET},
                {title:langDictionary?.add_new, value:Permissions.PAYMENT_TARIFF_CATEGORY_POST},
                {title:langDictionary?.update, value:Permissions.PAYMENT_TARIFF_CATEGORY_PUT},
                {title:langDictionary?.delete, value:Permissions.PAYMENT_TARIFF_CATEGORY_DELETE}
            ]
        },
        {
            title:'Nyrhnamalar', id:'price-list', 
            all:[
                Permissions.PAYMENT_TARIFF_GET,
                Permissions.PAYMENT_TARIFF_POST,
                Permissions.PAYMENT_TARIFF_PUT,
                Permissions.PAYMENT_TARIFF_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.PAYMENT_TARIFF_GET},
                {title:langDictionary?.add_new, value:Permissions.PAYMENT_TARIFF_POST},
                {title:langDictionary?.update, value:Permissions.PAYMENT_TARIFF_PUT},
                {title:langDictionary?.delete, value:Permissions.PAYMENT_TARIFF_DELETE}
            ]
        },
        {
            title:'loglar', id:'logs', 
            all:[
                Permissions.LOG_GET,
                Permissions.LOG_DELETE
            ],
            children:[
                {title:langDictionary?.view, value:Permissions.LOG_GET},
                {title:langDictionary?.delete, value:Permissions.LOG_DELETE}
            ]
        }
    ]
    return(
        <div className="border rounded-3 overflow-hidden">
            <div className="w-100 text-start text-slatgray d-flex align-items-center bg-light p-2 rounded-3 fw-500 cursor-pointer bottom-radius-0 border-bottom collapsed"
                data-bs-toggle="collapse" aria-expanded="" data-bs-target={`#admin-roles-form-permissions`} aria-controls="contents-collapse">
                {langDictionary?.permissions}
                <Button theme="light" className="ms-auto">
                    <i className="bi bi-chevron-compact-down"/>
                </Button>
            </div>
            <ul id={'admin-roles-form-permissions'} className="nav-content list-unstyled tree m-0 p-2 collapse">
                {
                    arr.map((item)=>{
                        return(
                            <li className="nav-item">
                                {/* <div className={'d-flex align-items-top mb-2 p-1 text-slatgray fw-500 rounded-2 px-3'}>
                                </div> */}
                                <button class="btn d-inline-flex align-items-center border-0 w-100" data-bs-toggle="collapse"
                                    aria-expanded="" data-bs-target={`#${item?.id}`} aria-controls="contents-collapse">
                                    {item?.title}
                                </button>
                                <ul id={item?.id} className="nav-content list-unstyled collapse ms-5">
                                    <li className="form-check">
                                        <input className={'form-check-input'} type="checkbox" name={`${item?.id}-all`} id={`${item?.id}-all`} 
                                            checked={arrayIncluding(form.permissions, item.all)}
                                            onClick={(e)=>{
                                                let arr = [...form.permissions]
                                                arr =arr.filter((_)=>!item.all.includes(_))
                                                if(e.target.checked) arr.push(...item.all)
                                                setForm({...form, permissions:arr})
                                            }}/>
                                        <label htmlFor={`${item?.id}-all`} className="form-check-label">
                                            {langDictionary?.all}
                                        </label>
                                    </li>
                                {
                                    item.children.map((_item, index)=>{
                                        return(
                                            <li className="form-check">
                                                <input className={'form-check-input'} type="checkbox" name={`${item?.id}-${index}`} id={`${item?.id}-${index}`} onClick={(e)=>{
                                                    
                                                }} checked={form?.permissions.includes(_item.value)} onChange={(e)=>{
                                                    let arr = [...form.permissions]
                                                    if(e.target.checked){
                                                        arr.push(_item.value)
                                                    }else{
                                                        arr = arr.filter((_)=>_!=_item?.value)
                                                    }
                                                    setForm({...form, permissions:arr})
                                                }}/>
                                                <label htmlFor={`${item?.id}-${index}`} className="form-check-label">
                                                    {_item.title}
                                                </label>
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}