import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

export const useLocalSave = () => {
    const [localRecords, setLocalRecords] = useState([]);
    const recordsId = "localRecords";

    function fetchRecords(){
        try {
            const data = JSON.parse(localStorage.getItem("localRecords") || '[]');
            setLocalRecords(data);
        } catch (error) {
            localStorage.removeItem(recordsId)
            fetchRecords();
        }
    }

    useEffect(()=>{
        fetchRecords();
    }, [])

    function addRecord (record){
        let arr = [...localRecords];
        arr.push(record);
        localStorage.setItem(recordsId, JSON.stringify(arr));
        fetchRecords()
    }

    function removeRecord(index){
        let arr = [...localRecords];
        arr = arr.filter((v, i) => i != index);
        localStorage.setItem(recordsId, JSON.stringify(arr));
        fetchRecords();
    }

    function clearRecords(){
        localStorage.setItem(recordsId, JSON.stringify([]));
        fetchRecords();
    }

    return {localRecords, setLocalRecords, addRecord, removeRecord, recordsId, clearRecords, fetchRecords};
}