import { useContext, useEffect, useState } from "react"
import PageTitle from "../../../../components/page-title/page-title"
import AdminWrapper from "../../Wrapper"
import { LangContext } from "../../../../context/LangContext"
import { AccentButton } from "../../../../components/button/button"
import AxiosHelper from "../../../../utils/API/AxiosHelper"
import { handleErrors } from "../../../../utils/error-handler"
import { AuthContext } from "../../../../context/AuthContext"
import LoadingIcon from "../../../../components/loading-icon/loading-icon"
import UserReport from "./users"
import VisitsReport from "./visits"
import ReportPatientsBlock from "./patients/patients"
import ReportVisitsBlock from "./visits/visits"
import ReportAnalysesBlock from "./analyses/analyses"
import ReportOperationsBlock from "./operations/operations"
import ReportPaymentsBlock from "./payments/payments"
import ReportBedsBlock from "./beds/beds"

export default function AdminReports(){
    const {langDictionary} = useContext(LangContext)
    const [options, setOptions] = useState({
        users:{
            specializations:[], positions:[]
        },
        visits:{
            statuses:[],
            types:[],
            departments:[],
            methods:[]
        }
    })
    const [isLoading, setIsLoading] = useState(false)
    const {logout, token} = useContext(AuthContext)
    useEffect(()=>refresh(), [token])
    function refresh() {
        if(!isLoading){
            setIsLoading(true)
            AxiosHelper.getReport('hospital', {}).then((result)=>{
                if(result.status == 200){
                    setOptions(result.data)
                }
            }).catch((e)=>handleErrors(e, logout))
            .finally(()=>setIsLoading(false))
        }
    }
    return (
        <AdminWrapper isSidebar={false}>
            <div className="px-2">
                <PageTitle title={langDictionary?.reports} endComponent={
                    <>
                    <AccentButton theme='secondary' className="px-3" onClick={refresh}>
                        <LoadingIcon isAnimate={isLoading}/>
                    </AccentButton>
                    </>
                }/>
            </div>
            <div className="d-flex align-items-top">
                {/* <Navbar/> */}
                <div className="px-2 w-100">
                    {/* <Counts /> */}
                    <div className="row d-md-stretch">
                        <div className="col-md-6 mb-4">
                            <ReportPatientsBlock/>
                        </div>
                        <div className="col-md-6 mb-4">
                            <ReportVisitsBlock/>
                        </div>
                        <div className="col-md-8 mb-4">
                            <ReportAnalysesBlock/>
                        </div>
                        <div className="col-md-4 mb-4">
                            <ReportOperationsBlock/>
                        </div>
                        <div className="col-md-4 mb-4">
                            <ReportBedsBlock/>
                        </div>
                        <div className="col-md-8 mb-3">
                            <ReportPaymentsBlock/>
                        </div>
                        {/* <UserReport users={options?.users}/>
                        <VisitsReport visits={options.visits}/> */}
                    </div>
                </div>
            </div>
        </AdminWrapper>
    )
}