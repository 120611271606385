import { useContext } from "react"
import { useReactToPrint } from "react-to-print"
import { LangContext } from "../../context/LangContext"

export default function PrintButton({_ref}){
    const handlePrint = useReactToPrint({
        content:()=>_ref?.current
    })
    const {langDictionary} = useContext(LangContext)
    return(
        <button className="btn btn-warning w-100 fw-500"  onClick={handlePrint}>
            <i className="bi bi-printer me-2"/>
            {langDictionary?.print}
        </button>
    )
}