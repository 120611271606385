import { useContext, useRef, useState } from "react";
import Button from "../button/button";
import ModalWithButton from "../modal-button.jsx/modal-btn";
import InputBlock from "../input-block/input-block";
import AxiosHelper from "../../utils/API/AxiosHelper";
import { handleErrors } from "../../utils/error-handler";
import { AuthContext } from "../../context/AuthContext";
import { LangContext } from "../../context/LangContext";
import { appendNotification } from "../notification";
import LoadingIcon from "../loading-icon/loading-icon";
import Loading from "../loading/Loading";
import { getFormattedDate, getFullname } from "../../utils/main";
const modalId = 'red-flag-modal'
export default function PatientRedFlags({patientId, data=[], setData=()=>{}, isCreate = false}){
    const {isReady, logout} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    const [form, setForm] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    // const [data, setData] = useState([])
    function refresh(){
        if(isReady){
            AxiosHelper.getPatientRedFlags({patient_id:patientId, limit:-1, sort:'-createdBy'}).then((result)=>{
                if(result.status == 200){
                    setData(result.data?.data)
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    return(
        <div className="p-2 rounded-3 mb-3 border box-shadow">
            <div className="d-flex align-items-center fw-500">
                <i className="bi bi-flag-fill text-danger me-2"/>
                {langDictionary?.red_flags}
                <div className="d-flex align-items-center ms-auto">
                    {
                        data?.length ?
                        <span className="px-2 rounded-2 bg-accent-danger text-danger ms-auto">
                            {data?.length}
                        </span>
                        :
                        null
                    }
                    <Button theme="light" className="ms-2" onClick={refresh}>
                        <LoadingIcon />
                    </Button>
                </div>
            </div>
            <hr className="my-2" />
            <div className="overflow-hidden rounded-3">
            {
                isLoading ? 
                <Loading/>
                :
                <DataList data={data} setForm={setForm}/>
            }
            </div>
            {
                isCreate &&
                <div className="d-flex align-items-center">
                    <AddModal form={form} setForm={setForm} patientId={patientId} refresh={refresh}/>
                </div>
            }
        </div>
    )
}

function DataList({data=[], setForm=()=>{}, isEdit = true}){
    const {langDictionary} = useContext(LangContext)
    return(
        data && data?.length ?
        <table className="table table-striped m-0">
            <thead className="bg-accent-primary text-primary">
                <tr>
                    <th>#</th>
                    <th>{langDictionary?.note}</th>
                    <th>{langDictionary?.doctor}</th>
                    <th>{langDictionary?.date}</th>
                    {
                        isEdit && <th></th>
                    }
                </tr>
            </thead>
            <tbody>
            {
                data.map((item, index)=>{
                    return(
                        <tr>
                            <td>{index+1}.</td>
                            <td className="w-100 text-start">{item?.flag_type}</td>
                            <td className="w-100 text-start">{getFullname(item?.creator)}</td>
                            <td className="w-100 text-start">{getFormattedDate(item?.createdAt)}</td>
                            {
                                isEdit &&
                                <td>
                                    <Button theme="light" buttonSize="little" className="text-primary" 
                                        data-bs-toggle="modal" data-bs-target={`#${modalId}`} 
                                        onClick={()=>setForm({...item})}>
                                        <i className="bi bi-pencil-square"/>
                                    </Button>
                                </td>
                            }
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
        :
        null
    )
}

function AddModal({patientId, form, setForm, refresh}) {
    const {isReady, logout} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    const [errors, setErrors] = useState({})
    const closeRef = useRef()
    function save() {
        if(form?.id){
            AxiosHelper.updatePatientRedFlag(form?.id, form).then((result)=>{
                if(result.status == 200){
                    appendNotification("Gyzyl baýdajyk üstünlikli üýgedildi!", 'success')
                    if(closeRef.current) closeRef.current.click()
                    refresh()
                }
            }).catch((e)=>handleErrors(e, logout))
        }else{
            AxiosHelper.createPatientRedFlag({...form, patient_id:patientId}).then((result)=>{
                if(result.status == 200){
                    appendNotification("Gyzyl baýdajyk üstünlikli goşuldy!", 'success')
                    if(closeRef.current) closeRef.current.click()
                    refresh()
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    return(
        <ModalWithButton closeRef={closeRef} modalId={modalId} title={form?.id ? "Gyzyl baýdajygy üýtgetmek #"+form?.id : "Gyzyl baýdajyk goşmak"} isSaveEnable={true} saveFunction={save}
            buttonProps={{className:'btn btn-sm btn-light text-primary fw-500 px-3 my-1 mx-auto'}}>
            <div className="row">
                <div className="col-md-12">
                    <InputBlock error={errors?.flag_type} id={'red-flag-flag_type'} label={'Görnüşi'} value={form?.flag_type} setValue={(e)=>setForm({...form, flag_type:e})}/>
                </div>
                {/* <div className="col-md-12">
                    <Textarea error={errors?.description} id={'red-flag-description'} label={'Mazmuny'} value={form?.description} setValue={(e)=>setForm({...form, description:e})}/>
                </div> */}
            </div>
        </ModalWithButton>
    )
}

export function RedFlagModal({data=[]}){
    const closeRef = useRef()
    const viewModalId = 'red-flag-view'
    const {langDictionary} = useContext(LangContext)
    if(data && data?.length){
        return(
            <ModalWithButton closeRef={closeRef} modalId={viewModalId} title={langDictionary?.red_flags} 
                buttonProps={{className:'btn btn-sm bg-accent-danger text-danger fw-bold'}} 
                buttonContent={
                    <><i className="bi bi-flag-fill"/> ({data?.length})</>
                }>
                <DataList data={data} isEdit={false}/>
            </ModalWithButton>
        )
    }
}