import { useContext } from "react"
import Button, { LinkButton } from "../../../../components/button/button"
import Configurations from "../../../../config/configurations"
import LanguageSwitcher from "../../_components/header/_language_switcher"
import Hexagon from "./hexagon"
import styles from './style.module.css'
import { LangContext } from "../../../../context/LangContext"
import icHospital1 from './svg/ic_hospital1.svg'
import icHospital2 from './svg/ic_hospital2.svg'
import icHospital3 from './svg/ic_hospital3.svg'
import icHospital4 from './svg/ic_hospital4.svg'
import icGreenSinus from './svg/ic_green_sinus.svg'
import icIdCard from './svg/ic_id_card.svg'
import icBlueSyrigne from './svg/ic_blue_syrigne.svg'
import icAtom from './svg/ic_atom.svg'
import icBlueHeartTablet from './svg/ic_blue_heart_tablet.svg'
import icDbCloud from './svg/ic_db_cloud.svg'
import icDigitalBrain from './svg/ic_digital_brain.svg'
import icDigitalDoc from './svg/ic_digital_doc.svg'
import icDoctor from './svg/ic_doctor.svg'
import icEKGMonitor from './svg/ic_ekg_monitor.svg'
import icMedicalRecords from './svg/ic_medical_records.svg'
import icHealthMobile from './svg/ic_health_mobile.svg'
import icLongInvoice from './svg/ic_long_invoice.svg'
import icMedicalBook from './svg/ic_medical_book.svg'
import icPurpleVirus from './svg/ic_purple_virus.svg'
import icRadioMonitor from './svg/ic_radio_monitor.svg'
import icRedSyrigne from './svg/ic_red_syrigne.svg'
import icSpeaker from './svg/ic_speaker.svg'
import icWarehouse from './svg/ic_warehouse.svg'
import icNews from './svg/ic_news.svg'
import icSearchEngineWebSite from './svg/ic_search_engine_web_site.svg'
import icDigitalSecurityEnterPinPasskeySecurityCodeSecurityPin from './svg/ic_digital_security_enter_pin_passkey_security_code_security_pin.svg'
import icComputerTelevision from './svg/ic_computer_television.svg'
import icChat from './svg/ic_chat.svg'
import icMedicalMachine from './svg/ic_medical_machine.svg'
import icStatistics from './svg/ic_statistics.svg'
import icMedicalSymbol from './svg/ic_medical_symbol.svg'
import { Link } from "react-router-dom"
export default function HexagonGrid(){
    const {langDictionary} = useContext(LangContext)
    // const items = [
    //     { name: langDictionary.SERVICE_1, icon: icHospital1, link: '/'},
    //     { name: langDictionary.SERVICE_2, icon: icHospital2, link: 'https://fiz2.alemtilsimat.com'},
    //     { name: langDictionary.SERVICE_3, icon: icHospital3, link: 'https://fiz2.alemtilsimat.com'},
    //     { name: langDictionary.SERVICE_4, icon: icHospital4, link: 'https://fiz2.alemtilsimat.com'},
    //     { name: langDictionary.SERVICE_5, icon: icGreenSinus, link: 'https://egmu.gov.tm'},
    //     { name: langDictionary.SERVICE_6, icon: icAtom, link: '/'},
    //     { name: langDictionary.SERVICE_7, icon: icIdCard, link: '/'},
    //     { name: langDictionary.SERVICE_8, icon: icPurpleVirus, link: '/'},
    //     { name: langDictionary.SERVICE_9, icon: icBlueSyrigne, link: 'https://alemhasap.alemtilsimat.com'},
    //     { name: langDictionary.SERVICE_10, icon: icMedicalRecords, link: '/'},
    //     { name: langDictionary.SERVICE_11, icon: icRedSyrigne, link: '/'},
    //     { name: langDictionary.SERVICE_12, icon: icWarehouse, link: '/'},
    //     { name: langDictionary.SERVICE_13, icon: icEKGMonitor, link: '/'},
    //     { name: langDictionary.SERVICE_14, icon: icMedicalSymbol, link: '/'},
    //     { name: langDictionary.SERVICE_15, icon: icLongInvoice, link: '/'},
    //     { name: langDictionary.SERVICE_16, icon: icStatistics, link: 'https://alemhasap.alemtilsimat.com'},
    //     { name: langDictionary.SERVICE_17, icon: icDoctor, link: '/'},
    //     { name: langDictionary.SERVICE_18, icon: icMedicalMachine, link: '/'},
    //     { name: langDictionary.SERVICE_19, icon: icMedicalBook, link: '/'},
    //     { name: langDictionary.SERVICE_20, icon: icDigitalDoc, link: '/'},
    //     { name: langDictionary.SERVICE_21, icon: icBlueHeartTablet, link: '/'},
    //     { name: langDictionary.SERVICE_22, icon: icRadioMonitor, link: '/'},
    //     { name: langDictionary.SERVICE_23, icon: icHealthMobile, link: '/'},
    //     { name: langDictionary.SERVICE_24, icon: icChat, link: '/'},
    //     { name: langDictionary.SERVICE_25, icon: icSpeaker, link: '/'},
    //     { name: langDictionary.SERVICE_26, icon: icNews, link: '/'},
    //     { name: langDictionary.SERVICE_27, icon: icSearchEngineWebSite, link: '/'},
    //     { name: langDictionary.SERVICE_28, icon: icDigitalSecurityEnterPinPasskeySecurityCodeSecurityPin, link: '/'},
    //     { name: langDictionary.SERVICE_29, icon: icDbCloud, link: '/'},
    //     { name: langDictionary.SERVICE_30, icon: icDigitalBrain, link: '/'},
    //     { name: langDictionary.SERVICE_31, icon: icComputerTelevision, link: '/'},
    // ]
    const arr = [
        [
            { name: langDictionary.SERVICE_1, icon: icHospital1, link: '/'}
        ],
        [
            { name: langDictionary.SERVICE_2, icon: icHospital2, link: 'https://fiz2.alemtilsimat.com'}, 
            0, 
            { name: langDictionary.SERVICE_3, icon: icHospital3, link: 'https://fiz2.alemtilsimat.com'}
        ],
        [
            { name: langDictionary.SERVICE_4, icon: icHospital4, link: 'https://fiz2.alemtilsimat.com'}, 
            0, 
            { name: langDictionary.SERVICE_5, icon: icGreenSinus, link: 'https://egmu.gov.tm'}, 
            0, 
            { name: langDictionary.SERVICE_6, icon: icAtom, link: '/'}
        ],
        [
            { name: langDictionary.SERVICE_7, icon: icIdCard, link: '/patients'}, 
            0, 
            { name: langDictionary.SERVICE_8, icon: icPurpleVirus, link: '/'}, 
            0, 
            { name: langDictionary.SERVICE_9, icon: icBlueSyrigne, link: 'https://alemhasap.alemtilsimat.com'}, 
            0, 
            { name: langDictionary.SERVICE_10, icon: icMedicalRecords, link: '/'}
        ],
        [
            { name: langDictionary.SERVICE_11, icon: icRedSyrigne, link: '/'}, 
            0,
            { name: langDictionary.SERVICE_12, icon: icWarehouse, link: '/'}, 
            0, 
            { name: langDictionary.SERVICE_13, icon: icEKGMonitor, link: '/'}, 
        ],
        [
            { name: langDictionary.SERVICE_14, icon: icMedicalSymbol, link: '/'},
            0, 
            { name: langDictionary.SERVICE_15, icon: icLongInvoice, link: '/'}, 
            0,
            { name: langDictionary.SERVICE_16, icon: icStatistics, link: 'https://alemhasap.alemtilsimat.com'}, 
            0, 
            { name: langDictionary.SERVICE_17, icon: icDoctor, link: '/'},
        ],
        [
            { name: langDictionary.SERVICE_18, icon: icMedicalMachine, link: '/'}, 
            0,
            { name: langDictionary.SERVICE_19, icon: icMedicalBook, link: '/'}, 
            0, 
            { name: langDictionary.SERVICE_20, icon: icDigitalDoc, link: '/'}
        ],
        [
            { name: langDictionary.SERVICE_21, icon: icBlueHeartTablet, link: '/'}, 
            0,
            { name: langDictionary.SERVICE_22, icon: icRadioMonitor, link: '/'}, 
            0, 
            { name: langDictionary.SERVICE_23, icon: icHealthMobile, link: '/'}, 
            0, 
            { name: langDictionary.SERVICE_24, icon: icChat, link: '/'}
        ],
        [
            { name: langDictionary.SERVICE_25, icon: icSpeaker, link: '/'},, 
            0,
            { name: langDictionary.SERVICE_26, icon: icNews, link: '/'},, 
            0, 
            { name: langDictionary.SERVICE_27, icon: icSearchEngineWebSite, link: '/'},
        ],
        [
            { name: langDictionary.SERVICE_28, icon: icDigitalSecurityEnterPinPasskeySecurityCodeSecurityPin, link: '/'},, 
            0,
            { name: langDictionary.SERVICE_29, icon: icDbCloud, link: '/'},
        ],
        [
            { name: langDictionary.SERVICE_30, icon: icDigitalBrain, link: '/'}
        ]
    ]
    return(
        <div className={`w-100 vh-100 ${styles.gradientBg} overflow--auto`}>
            <div className="p-3 d-flex align-items-ceter fw-500 mb-3">
                <img src="/img/logo.png" alt="" height={50} className="me-3"/>
                <h1 className="m-0 text-secondary">{Configurations.FULLAPPNAME}</h1>
                <div className="d-flex align-items-center ms-auto">
                    <LanguageSwitcher isSmall={true}/>
                    {/* <ProfileItem key={'dashboard-profile-item'}/> */}
                    <LinkButton theme="light" buttonSize="sm" className="border" to={'/settings'}>
                        <i className="bi bi-gear me-2"/>Sazlamalar
                    </LinkButton>
                </div>
            </div>
            <div className={"d-grid justify-content-center align-items-center pb-5"}>
            {
                arr.map((row, index)=>{
                    return(
                        <div className="d-flex align-items-center justify-content-center" style={{marginTop:-(index ? 120 : 0)+'px'}}>
                        {
                            row.map((item)=>{
                                return(
                                    item ?
                                    <Hexagon link={item?.link} content={
                                        <div>
                                            <img src={item.icon} height={50}/>
                                            <div className="mt-1">
                                                {item.name}
                                            </div>
                                        </div>
                                    }/>
                                    :
                                    <div className="" style={{width:120+'px'}}/>
                                )
                            })
                        }
                        </div>
                    )
                })
            }
            </div>
            <div className="position-absolute bottom-0 start-0 p-2 w-100">
                <div className="d-flex align-items-center w-100 justify-content-between border-top p-2 pb-0 text-small fw-500 text-slatgray">
                    <span className="d-none d-md-block">
                        Copyright &copy; {new Date().getFullYear()}. {langDictionary?.copyright_text} 
                    </span>
                    <span className="">
                        Powered by {Configurations.COMPANY_NAME} {Configurations.COMPANY_APPENDIX}
                    </span>
                </div>
            </div>
        </div>
    )
}