import { useContext, useEffect, useState } from "react"
import { LangContext } from "../../../../../context/LangContext"
import Button, { AccentButton } from "../../../../../components/button/button"
import SemiDonutChart from "../../../../../components/semi-donut-chart"
import { COLORS } from "../../../../../utils/main"
import DonutChart from "../../../../../components/donut-chart"
import Chart from "react-apexcharts"
import BarChart from "../../../../../components/bar-chart"
import AreaChart from "../../../../../components/area-chart"

export default function ReportAnalysesBlock(){
    const {langDictionary} = useContext(LangContext)
    return(
        <div className="p-2 rounded-3 box-shadow bg-white">
            <div className="d-flex align-items-center">
                <span className="text-slatgray fw-500">
                    {langDictionary?.analyses}
                </span>
                <div className="ms-auto">
                    <AccentButton theme="primary" className="me-2">
                        <i className="bi bi-file-earmark-medical"/>
                    </AccentButton>
                    <Button theme="light">
                        <i className="bi bi-arrow-repeat"/>
                    </Button>
                </div>
            </div>
            <hr className="my-2" />
            <div className="row">
                <div className="col-md-9 border-end">
                    <ByDate/>
                </div>
                <div className="col-md-3">
                    <ByStatus/>
                    <ByType/>
                </div>
            </div>
        </div>
    )
}
function ByStatus() {
    return(
        <div className="p-1 pb-0 text-medium ">
            <div className="d-flex align-items-center">
                <div className="col-6 pe-1">
                    <span className="text-slatgray text-end fw-500">
                        <i className="bi bi-square-fill text-warning me-1"/>
                        Garaş. - 55%
                    </span>
                </div>
                <div className="col-6 ps-1 text-end">
                    <span className="text-slatgray text-end fw-500">
                        <i className="bi bi-square-fill text-primary me-1"/>
                        Tamaml. - 45%
                    </span>
                </div>
            </div>
            <div className="overflow-hidden" style={{maxHeight:100+'px'}}>
                <SemiDonutChart 
                    labels={['Garaşylýan', 'Tamamlanan']}
                    series={[55, 45]}
                    height={200}
                    labelShow={false}
                    toolbarShow={false}
                    colors={['#FF824A', '#0D6EFD']}
                    />
            </div>
        </div>
    )
}

function ByType() {
    const data = [
        {x:'Rentgen', y:1250},
        {x:'KT', y:1360},
        {x:'Gan umumy', y:750},
        {x:'Kardiologiýa', y:150},
        {x:'Ultrases', y:980}
    ]
    return(
        <div className="w-100">
            <san className="text-slatgray fw-500">
                Barlag görnüşleri
            </san>
            <div className="overflow-hidden" style={{maxHeight:150+'px'}}>
                <BarChart id={'patient-benefit-chart'} text={undefined} colors={["#73A3C1"]} height={170}
                    toolbarShow={false}
                    showYLabels={false}
                    showXLabels={false}
                    series={[
                        {
                            name:'Geçirilen barlag',
                            data
                        }
                    ]}/>
            </div>
        </div>
    )
}

function ByDate(){
    const startDate = new Date()
    startDate.setDate(startDate.getDate()-6)
    const [series, setSeries]= useState([])
    useEffect(()=>{
        const arr = []
        for (let i = 0; i < 6; i++) {
            arr.push({
                x: startDate.getTime(),
                y: (Math.random()*100)
            })
            startDate.setDate(startDate.getDate()+1)
        }
        setSeries(arr)
    }, [])
    const [options, setOptions] = useState({
        chart:{
            type:'area',
            height:350,
            toolbar:{
                show:false
            }
        },
        series:[
            {
                name:'Radiologiýa',
                data:[45, 42, 38, 52, 51, 48, 51]
            },
            {
                name:'Laboratoriýa',
                data:[60, 65, 71, 70, 68, 69, 81]
            },
            {
                name:'Hünarmen',
                data:[35, 37, 31, 39, 47, 42, 61]
            },
            {
                name:'Enjam',
                data:[17, 25, 30, 28, 29, 26, 41]
            }
        ],
        xaxis: {
            type: 'datetime',
            categories: ["04/11/2024", "04/12/2024", "04/13/2024", "04/14/2024", "04/15/2024", "04/16/2024", "04/17/2024"]
        },
        stroke: {
            width: 2,
            curve:'smooth'
        },
        tooltip: {
          shared: false,
          intersect: true
        },
        markers: {
          size: 4
        },
        legend: {
            horizontalAlign: 'left',
            position: 'top'
        },
        colors:COLORS,
        dataLabels: {
            enabled: false,
        },
    })
    return(
        <Chart id={'analyse-reports-by-date'} type={"area"} height={300} options={options} series={options.series}/>
    )
}