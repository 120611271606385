import React from 'react';
import styles from './style.module.css';
import { Link } from 'react-router-dom';

const Hexagon = ({ content, link = ''}) => {
  return (
    <Link to={link} className={styles.hex}>
      <div className={styles.content}>{content}</div>
    </Link>
  );
};

export default Hexagon;