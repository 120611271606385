export default function ScrollToTop({id}){
    return (
        <button className="btn btn-to-top" onClick={()=>{
            const el = document.getElementById(id);
            el.scrollTo({top:0, behavior:'smooth'})
        }}>
            <div className="bottom-line"></div>
            <i className="bi bi-arrow-up"/>
        </button>
    )
}