import InputBlock from "../../../../components/input-block/input-block";
import Select from "../../../../components/select/select";
import { districtTypes, epidStatuses, getArrayFromObject, villageTypes } from "../../../../utils/main";
export default function Form({form, setForm, errors, districts}){
    return (
        <div className="row">
            <div className="col-md-12">
                <InputBlock error={errors?.name} id={'admin-villages-form-name'} label={'Ady'} 
                    value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                    icon={'bi bi-keyboard'} />
            </div>
            <div className="col-md-6">
                <Select error={errors?.type} id={'villages-type'} items={getArrayFromObject(villageTypes)} label={"Görnüşi"}
                    value={form?.type !== undefined ? form?.type : ''} setValue={(e)=>setForm({...form, type:e})}/>
            </div>
            <div className="col-md-6">
                <Select error={errors?.district} id={'admin-villages-form-district'} label={'Etrap/şäher'} key={'admin-villages-form-district'} 
                    items={districts.map((_)=>{return({id:_?.id, name:`${_?.name} ${districtTypes[_?.type].toLowerCase()}`})})} 
                    value={form.district || ''} setValue={(district)=>setForm({...form , district})}/>
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.order} id={'admin-bed-form-order'} label={'Tertip belgisi'} 
                    value={form?.order || ''} setValue={(e)=>setForm({...form, order:e})} type="number" 
                    icon={'bi bi-123'} />
            </div>
            <div className="col-md-6">
                <Select error={errors?.status} id={'villages-status'} items={epidStatuses} label={"Statusy"}
                    value={form?.status !== undefined ?  form?.status : ''} setValue={(e)=>setForm({...form, status:e})} itemTitleLabel="title" itemValueLabel="value"/>
            </div>
        </div>
    )
}