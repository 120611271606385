import { useContext, useState } from "react"
import { LangContext } from "../../../../../context/LangContext"
import Button, { AccentButton } from "../../../../../components/button/button"
import SemiDonutChart from "../../../../../components/semi-donut-chart"
import { COLORS } from "../../../../../utils/main"
import Chart from "react-apexcharts"

export default function ReportOperationsBlock(){
    const {langDictionary} = useContext(LangContext)
    return(
        <div className="p-2 rounded-3 box-shadow bg-white">
            <div className="d-flex align-items-center">
                <span className="text-slatgray fw-500">
                    {langDictionary?.operations}
                </span>
                <div className="ms-auto">
                    <AccentButton theme="primary" className="me-2">
                        <i className="bi bi-file-earmark-medical"/>
                    </AccentButton>
                    <Button theme="light">
                        <i className="bi bi-arrow-repeat"/>
                    </Button>
                </div>
            </div>
            <hr className="my-2" />
            <div className="row">
                <div className="col-md-6 border-end">
                    <div className="d-grid fw-500">
                        {
                            [
                                {title:'Ählisi', value:1520},
                                {title:'Tamamlanan', value:750},
                                {title:'Garaşylýan', value:350},
                                {title:'Ret edilen', value:25}
                            ].map((item)=>{
                                return(
                                    <div className="d-flex align-items-bottom my-1">
                                        <span className="text-slatgray text-nowrap">{item.title}</span>
                                        <div className="border-0 border-bottom border-dashed w-100"/>
                                        <span className="text-warning">{item.value}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <ByStatus/>
                </div>
                <div className="col-md-12">
                    <ByType/>
                </div>
            </div>
        </div>
    )
}


function ByStatus() {
    return(
        <div className="p-1 pb-0 text-medium ">
            <div className="d-flex align-items-center">
                <div className="col-6 pe-1">
                    <span className="text-slatgray text-end fw-500">
                        <i className="bi bi-square-fill text-warning me-1"/>
                        Garaş. - 55%
                    </span>
                </div>
                <div className="col-6 ps-1 text-end">
                    <span className="text-slatgray text-end fw-500">
                        <i className="bi bi-square-fill text-primary me-1"/>
                        Tamaml. - 45%
                    </span>
                </div>
            </div>
            <div className="overflow-hidden" style={{maxHeight:105+'px'}}>
                <SemiDonutChart 
                    labels={['Garaşylýan', 'Tamamlanan']}
                    series={[55, 45]}
                    height={220}
                    labelShow={false}
                    toolbarShow={false}
                    colors={['#FF824A', '#0D6EFD']}
                    />
            </div>
        </div>
    )
}

function ByType() {
    const data = [
        {x:'Terapewt', y:1250},
        {x:'Kardiolog', y:1360},
        {x:'Dem alyş ýol.', y:750},
        {x:'Trawmatologiýa', y:150},
        {x:'Newrologiýa', y:980}
    ]

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'radar',
            toolbar: {
                show: false
            }
        },
        title: {
            text: undefined,
        },
        xaxis: {
            categories: data.map((_)=>_.x),
            labels: {
                show: true,
                style: {
                    colors: ["#a8a8a8"],
                    fontSize: "11px",
                    fontFamily: 'Arial'
                }
            }
        },
        stroke: {
            width: 1,
        },
        markers: {
            size: 3,
        },
        colors:COLORS,
        plotOptions: {
            radar: {
            polygons: {
                strokeColor: '#e8e8e8',
                fill: {
                    colors: ['#f8f8f8', '#fff']
                }
            }
            }
        }
    });

    const [chartSeries, setChartSeries] = useState([
        {
            name: 'Ýüztutma görnüşleri',
            data: data.map((_)=>_.y),
        }
    ]);
    return(
        <div className="w-100">
            <div className="overflow-hidden" style={{maxHeight:220+'px', marginTop:-30+'px'}}>
                <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="radar"
                    width="100%"
                    height="300"/>
            </div>
        </div>
    )
}