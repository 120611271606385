import Button from "../button/button"
import InputWrapper from "../input-wrapper/input-wrapper"

export default function Select({error, label, id, value, setValue, items,  
                                itemValueLabel = 'id', itemTitleLabel='name', inputProps = {},
                                isDefault = true, defaultText ='...', condition=()=>{return(true)}, isTitle = true,
                                isRefreshable=false, className='', isRequired = false, refreshFunction=()=>{}, isDisabled=false}){
    return (
        <InputWrapper error={error} id={id} label={label} key={`'${id}-wrapper`} isRequired={isRequired} isTitle={isTitle}>
            <div className="d-flex align-items-center">
                <select className={`form-select ${className} `+(isDisabled ? 'bg-accent-secondary' : "")} id={id} name={id} 
                    value={value} onChange={(e)=>setValue(e.target.value, e)} {...inputProps}
                    disabled={isDisabled}>
                    {
                        isDefault ?
                        <option value="">{defaultText}</option>
                        :
                        null
                    }
                    {
                        items.map((item)=>{
                            if(condition(item)){
                                return (
                                    <option value={item[itemValueLabel]}>{item[itemTitleLabel]}</option>
                                )
                            }
                        })
                    }
                </select>
                {
                    isRefreshable ?
                    <Button theme="light" onClick={(e)=>refreshFunction(e)} className="ms-1">
                        <i className="bi bi-arrow-repeat"/>
                    </Button>
                    :
                    null
                }
            </div>
        </InputWrapper>
    )
}