import InputBlock from "../../../../components/input-block/input-block";
import Select from "../../../../components/select/select";
import { districtTypes, epidStatuses, regionTypes, villageTypes } from "../../../../utils/main";
export default function Form({form, setForm, errors, options}){
    return (
        <div className="row">
            <div className="col-md-12">
                <InputBlock error={errors?.name} id={'admin-streets-form-name'} label={'Ady'} 
                    value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                    icon={'bi bi-keyboard'} />
            </div>
            <div className="col-md-12">
                <Select error={null} id={'admin-streets-form-region'} label={'Welaýat'} key={'admin-streets-form-region'} 
                    items={options.regions.map((_)=>{return({..._, name:`${_?.name} ${regionTypes[_?.type].toLowerCase()}`})})} 
                    value={form?._district?.region || ''} setValue={(region)=>setForm({...form, _district:{region}})}/>
            </div>
            <div className="col-md-6">
                <Select error={errors?.district} id={'admin-streets-form-district'} label={'Etrap/şäher'} key={'admin-streets-form-district'} 
                    items={options.districts.map((_)=>{return({..._, name:`${_?.name} ${districtTypes[_?.type].toLowerCase()}`})})} 
                    value={form.district} setValue={(district)=>setForm({...form , district})}
                    condition={(item)=>item?.region == form?._district?.region}
                    />
            </div>
            <div className="col-md-6">
                <Select error={errors?.village} id={'admin-streets-form-village'} label={'Etrap/şäher'} key={'admin-streets-form-village'} 
                    items={options.villages.map((_)=>{return({..._, name:`${_?.name} ${villageTypes[_?.type].toLowerCase()}`})})} 
                    value={form.village} setValue={(village)=>setForm({...form , village})}
                    condition={(item)=>item?.district == form?.district}
                    />
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.order} id={'admin-streets-form-order'} label={'Tertip belgisi'} 
                    value={form?.order} setValue={(e)=>setForm({...form, order:e})} type="number" 
                    icon={'bi bi-123'} />
            </div>
            <div className="col-md-6">
                <Select error={errors?.status} id={'streets-status'} items={epidStatuses} label={"Statusy"}
                    value={form?.status} setValue={(e)=>setForm({...form, status:e})} itemTitleLabel="title" itemValueLabel="value"/>
            </div>
        </div>
    )
}