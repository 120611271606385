import { useContext } from "react"
import { AuthContext } from "../../../../context/AuthContext"
import Dropdown from "../../../../components/dropdown"
import Configurations from "../../../../config/configurations"
import { LangContext } from "../../../../context/LangContext"
import { Link } from "react-router-dom"
import styles from './profile.module.css'
import Modules from "../../../../utils/modules"
import { firstLetterUpperCase } from "../../../../utils/main"

export default function ProfileItem(){
    const {user, isReady, logout}  = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    const id = "profile-dropdown"
    const positions = [styles.objectPositionCenter, styles.objectPositionTop, styles.objectPositionBottom]
    return (
        <Dropdown id={id} title={
            <div className="profile-item cursor-pointer" id={id} data-bs-toggle="dropdown">
                <img src={user?.thumbnail ? `${Configurations.getStaticServer()}user/${user?.id}/${user?.thumbnail}` : '/img/thumbnail.png' } 
                    className={styles.image+ ' ' +positions[user?.thumbnail_position || 0]}    crossOrigin="" />
                <span>{`${(user?.name+'').substring(0,1).toUpperCase()}.${firstLetterUpperCase(user?.surname)}`}</span>
                <i className="bi bi-caret-down-fill ms-3 text-small"/>
            </div>
        } isButton={false} dropdownClass="p-1">
            <ul className="list-unstyled profile-dropdown p-0">
                {
                    user && user._role?.modules && user._role?.modules.includes(Modules.SETTINGS) ?
                    <Link to={'/settings'} className="list-item">
                        <i className={"bi bi-gear"}/>
                        {langDictionary?.settings}
                    </Link>
                    :
                    null
                }
                <Link to={'/messenger'} className="list-item">
                    <i className={"bi bi-chat-square"}/>
                    {langDictionary?.chat}
                </Link>
                <li className="list-item" onClick={()=>{
                    if(window.confirm("Siz hakykatdan hem ulgamdan çykmakçymy?")){
                        logout()
                    }
                }}>
                    <i className={"bi bi-box-arrow-right"}/>
                    {langDictionary?.logout}
                </li>
                <li className="list-item">
                    <i className={"bi bi-info-circle"}/>
                    {langDictionary?.version} {Configurations.VERSION}
                </li>
            </ul>
        </Dropdown>
    )
}