export default class Modules{
    static PATIENTS = 1
    static PATIENTS_VIEW = 2
    static PATIENTS_UPDATE = 3
    static PATIENTS_CREATE = 4
    static PATIENTS_DELETE = 5
    static PATIENTS_VISITS = 6
    static PATIENTS_ANALYSE_PLANS = 7
    static PATIENTS_ANALYSE_RESULTS = 8
    static PATIENTS_PAYMENTS = 9
    static PATIENTS_REGISTERS = 10

    static VISITS = 11
    static VISITS_VIEW = 12
    static VISITS_UPDATE = 13
    static VISITS_CREATE = 14
    static VISITS_DELETE = 15
    static VISITS_ANALYSE_PLANS = 16
    static VISITS_LAB = 17
    static VISITS_RAD = 18
    static VISITS_SPEC = 50
    static VISITS_INSTRUMENTAL = 51
    static VISITS_DIAGNOSE = 56
    static VISITS_CLYNIC = 19
    static VISITS_DOCTOR_ANALYSE = 55
    static VISITS_TR_ORDER = 20
    static VISITS_TR_PLAN = 21
    static VISITS_OPERATIONS = 22
    static VISITS_TR_DIARY = 60
    static VISITS_ARB = 58
    static VISITS_CONSILIUMS = 57
    static VISITS_ARB_DIARY = 23
    static VISITS_OUT = 24
    static VISITS_PM = 25
    static VISITS_PAYMENTS = 26
    static VISITS_HISTORY = 27

    static REGISTERS = 28
    static COMINGS = 29
    static AMBULATOR = 30

    static RADIOLOGY = 31
    static RADIOLOGY_ANALYSES = 32
    static RADIOLOGY_ANALYSE_VIEW = 33

    static LABORATORY = 34
    static LABORATORY_ANLAYSES = 35
    static LABORATORY_ANLAYSE_VIEW = 36

    static SPEC = 37
    static SPEC_ANALYSES = 38
    static SPEC_ANALYSE_VIEW = 39

    static CASHIER = 41
    static CASHIER_REPORTS = 42
    
    static TREATMENTS = 40
    static REPORTS = 43
    static SCIENCE = 44
    static STATISTICS = 45
    static SETTINGS = 46
    static ASPIRANTS = 47
    static LIBRARY = 48
    static ROOMS = 49
    
    static INSTRUMENTAL = 52
    static INSTRUMENTAL_ANALYSES = 53
    static INSTRUMENTAL_ANALYSE_VIEW = 54

    static MODULES = {
        [this.PATIENTS]:{
            tm:"Raýatlar",
            eng:"Patients",
            ru:"Пациенты"
        },
        [this.VISITS]:{
            tm:"Ýüztutmalar",
            eng:"Visits",
            ru:"Посещение"
        },
        [this.LABORATORY]:{
            tm:"Barlaghana",
            eng:"Laboratory",
            ru:"Лаборатория"
        },
        [this.RADIOLOGY]:{
            tm:"Radiologiýa",
            eng:"Radiology",
            ru:"Радиология"
        },
        [this.SPEC]:{
            tm:"Inçe hünärmenler",
            eng:"Specializations",
            ru:"Специалные анализы"
        },
        [this.CASHIER]:{
            tm:"Kassa",
            eng:"Cashier",
            ru:"Касса"
        },
        [this.SCIENCE]:{
            tm:"Ylym",
            eng:"Science",
            ru:"Наука"
        },
        [this.STATISTICS]:{
            tm:"Statistika",
            eng:"Statistics",
            ru:"Статистика"
        },
        [this.SETTINGS]:{
            tm:"Sazlamalar",
            eng:"Sazlamalar",
            ru:"Sazlamalar"
        },
    }
}