import InputBlock from "../../../../../components/input-block/input-block";
import Select from "../../../../../components/select/select";
import Textarea from "../../../../../components/textarea/textarea";

export default function OfficeElement({form, setForm, errors, options}){
    return (
        <div className="row">
            <div className="col-md-6">
                <Select error={errors?.department} id={'admin-users-form-office'} label={"Degişli bölümi"} key={'admin-users-form-office-wrapper'}
                    items={options?.departments} value={form?.department} setValue={(e)=>setForm({...form, department:e})}/>
            </div>
            <div className="col-md-6">
                <Select error={errors?.position} id={'admin-users-form-position'} label={"Wezipesi"} key={'admin-users-form-position-wrapper'}
                    items={options?.positions} value={form?.position} setValue={(e)=>setForm({...form, position:e})} condition={(item)=>item?.department == form?.department}/>
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.room} id={'admin-users-form-room'} label={'Otag'} 
                    value={form?.room} setValue={(e)=>setForm({...form, room:e})}
                    icon={'bi bi-door-open'} />
            </div>
            <div className="col-md-6">
                <Select error={errors?.role} id={'admin-users-form-role'} label={"Roly"} key={'admin-users-form-role-wrapper'}
                    items={options?.roles} value={form?.role} setValue={(e)=>setForm({...form, role:e})}/>
            </div>
            <div className="col-md-12">
                <Textarea error={errors?.note} label={"Bellik"} id="admin-users-form-note" 
                    value={form?.note} setValue={(e)=>setForm({...form, note:e})}/>
            </div>
        </div>
    )
}