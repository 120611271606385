export default function Badge({theme = '', className = '', isDot = true, ...props}){
    return(
        <span className={`badge bg-accent-${theme} rounded-2 text-${theme} p-1 fw-500 ${className}`}>
            {
                isDot &&
                <i className={`bi bi-circle-fill me-1 text-${theme} text-tiny`}/>
            }
            {props?.children}
        </span>
    )
}