import Badge from "../badge/badge"
import { AccentButton } from "../button/button"
import Sort from "../sort/sort"

export default function TableStatusItem(filter, setFilter){
    return(
        {
            text:<Sort text="Status" filter={filter} setFilter={setFilter} targetAttribute='status'/>, content:(item)=>{
                return (
                    <Badge theme={item?.status == 99 ? "secondary" : 'success'} isDot={false}>
                        <i className={`bi bi-eye${item?.status == 99 ? '-slash' : ''}`}/>
                    </Badge>
                )
            }
        }
    )
}