import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { addZero, getFormattedDate, getFullname, getStatuses, getString } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import { Link } from "react-router-dom";
import Filter from "./filter";
import { handleErrors } from "../../../../utils/error-handler";
import Button from "../../../../components/button/button";

export default function AdminUsers(){
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState({});
    const [count, setCount] = useState(0);
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:10, statuses:[], roles:[]});
    const [isLoading, setIsLoading] = useState(false);
    const {isReady, token, logout, user} = useContext(AuthContext);
    const pageKey="admin-users-page";
    const pageTitle="Ulanyjylar";
    useEffect(()=>refresh(), [filter])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="F.A.A" filter={filter} setFilter={setFilter} targetAttribute='surname'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {getFullname(item, true)}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Ulanyjy ady" filter={filter} setFilter={setFilter} targetAttribute='login'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.login) ? getString(item?.login) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Tel. belgisi" filter={filter} setFilter={setFilter} targetAttribute='phone_number'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.phone_number) ? '+993 '+getString(item?.phone_number) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Hünäri" filter={filter} setFilter={setFilter} targetAttribute='specialization'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?._specialization) ? getString(item?._specialization?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Bölümi" filter={filter} setFilter={setFilter} targetAttribute='department'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?._department) ? getString(item?._department?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Wezipesi" filter={filter} setFilter={setFilter} targetAttribute='position'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {getString(item?._position?.name) || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Otag" filter={filter} setFilter={setFilter} targetAttribute='room'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {getString(item?.room) || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Roly" filter={filter} setFilter={setFilter} targetAttribute='role'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?._role) ? getString(item?._role?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Statusy" filter={filter} setFilter={setFilter} targetAttribute='status'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(getStatuses()[item?.status])}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Bloklanan" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.blocked_till);
                return (
                    item?.blocked_till ?
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '/', true)}
                    </span>
                    :
                    '-//-'
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date)}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <Button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu ulanyjyny ýok etmekçimi?")){
                                AxiosHelper.deleteUser(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Ulanyjy üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                }).catch((e)=>handleErrors(e, logout))
                            }
                        }}>
                            <i className="bi bi-trash2"/>
                        </Button>
                        <Link className="btn btn-sm bg-accent-primary text-primary m-1" to={'/settings/users/update/'+item?.id}>
                            <i className="bi bi-pencil"/>
                        </Link>
                        {
                            item?.blocked_till && <UnlockButton userId={item?.id} refresh={refresh}/>
                        }
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getUsers(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle}
            isFilterable={true} filterBlock={<Filter filter={filter} setFilter={setFilter} roles={roles}/>}
            startBlock={
                <Link to={'/settings/users/create'} className="btn btn-sm btn-primary px-3 text-nowrap">
                    <i className="bi bi-plus-lg me-2"/>
                    Täze goşmak
                </Link>
            } />
    )
}

function UnlockButton({userId, refresh}) {
    const {logout} = useContext(AuthContext)
    function handleUnlock() {
        if(window.confirm("Siz hakykatdan hem bu ulanyjyny wagtlaýyn blkdan aýyrmakçymy?")){
            AxiosHelper.unlockUser(userId).then((result)=>{
                if(result.status === 200){
                    appendNotification("Ulanyjy üstünlikli blokdan çykaryldy!", 'success')
                    refresh()
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    return(
        <Button theme="light" className="ms-1 border" onClick={handleUnlock}>
            <i className="bi bi-unlock"/>
        </Button>
    )
}