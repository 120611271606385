import CheckableCard from "../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import Select from "../../../../components/select/select";
import { booleanVals, epidStatuses } from "../../../../utils/main";
export default function Form({form, setForm, errors, saveFunction}){
    return (
        <div className="row">
            <div className="col-md-12">
                <InputBlock error={errors?.name} id={'admin-doc-types-form-name'} label={'Ady'} 
                    value={form?.name || ''} setValue={(e)=>setForm({...form, name:e})}
                    icon={'bi bi-keyboard'} />
            </div>
            <div className="col-md-12">
                <InputBlock error={errors?.short_name} id={'admin-doc-types-form-short_name'} label={'Gysga ady'} 
                    value={form?.short_name || ''} setValue={(e)=>setForm({...form, short_name:e})}
                    icon={'bi bi-keyboard'} />
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.order} id={'admin-doc-types-form-order'} label={'Tertip belgisi'} 
                    value={form?.order || ''} setValue={(e)=>setForm({...form, order:e})} type="number"
                    icon={'bi bi-123'} />
            </div>
            <div className="col-md-6">
                <Select error={errors?.status} id={'doc-types-status'} items={epidStatuses} label={"Statusy"}
                    value={form?.status !== undefined ?  form?.status : ''} setValue={(e)=>setForm({...form, status:e})} itemTitleLabel="title" itemValueLabel="value"/>
            </div>
            <div className="col-md-12">
                <InputWrapper error={errors?.isSerialKeyRequired} id={'admin-doc-types-form-status'} label={'Seriýa belgisi bolmalymy'} key={'admin-doc-types-form-status-wrapper'}>
                    <div className="d-flex flex-wrap justify-content-center">
                        {
                            booleanVals.map((val)=>{
                                return (
                                    <CheckableCard title={val.title} isActive={form?.isSerialKeyRequired == val.value} 
                                        onClick={()=>setForm({...form, isSerialKeyRequired:val.value})} key={'admin-doc-types-item-'+val.value}/>
                                )
                            })
                        }
                    </div>
                </InputWrapper>
            </div>
        </div>
    )
}