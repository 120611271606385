import { copyToClipboard } from "../../utils/main";

export default function CopyableText({text, textAppendix='', className = '', isIcon = true}){
    return (
        <span className={`cursor-pointer ${className}`} onClick={()=>copyToClipboard(text)} title="Nusgala">
            {
                isIcon &&
                <i className="bi bi-copy me-2 cursor-pointer"/>
            }
            {textAppendix} {text}
        </span>
    )
}