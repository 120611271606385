import { useContext } from "react";
import AdminWrapper from "../../pages/admin/Wrapper";
import DataViewer from "../data-viewer";
import FilterBlock from "../filter-block/filter-block";
import Loading from "../loading/Loading";
import NoResult from "../noResult/noResult";
import PageTitle from "../page-title/page-title";
import { AuthContext } from "../../context/AuthContext";

export default function SettingsBlock({
    filter, setFilter, startBlock, isLoading = false, selectedViewBlock, onClick = ()=>{},  filterBottomBlock = null,
    data, tableItems, count, pageKey='', pageTitle='', isFilterable=false, filterBlock=null,
    onDoubleClick = ()=>{}, pageTitleEndComponent=null, isRefreshable=false, refresh=()=>{}}){
    const {isReady, user} = useContext(AuthContext);
    return (
        <AdminWrapper isSidebar={false}>
            <PageTitle title={pageTitle} endComponent={pageTitleEndComponent} />
            <div className="d-flex">
                <div className="card p-2 w-100">
                    <FilterBlock filter={filter} setFilter={setFilter} key={`${pageKey}-filter-block`} isRefreshable={isRefreshable} refresh={refresh}
                        filterBlock={filterBlock} isFilterable={isFilterable}
                        startBlock={startBlock} filterBottomBlock={filterBottomBlock}/>
                        {
                            isLoading ?
                            <Loading/>
                            :
                            data && data?.length ?
                            <>
                            <DataViewer count={count} data={data} filter={filter} items={tableItems} refresh={refresh} setFilter={setFilter} 
                                onDoubleClick={onDoubleClick} onClick={onClick} 
                                key={'settings-block-dataviewer'}/>
                            </>
                            :
                            <NoResult key={`${pageKey}-no-result-block`} isRefreshable={isRefreshable} refreshFunction={refresh}/>
                        }

                    
                </div>
                {selectedViewBlock}
            </div>
        </AdminWrapper>
    )
}