import { useContext } from "react";
import BarcodeModal from "../../../../components/barcode-modal/barcode-modal";
import CopyableText from "../../../../components/copyable-text/copyable-text";
import { appendNotification } from "../../../../components/notification";
import ProfileImg from "../../../../components/profile-img/profile-img";
import Configurations from "../../../../config/configurations";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../../utils/error-handler";
import { getAge, getFormattedDate, getFullname, getString } from "../../../../utils/main";
import { LangContext } from "../../../../context/LangContext";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";

export default function PatientGeneralData({model, setModel, isUploadable = false}){
    const {langDictionary} = useContext(LangContext)
    const {logout} = useContext(AuthContext)
    const list = [
        {title:langDictionary?.birth_date, icon:"calendar2", value:`${getFormattedDate(model?.birth_date)} (${getAge(new Date(model?.birth_date), (model?.post_mortem ? model?.post_mortem?.date : undefined))})`, condition:model?.birth_date},
        {title:langDictionary?.death_date, icon:"calendar2", value:`${getFormattedDate(model?.post_mortem?.date)}`, condition:model?.post_mortem},
        {title:langDictionary?.origin, icon:"globe2", value:model?._country?.name, condition:model?._country?.name},
        {title:langDictionary?.phone_number, icon:"telephone", value:`+993 ${model?.phone_number}`, condition:model?.phone_number},
        {title:langDictionary?.registered_region, icon:"geo-alt", value:getString(model?._registered_region?.name), condition:model?._registered_region},
        {title:langDictionary?.registered_district, icon:"geo-alt", value:`${getString(model?._registered_district?.name)}`, condition:model?._registered_district},
        {title:langDictionary?.registered_village, icon:"geo-alt", value:`${getString(model?._registered_village?.name)}`, condition:model?._registered_village},
        {title:langDictionary?.living_region, icon:"geo-alt", value:getString(model?._living_region?.name), condition:model?._living_region},
        {title:langDictionary?.living_district, icon:"geo-alt", value:`${getString(model?._living_district?.name)}`, condition:model?._living_district},
        {title:langDictionary?.living_village, icon:"geo-alt", value:`${getString(model?._living_village?.name)}`, condition:model?._living_village},
        {title:langDictionary?.email, icon:"envelope", value:`${getString(model?.mail)}`, condition:model?.mail}
    ]
    function upImg(e){
        if(e?.target?.files?.length &&window.confirm(langDictionary?.confirm_thumb_upload)){
            const formData = new FormData();
            formData.append('image', e.target?.files[0]) 
            AxiosHelper.uploadPatientImage(model?.id, formData).then((result)=>{
                if(result.status == 200){
                    appendNotification(`${langDictionary?.patient_thumbnail} ${getString(langDictionary?.successfully_saved).toLowerCase()}`, 'success')
                    setModel({...model, thumbnail:result.data?.model?.thumbnail, thumbnail_position:result.data?.model?.thumbnail_position})
                    setModel({...model, thumbnail:result.data?.model?.thumbnail, thumbnail_position:result.data?.model?.thumbnail_position})
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    function updateImagePosition(thumbnail_position){
        AxiosHelper.updatePatientImagePosition(model?.id, {thumbnail_position}).then((result)=>{
            if(result.status == 200){
                appendNotification('Raýat suraty üstünlikli üýtgedildi', 'success')
                setModel({...model, thumbnail_position})
                setModel({...model, thumbnail_position})
            }
        }).catch((e)=>handleErrors(e, logout))
    }
    function rmImg(){
        if(window.confirm(langDictionary?.confirm_thumb_delete)){
            AxiosHelper.deletePatientThumbnail(model?.id).then((result)=>{
                if(result.status == 200){
                    appendNotification(`${langDictionary?.patient_thumbnail} ${(langDictionary?.successfully_deleted+'').toLowerCase()}`)
                    setModel({...model, thumbnail:''})
                    setModel({...model, thumbnail:''})
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    console.log(model);
    return(
        <div className="p-2 bg-accent-primary box-shadow border rounded-3 mb-3">
            <div className="d-flex align-items-center mb-2 text-medium">
                <ProfileImg height={60} 
                    isUpdate={isUploadable}  save={upImg} 
                    isRemovable={isUploadable} remove={rmImg} 
                    imagePosition={model?.thumbnail_position} updateImagePosition={updateImagePosition}
                    src={model?.thumbnail ? `${Configurations.getStaticServer()}patient/${model?.id}/${model?.thumbnail}` : undefined}/>
                <div className="d-grid ps-1">
                    <Link to={`/patients/${model?.id}`} className="fw-bold text-decoration-none text-dark">
                        {getFullname(model, true)}
                    </Link>
                    <span className="fw-500 text-small">
                        <CopyableText text={model?.id} textAppendix="Saglyk ID:"/>
                    </span>
                </div>
                <div className="ms-auto">
                    <BarcodeModal height={25} value={model?.id}/>
                </div>
            </div>
            <div className="d-flex">
                <div className="d-grid text-small w-100">
                {
                    list.map((item)=>{
                        if(item.condition){
                            return(
                                <div className="d-flex align-items-center fw-500">
                                    <span className="text-slatgray">
                                        <span className="me-2">
                                            <i className={`bi bi-${item?.icon} me-1`}/>
                                            {item?.title}
                                        </span>
                                    </span>
                                    <span className="ms-auto fst-italic">
                                        {item?.value}
                                    </span>
                                </div>
                            )
                        }
                    })
                }
                </div>
            </div>
        </div>
    )
}