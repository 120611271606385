import html2canvas from 'html2canvas';
import { useContext } from 'react';
import { LangContext } from '../../context/LangContext';

export default function SaveButton({_ref}){
    const {langDictionary} = useContext(LangContext)
    return(
        <button className="btn btn-primary w-100 fw-500" onClick={()=>{
            html2canvas(_ref?.current).then((canvas) => {
                const downloadLink = document.createElement('a');
                downloadLink.href = canvas.toDataURL('image/png');
                downloadLink.download = 'barcode.png';
                downloadLink.click();

                // Optionally, notify the parent component about the download
                // if (onDownload) {
                //     onDownload();
                // }
            }).catch((e)=>console.error(e))
        }}>
            <i className="bi bi-download me-2"/>
            {langDictionary?.download}
        </button>
    )
}