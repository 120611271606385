import { Link } from "react-router-dom"

export default function Button({theme='', buttonSize='sm', className='', btnRef=null, ...props}){
    return(
        <button className={`btn btn-${buttonSize} btn-${theme} fw-500 ${className} text-nowrap`} ref={btnRef} {...props}>{props.children}</button>
    )
}

export function PrButton({buttonSize=undefined, buttonProps={}, ...props}){
    return(<Button theme="primary" buttonSize={buttonSize} {...buttonProps} {...props}>{props?.children}</Button>)
}

export function DnButton({buttonSize=undefined, buttonProps={}, ...props}){
    return(<Button theme="danger" buttonSize={buttonSize} {...buttonProps}>{props?.children}</Button>)
}

export function WrButton({buttonSize=undefined, buttonProps={}, ...props}){
    return(<Button theme="warning" buttonSize={buttonSize} {...buttonProps}>{props?.children}</Button>)
}

export function ScButton({buttonSize=undefined, buttonProps={}, ...props}){
    return(<Button theme="success" buttonSize={buttonSize} {...buttonProps}>{props?.children}</Button>)
}

export function AccentButton({theme='primary', buttonSize='sm', className='', btnRef = null, ...props}){
    return(
        <button className={`btn btn-${buttonSize} bg-accent-${theme} text-${theme} fw-500 text-nowrap ${className}`} ref={btnRef} {...props}>{props.children}</button>
    )
}

export function AccentLink({theme='primary', buttonSize='sm', className='', to='', ...props}){
    return(
        <Link to={to} className={`btn btn-${buttonSize} bg-accent-${theme} text-${theme} fw-500 ${className} text-nowrap`} {...props}>{props.children}</Link>
    )
}

export function LinkButton({theme='', buttonSize='sm', className='', to='', ...props}){
    return(
        <Link to={to} className={`btn btn-${buttonSize} btn-${theme} fw-500 ${className} text-nowrap`} {...props}>{props.children}</Link>
    )
}
