import GlobalNavs from "./navs/_global_navs";
import LanguageSwitcher from "./_language_switcher";
import AdminLogo from "./_logo";
import ProfileItem from "./_profile";
import { toggleRightbar } from "../../../../utils/main";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import Permissions from "../../../../utils/permissions";
import { LinkButton } from "../../../../components/button/button";

export default function AdminHeader({isSidebar, isRightbar, visitsCount=0, headerClass = 'text-bg-light', isGlobalNavs = true}){
    const {user, isReady} = useContext(AuthContext)
    return (
        <header class={`p-2 px-3 position-absolute top-0 start-0 end-0 w-100 border-bottom ${headerClass}`}>
            <div class="d-flex align-items-center">
                <div className="col-lg-2 col-md-3">
                    <AdminLogo isSidebar={isSidebar}/>
                </div>
                {
                    isGlobalNavs && <GlobalNavs />
                }
                <div className="col-lg-3 col-md-4 ms-auto">
                    <div class="d-flex align-items-center justify-content-end">
                        {/* <Bookmarks /> */}
                        {/* <Chats/> */}
                        {/* <Docs/> */}
                        {/* <Notifications/> */}
                        <LanguageSwitcher />
                        <LinkButton to="/settings" theme="light" className="me-2 border">
                            <i className="bi bi-gear"/>
                        </LinkButton>
                        <ProfileItem />
                        {
                            isRightbar && isReady && user && user?._role && user?._role?.permissions && user?._role?.permissions.includes(Permissions.VISIT_GET) ? 
                            <button className="btn btn-light btn-sm rounded-circle ms-2 position-relative" onClick={toggleRightbar} title="Alt+R">
                                <i className="bi bi-layout-sidebar-inset-reverse"/>
                                {
                                    visitsCount && visitsCount > 0 ?
                                    <span className="text-white position-absolute text-small fw-bold top-0 end-0 px-1 rounded-1 bg-primary">{visitsCount  }</span>
                                    :
                                    null
                                }
                            </button>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </header>
    )
}