import { useContext, useEffect, useRef, useState } from "react"
import { LangContext } from "../../../../context/LangContext"
import { AuthContext } from "../../../../context/AuthContext"
import Button, { AccentButton } from "../../../../components/button/button"
import AxiosHelper from "../../../../utils/API/AxiosHelper"
import { handleErrors } from "../../../../utils/error-handler"
import { getFormattedDate, getFullname } from "../../../../utils/main"
import Sort from "../../../../components/sort/sort"
import Modal from "../../../../components/modal/modal"
import DataViewer from "../../../../components/data-viewer"
import ViewAlternateVisit from "../../visits/viewer"
import CONSTANTS from "../../../../utils/constants"
import LoadingIcon from "../../../../components/loading-icon/loading-icon"
export const viewVisitMetadataModalID = 'view-alternate-visit-metadata-modal'
export const viewVisitModalId = 'view-alternte-visit'
export default function PatientVisits({model}){
    const {langDictionary} = useContext(LangContext)
    const {isReady, logout} = useContext(AuthContext)
    const [filter, setFilter] = useState({limit:10, page:1})
    const [data, setData] = useState([])
    const [count, setCount] = useState(0)
    const [selected, setSelected] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const modalTogglerRef = useRef()
    const refresh = ()=>{
        if(isReady && !isLoading){
            setIsLoading(true)
            AxiosHelper.getVisits({patient_id:model?.id}).then((result)=>{
                if(result.status == 200){
                    setData(result?.data?.data)
                    setCount(result?.data?.count)
                }
            }).catch((e)=>handleErrors(e, logout))
            .finally(()=>setIsLoading(false))
        }
    }
    useEffect(refresh, [isReady])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1
            }
        },
        // {
        //     text:langDictionary?.patient, content:(item)=>{
        //         return (
        //             getFullname(item?._patient, true)
        //         )
        //     }
        // },
        {
            text:langDictionary?.visit_office, content:(item)=>{
                return (
                    <span>
                        {item?._hospital?.name || 'Näbelli'}
                    </span>
                )
            }
        },
        {
            text:langDictionary?.visit_type, content:(item)=>{
                return (item?.metadata?._type?.name || '-//-')
            }
        },
        {
            text:langDictionary?.visit_method, content:(item)=>{
                return (item?.metadata?._method?.name || '-//-')
            }
        },
        {
            text:<Sort text={langDictionary?.visit_date} filter={filter} setFilter={setFilter} targetAttribute={"date"}/>, content:(item)=>{
                const date = new Date(item?.visit_date)
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '-')}
                    </span>
                )
            }
        },
        {
            text:langDictionary?.department, content:(item)=>{
                return (item?.metadata?._department?.name || '-//-')
            }
        },
        {
            text:langDictionary?.doctor, content:(item)=>{
                return (item?.metadata?._doctor ? getFullname(item?.metadata?._doctor) : '-//-')
            }
        },
        {
            text:<Sort text={langDictionary?.created_at} filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt)
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '-')}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <Button theme="light" className="bg-gray text-dark me-2 border" 
                            data-bs-toggle="modal" data-bs-target={`#${viewVisitMetadataModalID}`} 
                            onClick={()=>setSelected(item)}>
                            <i className="bi bi-eye"/>
                        </Button>
                        <AccentButton theme="danger" data-bs-toggle="modal" data-bs-target={`#${viewVisitModalId}`} 
                            onClick={()=>setSelected(item)}>
                            <i className="bi bi-file-earmark-pdf-fill"/>
                        </AccentButton>
                    </div>
                )
            }
        }
    ]
    return(
        <div className="p-2 rounded-3 box-shadow mb-3">
            <div className="d-flex align-items-center fw-500">
                <span className="text-slatgray">
                    {langDictionary?.visits}
                </span>
                <Button theme="light" className="bg-gray border ms-auto" onClick={refresh}>
                    <LoadingIcon isAnimate={isLoading}/>
                </Button>
            </div>
            <hr className="my-2" />
            <DataViewer data={data} items={items} count={count} filter={filter} setFilter={setFilter} refresh={refresh}
                key={'personified-records-data'} onDoubleClick={(item)=>{
                    if(modalTogglerRef?.current){
                        setSelected(item)
                        modalTogglerRef.current.click()
                    }
                }}/>
                
            <ViewAlternateVisit modalId={viewVisitModalId} model={selected}/>
            <ViewMetadata model={selected}/>
            <button className="d-none" data-bs-toggle="modal" data-bs-target={`#${viewVisitMetadataModalID}`} ref={modalTogglerRef}/>
        </div>
    )
}


export function ViewMetadata({model}) {
    const {langDictionary} = useContext(LangContext)
    const [dgs, setDgs] = useState([])
    useEffect(()=>{
        const arr = []
        if(model?.metadata?.diagnoses && model?.metadata?.diagnoses?.length){
            Array.from(model?.metadata?.diagnoses).map((diagnose)=>{
                if(diagnose && diagnose?._diagnose && diagnose?.type == CONSTANTS.CL_DIAGNOSE){
                    arr.push(`${diagnose?._diagnose?.code}-${diagnose?._diagnose?.name}`)
                }
            })
        }
        setDgs(arr.join(', '))
    }, [model])
    const arr = [
        {title:langDictionary?.patient, value:getFullname(model?._patient, true)},
        {title:langDictionary?.visit_office, value:model?._hospital?.name},
        {title:langDictionary?.date, value:getFormattedDate(model?.visit_date, '/', true)},
        {title:langDictionary?.department, value:model?.metadata?._department?.name},
        {title:langDictionary?.doctor, value:getFullname(model?.metadata?._doctor, true)},
        {title:langDictionary?.visit_type, value:model?.metadata?._type?.name},
        {title:langDictionary?.visit_method, value:model?.metadata?._method?.name},
        {title:langDictionary?.clynical_diagnose, value:dgs},
    ]
    return(
        <Modal id={viewVisitMetadataModalID} isFooter={false} isHeader={false} isBackdropStatic={false} modalSize="modal-lg">
            <div className="d-flex align-items-center fw-500">
                <span className="text-slatgray">
                    {langDictionary?.visit} #{model?.id}
                </span>
                <Button theme="light" className="ms-auto text-slatgray bg-gray"
                    data-bs-dismiss="modal">
                    <i className="bi bi-x-lg me-2"/>
                    {langDictionary?.close}
                </Button>
            </div>
            <hr className="my-2" />
            <table className="table table-sm table-bordered table-striped m-0">
                <tbody>
                {
                    arr.map((item)=>{
                        return(
                            <tr>
                                <td className="text-secondary" style={{width:40+'%'}}>{item?.title}</td>
                                <td className="text-dark text-center">{item?.value}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </Modal>
    )
}