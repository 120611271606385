export default function LimitSetter({filter, setFilter, count=undefined}){
    const limits = [10, 20, 30, 40, 50];
    return (
        <select name="" id="" class="form-control form-control-sm text-main mx-1" value={filter?.limit} style={{width:50+'px'}} onChange={(e)=>{
            if(count && count<e.target.value){
                setFilter({...filter, limit:e.target.value, page:1})
            }else{
                setFilter({...filter, limit:e.target.value})
            }
        }}>
            {
                limits.map((item)=>{
                    return(
                        <option value={item}>{item}</option>
                    )
                })
            }
        </select>
    )
}