import { useState } from "react";
import CheckableCard from "../../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../../components/input-block/input-block";
import InputWrapper from "../../../../../components/input-wrapper/input-wrapper";
import Select from '../../../../../components/select/select';
import { getArrayFromObject, getStatusesArr } from "../../../../../utils/main";

export default function MainElement({form, setForm, errors}){
    const [isHide, setIsHide] = useState(true);
    return (
        <div className="row">
            <div className="col-md-6">
                <InputBlock error={errors?.login} id={'admin-users-form-login'} label={'Ulanyjy ady'} 
                    value={form?.login} setValue={(e)=>setForm({...form, login:e})}
                    icon={'bi bi-person'} />
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.password} id={'admin-users-form-password'} label={'Açar sözi'} 
                    value={form?.password} setValue={(e)=>setForm({...form, password:e})} type={isHide ? "password" : 'text'}
                    icon={isHide ? 'bi bi-eye' : 'bi bi-eye-slash'} onIconClick={()=>setIsHide(!isHide)}/>
            </div>
            <div className="col-md-12">
                <Select error={errors?.status} id={'admin-users-form-status'} label={"Statusy"} 
                    key={'admin-users-form-status-wrapper'} items={getStatusesArr()} itemTitleLabel="txt" itemValueLabel="value" 
                        value={form?.status} setValue={(e)=>setForm({...form, status:e})} />
            </div>
        </div>
    )
}