export default class Settings {
    static USERS = 100
    static ROLES = 101
    static SPECIALIZATIONS = 102
    static POSITIONS = 103
    static DOCUMENT_TYPES = 104
    static DOCUMENT_SERIAL_KEYS = 105
    static BENEFITS = 106
    static RELATION_TYPES = 107
    static COUNTRIES = 108
    static REGIONS = 109
    static VILLAGES = 110
    static DISTRICTS = 111
    static DEPARTMENTS = 112
    static ROOMS = 113
    static BEDS = 114
    static VISIT_ELEMENTS = 115
    static VISIT_TYPES = 116
    static VISIT_METHODS = 117
    static VISIT_RESULTS = 118
    static DIAGNOSES = 119
    static EPID_ANAMNEZES = 120
    static VISIT_SECTIONS = 121
    static VISIT_COUNTS = 122
    static BAD_HABITS = 123
    static LIKE_ILLS = 124
    static REGISTRATION_TYPE = 125
    static OPERATION_TYPES = 126
    static OPERATION_ROOM = 127
    static ARB_ROOMS = 128
    static ARB_BED = 129
    static ANALYSE_TYPES = 130
    static ANALYSE_ATTRIBUTES = 131
    static ANALYSE_PROBES = 132
    static RD_TYPES = 133
    static RD_QUESTIONS = 134
    static RD_ANSWERS = 135
    static RD_TYPE_DIAGNOSES = 136
    static SP_TYPES = 137
    static SP_QUESTIONS = 138
    static SP_ANSWERS = 139
    static SP_DIAGNOSE_TYPES = 140
    static INSTRUMENTAL_ANALYSE_TYPES = 141
    static INSTRUMENTAL_ANALYSE_QUESTIONS = 142
    static INSTRUMENTAL_ANALYSE_ANSWERS = 143
    static TREATMENT_TYPES = 144
    static TR_PARAMS = 145
    static ARB_PARAMS = 146
    static PR_ITEMS = 147
    static PAYMENT_CATEGORIES = 148
    static PAYMENT_TARIFFS = 149
    static DISCOUNT_TYPES = 150
    static EDU_GRADES = 151
    static SC_GRADES = 152
    static EDU_INSTITUTES = 153
    static EDU_TYPES = 154
    static SC_SPECS = 155
    static SC_LABS = 156
    static SC_DIRECTIONS = 157
    static SC_FILE_TYPES = 158
    static LOGS = 159
    static DUMPS = 160
}