import axios from 'axios';
import Configurations from '../../config/configurations';
const baseURL = Configurations.getServer();
export const instance = axios.create({
    baseURL:baseURL,
    timeout: 10*60000,
    withCredentials:true,
    headers: {
        "Accept": "application/json"
    }
});

instance.interceptors.request.use((config)=>{
    config.headers.authorization = `Bearer ${window.localStorage.getItem('token')}`;
    return config;
});

instance.interceptors.response.use((config)=>{
    return config;
}, async(error)=>{
    const originalRequest = error.config;
    if(error?.response?.status == 401 && error?.config && !error.config?._isRetry){
        originalRequest._isRetry = true;
        try{
            const response = await axios.post(`${baseURL}auth/refresh`, {}, {withCredentials:true});
            window.localStorage.setItem('token', response.data?.token);
            window.localStorage.setItem("userData", JSON.stringify({...response.data.user}))
            return instance.request(originalRequest);
        }catch(e){
            console.log(e);
        }
    }
    throw error;
});

export default class AxiosHelper {
    //#region Auth
    static async login(form = {}) {
        const promise = await instance.post("/auth/login", form);
        return promise;
    }

    static async updatePassword(form = {}) {
        const promise = await instance.post("/auth/change-password", form);
        return promise;
    }

    static async captcha(){
        const promise = await instance.get('/auth/captcha', {
            responseType:'arraybuffer'
        });
        return promise;
    }

    static async confirm(form){
        const promise = await instance.post("/auth/confirm-number", form, {
            headers:{
                session_id:form?.session_id
            }
        });
        return promise;
    }
    //#endregion

    //#region Admin
        // #region Countries
        static async getCountries(filter = {}){
            const promise = await instance.get("/admin/countries",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getCountry(id){
            const promise = await instance.get("/admin/countries/"+id);
            return promise;
        }

        static async createCountry(form){
            const promise = await instance.post("/admin/countries", form);
            return promise;
        }

        static async updateCountry(id, form){
            const promise = await instance.put("/admin/countries/"+id, form);
            return promise;
        }

        static async deleteCountry(id){
            const promise = await instance.delete("/admin/countries/"+id);
            return promise;
        }
        //#endregion
        
        // #region Regions
        static async getRegions(filter = {}){
            const promise = await instance.get("/admin/regions",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getRegion(id){
            const promise = await instance.get("/admin/regions/"+id);
            return promise;
        }

        static async createRegion(form){
            const promise = await instance.post("/admin/regions", form);
            return promise;
        }

        static async syncRegions(){
            const promise = await instance.post("/admin/regions/sync");
            return promise;
        }

        static async updateRegion(id, form){
            const promise = await instance.put("/admin/regions/"+id, form);
            return promise;
        }

        static async deleteRegion(id){
            const promise = await instance.delete("/admin/regions/"+id);
            return promise;
        }
        //#endregion
        
        // #region Districts
        static async getDistricts(filter = {}){
            const promise = await instance.get("/admin/districts",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getDistrict(id){
            const promise = await instance.get("/admin/districts/"+id);
            return promise;
        }

        static async createDistrict(form){
            const promise = await instance.post("/admin/districts", form);
            return promise;
        }

        static async updateDistrict(id, form){
            const promise = await instance.put("/admin/districts/"+id, form);
            return promise;
        }

        static async deleteDistrict(id){
            const promise = await instance.delete("/admin/districts/"+id);
            return promise;
        }
        //#endregion

        // #region Villages
        static async getVillages(filter = {}){
            const promise = await instance.get("/admin/villages",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getVillage(id){
            const promise = await instance.get("/admin/villages/"+id);
            return promise;
        }

        static async createVillage(form){
            const promise = await instance.post("/admin/villages", form);
            return promise;
        }

        static async updateVillage(id, form){
            const promise = await instance.put("/admin/villages/"+id, form);
            return promise;
        }

        static async deleteVillage(id){
            const promise = await instance.delete("/admin/villages/"+id);
            return promise;
        }
        //#endregion

        // #region Streets
        static async getStreets(filter = {}){
            const promise = await instance.get("/admin/streets",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getStreet(id){
            const promise = await instance.get("/admin/streets/"+id);
            return promise;
        }

        static async createStreet(form){
            const promise = await instance.post("/admin/streets", form);
            return promise;
        }

        static async updateStreet(id, form){
            const promise = await instance.put("/admin/streets/"+id, form);
            return promise;
        }

        static async deleteStreet(id){
            const promise = await instance.delete("/admin/streets/"+id);
            return promise;
        }
        //#endregion

        // #region Homes
        static async getHomes(filter = {}){
            const promise = await instance.get("/admin/homes",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getHome(id){
            const promise = await instance.get("/admin/homes/"+id);
            return promise;
        }

        static async createHome(form){
            const promise = await instance.post("/admin/homes", form);
            return promise;
        }

        static async updateHome(id, form){
            const promise = await instance.put("/admin/homes/"+id, form);
            return promise;
        }

        static async deleteHome(id){
            const promise = await instance.delete("/admin/homes/"+id);
            return promise;
        }
        //#endregion

        // #region Apartments
        static async getApartments(filter = {}){
            const promise = await instance.get("/admin/apartments",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getApartment(id){
            const promise = await instance.get("/admin/apartments/"+id);
            return promise;
        }

        static async createApartment(form){
            const promise = await instance.post("/admin/apartments", form);
            return promise;
        }

        static async updateApartment(id, form){
            const promise = await instance.put("/admin/apartments/"+id, form);
            return promise;
        }

        static async deleteApartment(id){
            const promise = await instance.delete("/admin/apartments/"+id);
            return promise;
        }
        //#endregion

        // #region Hospitals
        static async getHospitals(filter = {}){
            const promise = await instance.get("/admin/hospitals",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getHospital(id){
            const promise = await instance.get("/admin/villages/"+id);
            return promise;
        }

        static async createHospital(form){
            const promise = await instance.post("/admin/hospitals", form);
            return promise;
        }

        static async syncHospitals(){
            const promise = await instance.post("/admin/hospitals/sync");
            return promise;
        }

        static async updateHospital(id, form){
            const promise = await instance.put("/admin/hospitals/"+id, form);
            return promise;
        }

        static async deleteHospital(id){
            const promise = await instance.delete("/admin/hospitals/"+id);
            return promise;
        }
        //#endregion

        //#region Users
        static async getUsers(filter = {}){
            const promise = await instance.get("/admin/users",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async uploadUserImage(id, form){
            const promise = await instance.post(`/admin/users/${id}/image`, form, {
                headers:{
                    "Content-type" : "multipart/form-data"
                }
            });
            return promise;
        }

        static async updateUserImagePosition(id, form){
            const promise = await instance.put(`/admin/users/${id}/image-position`, form)
            return promise
        }

        static async getRawUsers(filter = {}){
            const promise = await instance.get("/admin/users/raw",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async checkAdmin(){
            const promise = await instance.get("/admin/users/check-admin");
            return promise;
        }

        static async getUser(id){
            const promise = await instance.get("/admin/users/"+id);
            return promise;
        }

        static async createUser(form){
            const promise = await instance.post("/admin/users", form);
            return promise;
        }

        static async changeUserPassword(id, form){
            const promise = await instance.put(`/admin/users/${id}/password`, form);
            return promise;
        }

        static async updateUser(id, form){
            const promise = await instance.put("/admin/users/"+id, form);
            return promise;
        }

        static async unlockUser(id){
            const promise = await instance.put(`/admin/users/${id}/unlock`);
            return promise;
        }

        static async deleteUser(id){
            const promise = await instance.delete("/admin/users/"+id);
            return promise;
        }

        static async deleteUserThumbnail(id){
            const promise = await instance.delete(`/admin/users/${id}/image`);
            return promise;
        }

        static async getUserFilter(){
            const promise = await instance.get("/admin/users/filter");
            return promise;
        }
        //#endregion

        // #region Roles
        static async getRoles(filter = {}){
            const promise = await instance.get("/admin/roles",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getRole(id){
            const promise = await instance.get("/admin/roles/"+id);
            return promise;
        }

        static async getPermissions(){
            const promise = await instance.get("/admin/users/permissions",);
            return promise;
        }

        static async createRole(form){
            const promise = await instance.post("/admin/roles", form);
            return promise;
        }

        static async updateRole(id, form){
            const promise = await instance.put("/admin/roles/"+id, form);
            return promise;
        }

        static async deleteRole(id){
            const promise = await instance.delete("/admin/roles/"+id);
            return promise;
        }
        //#endregion

        // #region Positions
        static async getPositions(filter = {}){
            const promise = await instance.get("/admin/positions",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getPosition(id){
            const promise = await instance.get("/admin/positions/"+id);
            return promise;
        }

        static async createPosition(form){
            const promise = await instance.post("/admin/positions", form);
            return promise;
        }

        static async updatePosition(id, form){
            const promise = await instance.put("/admin/positions/"+id, form);
            return promise;
        }

        static async deletePosition(id){
            const promise = await instance.delete("/admin/positions/"+id);
            return promise;
        }
        //#endregion
        
        // #region Departments
        static async getDepartments(filter = {}){
            const promise = await instance.get("/admin/departments",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getDepartment(id){
            const promise = await instance.get("/admin/departments/"+id);
            return promise;
        }

        static async createDepartment(form){
            const promise = await instance.post("/admin/departments", form);
            return promise;
        }

        static async updateDepartment(id, form){
            const promise = await instance.put("/admin/departments/"+id, form);
            return promise;
        }

        static async deleteDepartment(id){
            const promise = await instance.delete("/admin/departments/"+id);
            return promise;
        }
        //#endregion

        // #region User notes
        static async getUserNotes(filter = {}){
            const promise = await instance.get("/admin/user-notes",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getOwnTasks(filter = {}){
            const promise = await instance.get("/admin/user-notes/own",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getUserNote(id){
            const promise = await instance.get("/admin/user-notes/"+id);
            return promise;
        }

        static async createUserNote(form){
            const promise = await instance.post("/admin/user-notes", form);
            return promise;
        }

        static async updateUserNote(id, form){
            const promise = await instance.put("/admin/user-notes/"+id, form);
            return promise;
        }

        static async deleteUserNote(id){
            const promise = await instance.delete("/admin/user-notes/"+id);
            return promise;
        }
        //#endregion
        
        // #region Diagnoses
        static async getDiagnoses(filter = {}){
            const promise = await instance.get("/admin/diagnoses",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async searchDiagnoses(filter = {}){
            const promise = await instance.get("/admin/diagnoses/search",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getDiagnose(id){
            const promise = await instance.get("/admin/diagnoses/"+id);
            return promise;
        }

        static async indexAllDiagnoses(){
            const promise = await instance.post("/admin/diagnoses/index-all", {});
            return promise;
        }

        static async importDiagnosesExcel(){
            const promise = await instance.post("/admin/diagnoses/import", {});
            return promise;
        }

        static async createDiagnose(form){
            const promise = await instance.post("/admin/diagnoses", form);
            return promise;
        }

        static async syncDiagnoses(){
            const promise = await instance.post("/admin/diagnoses/sync");
            return promise;
        }

        static async updateDiagnose(id, form){
            const promise = await instance.put("/admin/diagnoses/"+id, form);
            return promise;
        }

        static async deleteDiagnose(id){
            const promise = await instance.delete("/admin/diagnoses/"+id);
            return promise;
        }
        //#endregion

        // #region Patients
        static async getPatients(filter = {}){
            const promise = await instance.get("/admin/patients",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getSimplePatients(filter = {}){
            const promise = await instance.get("/admin/patients/simple",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getPatientsFilter(){
            const promise = await instance.get("/admin/patients/filter");
            return promise;
        }

        static async getSimplePatient(id){
            const promise = await instance.get(`/admin/patients/${id}/simple`);
            return promise;
        }

        static async getPatient(id){
            const promise = await instance.get("/admin/patients/"+id);
            return promise;
        }

        static async getPatientDashboard(id){
            return await instance.get("/admin/patients/"+id+'/dashboard');
        }

        static async getPatientCounts(id){
            const promise = await instance.get("/admin/patients/"+id+"/counts");
            return promise;
        }

        static async syncPatientWithPacs(id){
            const promise = await instance.post("/admin/patients/pacs/"+id);
            return promise;
        }

        static async createPatient(form){
            const promise = await instance.post("/admin/patients", form);
            return promise;
        }

        static async indexPatients(){
            const promise = await instance.post("/admin/patients/index");
            return promise;
        }

        static async updatePatient(id, form){
            const promise = await instance.put("/admin/patients/"+id, form);
            return promise;
        }

        static async deletePatient(id){
            const promise = await instance.delete("/admin/patients/"+id);
            return promise;
        }
        
        static async uploadPatientImage(id, form){
            const promise = await instance.post(`/admin/patients/${id}/image`, form, {
                headers:{
                    "Content-type" : "multipart/form-data"
                }
            });
            return promise;
        }

        static async updatePatientImagePosition(id, form){
            const promise = await instance.put(`/admin/patients/${id}/image-position`, form)
            return promise
        }
        
        static async deletePatientThumbnail(id){
            const promise = await instance.delete(`/admin/patients/${id}/image`);
            return promise;
        }
        //#endregion

        // #region Benefits
        static async getBenefits(filter = {}){
            const promise = await instance.get("/admin/benefits",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getBenefit(id){
            const promise = await instance.get("/admin/benefits/"+id);
            return promise;
        }

        static async createBenefit(form){
            const promise = await instance.post("/admin/benefits", form);
            return promise;
        }

        static async syncBenefits(){
            const promise = await instance.post("/admin/benefits/sync");
            return promise;
        }

        static async updateBenefit(id, form){
            const promise = await instance.put("/admin/benefits/"+id, form);
            return promise;
        }

        static async deleteBenefit(id){
            const promise = await instance.delete("/admin/benefits/"+id,);
            return promise;
        }
        //#endregion

        // #region Education grades
        static async getEducationGrades(filter = {}){
            const promise = await instance.get("/admin/education-grades",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getEducationGrade(id){
            const promise = await instance.get("/admin/education-grades/"+id);
            return promise;
        }

        static async createEducationGrade(form){
            const promise = await instance.post("/admin/education-grades", form);
            return promise;
        }

        static async syncEducationGrades(){
            const promise = await instance.post("/admin/education-grades/sync");
            return promise;
        }

        static async updateEducationGrade(id, form){
            const promise = await instance.put("/admin/education-grades/"+id, form);
            return promise;
        }

        static async deleteEducationGrade(id){
            const promise = await instance.delete("/admin/education-grades/"+id,);
            return promise;
        }
        //#endregion

        // #region Bad habits
        static async getBadHabits(filter = {}){
            const promise = await instance.get("/admin/bad-habits",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getBadHabit(id){
            const promise = await instance.get("/admin/bad-habits/"+id);
            return promise;
        }

        static async createBadHabit(form){
            const promise = await instance.post("/admin/bad-habits", form);
            return promise;
        }

        static async syncBadHabits(){
            const promise = await instance.post("/admin/bad-habits/sync");
            return promise;
        }

        static async updateBadHabit(id, form){
            const promise = await instance.put("/admin/bad-habits/"+id, form);
            return promise;
        }

        static async deleteBadHabit(id){
            const promise = await instance.delete("/admin/bad-habits/"+id);
            return promise;
        }
        //#endregion
        
        // #region Like ills
        static async getLikeIlls(filter = {}){
            const promise = await instance.get("/admin/like-ills",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getLikeIll(id){
            const promise = await instance.get("/admin/like-ills/"+id);
            return promise;
        }

        static async createLikeIll(form){
            const promise = await instance.post("/admin/like-ills", form);
            return promise;
        }

        static async syncLikeIlls(){
            const promise = await instance.post("/admin/like-ills/sync");
            return promise;
        }

        static async updateLikeIll(id, form){
            const promise = await instance.put("/admin/like-ills/"+id, form);
            return promise;
        }

        static async deleteLikeIll(id){
            const promise = await instance.delete("/admin/like-ills/"+id);
            return promise;
        }
        //#endregion
        
        // #region Patient parents
        static async getPatientParents(filter = {}){
            const promise = await instance.get("/admin/patient-parents",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getPatientParent(id){
            const promise = await instance.get("/admin/patient-parents/"+id);
            return promise;
        }

        static async createPatientParent(form){
            const promise = await instance.post("/admin/patient-parents", form);
            return promise;
        }

        static async updatePatientParent(id, form){
            const promise = await instance.put("/admin/patient-parents/"+id, form);
            return promise;
        }

        static async deletePatientParent(id){
            const promise = await instance.delete("/admin/patient-parents/"+id);
            return promise;
        }
        //#endregion
        
        // #region Relation types
        static async getRelationTypes(filter = {}){
            const promise = await instance.get("/admin/relation-types",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getRelationType(id){
            const promise = await instance.get("/admin/relation-types/"+id);
            return promise;
        }

        static async createRelationType(form){
            const promise = await instance.post("/admin/relation-types", form);
            return promise;
        }

        static async syncRelationTypes(){
            const promise = await instance.post("/admin/relation-types/sync");
            return promise;
        }

        static async updateRelationType(id, form){
            const promise = await instance.put("/admin/relation-types/"+id, form);
            return promise;
        }

        static async deleteRelationType(id){
            const promise = await instance.delete("/admin/relation-types/"+id,);
            return promise;
        }
        //#endregion

        // #region Patient red flags
        static async getPatientRedFlags(filter = {}){
            const promise = await instance.get("/admin/patient-red-flags",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getPatientRedFlag(id){
            const promise = await instance.get("/admin/patient-red-flags/"+id);
            return promise;
        }

        static async createPatientRedFlag(form){
            const promise = await instance.post("/admin/patient-red-flags", form);
            return promise;
        }

        static async updatePatientRedFlag(id, form){
            const promise = await instance.put("/admin/patient-red-flags/"+id, form);
            return promise;
        }

        static async deletePatientRedFlag(id){
            const promise = await instance.delete("/admin/patient-red-flags/"+id);
            return promise;
        }
        //#endregion
        
        // #region Patient documents
        static async getPatientDocuments(filter = {}){
            const promise = await instance.get("/admin/patient-documents",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getPatientDocumentsFilter(){
            const promise = await instance.get("/admin/patient-documents/filter", );
            return promise;
        }

        static async getPatientDocument(id){
            const promise = await instance.get("/admin/patient-documents/"+id);
            return promise;
        }

        static async createPatientDocument(form){
            const promise = await instance.post("/admin/patient-documents", form);
            return promise;
        }

        static async updatePatientDocument(id, form){
            const promise = await instance.put("/admin/patient-documents/"+id, form);
            return promise;
        }

        static async deletePatientDocument(id){
            const promise = await instance.delete("/admin/patient-documents/"+id);
            return promise;
        }
        //#endregion

        // #region Document types
        static async getDocumentTypes(filter = {}){
            const promise = await instance.get("/admin/document-types",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getDocumentType(id){
            const promise = await instance.get("/admin/document-types/"+id);
            return promise;
        }

        static async createDocumentType(form){
            const promise = await instance.post("/admin/document-types", form);
            return promise;
        }

        static async syncDocumentTypes(){
            const promise = await instance.post("/admin/document-types/sync");
            return promise;
        }

        static async updateDocumentType(id, form){
            const promise = await instance.put("/admin/document-types/"+id, form);
            return promise;
        }

        static async deleteDocumentType(id){
            const promise = await instance.delete("/admin/document-types/"+id);
            return promise;
        }
        //#endregion

        // #region Passport series
        static async getPassportSeries(filter = {}){
            const promise = await instance.get("/admin/serial-keys",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getPassportSerie(id){
            const promise = await instance.get("/admin/serial-keys/"+id);
            return promise;
        }

        static async createPassportSerie(form){
            const promise = await instance.post("/admin/serial-keys", form);
            return promise;
        }

        static async updatePassportSerie(id, form){
            const promise = await instance.put("/admin/serial-keys/"+id, form);
            return promise;
        }

        static async deletePassportSerie(id){
            const promise = await instance.delete("/admin/serial-keys/"+id);
            return promise;
        }
        //#endregion

        // #region Registrations
        static async getRegistrations(filter = {}){
            const promise = await instance.get("/admin/registrations",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getRegistration(id){
            const promise = await instance.get("/admin/registrations/"+id);
            return promise;
        }
        
        static async getRegistrationFilter(){
            const promise = await instance.get("/admin/registrations/filter");
            return promise;
        }

        static async createRegistration(form){
            const promise = await instance.post("/admin/registrations", form);
            return promise;
        }

        static async updateRegistration(id, form){
            const promise = await instance.put("/admin/registrations/"+id, form);
            return promise;
        }

        static async deleteRegistration(id){
            const promise = await instance.delete("/admin/registrations/"+id);
            return promise;
        }
        //#endregion

        // #region Registration types
        static async getRegTypes(filter = {}){
            const promise = await instance.get("/admin/registration-types",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getRegType(id){
            const promise = await instance.get("/admin/registration-types/"+id);
            return promise;
        }

        static async createRegType(form){
            const promise = await instance.post("/admin/registration-types", form);
            return promise;
        }

        static async syncRegistrationTypes(){
            const promise = await instance.post("/admin/registration-types/sync");
            return promise;
        }

        static async updateRegType(id, form){
            const promise = await instance.put("/admin/registration-types/"+id, form);
            return promise;
        }

        static async deleteRegType(id){
            const promise = await instance.delete("/admin/registration-types/"+id);
            return promise;
        }
        //#endregion

        //#region Visits
        static async getVisits(filter = {}){
            const promise = await instance.get("/admin/visits",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getVisitElements(){
            const promise = await instance.get("/admin/visits/elements",);
            return promise;
        }

        static async getVisit(id){
            const promise = await instance.get("/admin/visits/"+id);
            return promise;
        }

        static async getVisitOne(filter = {}){
            const promise = await instance.get("/admin/visits/one", {
                params:{...filter}
            });
            return promise;
        }

        static async createVisit(form){
            const promise = await instance.post("/admin/visits", form, {
                headers:{
                    "Content-type" : "multipart/form-data"
                }
            });
            return promise;
        }

        static async updateVisit(id, form){
            const promise = await instance.put("/admin/visits/"+id, form);
            return promise;
        }

        static async deleteVisit(id){
            const promise = await instance.delete("/admin/visits/"+id);
            return promise;
        }
        //#endregion

        //#region Analyse
        static async getAnalyses(filter = {}){
            const promise = await instance.get("/admin/analyses",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getAnalyse(id){
            const promise = await instance.get("/admin/analyses/"+id);
            return promise;
        }

        static async createAnalyse(form){
            const promise = await instance.post("/admin/analyses", form);
            return promise;
        }

        static async updateAnalyse(id, form){
            const promise = await instance.put("/admin/analyses/"+id, form);
            return promise;
        }

        static async deleteAnalyse(id){
            const promise = await instance.delete("/admin/analyses/"+id);
            return promise;
        }
        //#endregion

        // #region Logs
        static async getLogs(filter = {}){
            const promise = await instance.get("/admin/logs",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getLog(id){
            const promise = await instance.get("/admin/logs/"+id);
            return promise;
        }

        static async deleteLog(filter){
            const promise = await instance.delete("/admin/logs", {
                params:{
                    ...filter
                }
            });
            return promise;
        }
        //#endregion

        // #region Dumps
        static async getDumps(filter = {}){
            const promise = await instance.get("/admin/system-dumps",{
                params:{
                    ...filter
                }
            });
            return promise;
        }

        static async getDump(id){
            const promise = await instance.get("/admin/system-dumps/"+id);
            return promise;
        }

        static async createDump(form){
            const promise = await instance.post("/admin/system-dumps", form);
            return promise;
        }

        static async deleteDump(id){
            const promise = await instance.delete(`/admin/system-dumps/${id}`);
            return promise;
        }
        //#endregion

    //#endregion Admin 
}