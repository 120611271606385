import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import RolesFilter from "./filter";
import { getFormattedDate, getString } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import { handleErrors } from "../../../../utils/error-handler";
import { LangContext } from "../../../../context/LangContext";
import { AccentButton, AccentLink, LinkButton } from "../../../../components/button/button";
import { useNavigate } from "react-router-dom";
import TableStatusItem from "../../../../components/status-item/status-item";

export default function AdminRoles(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [errors, setErrors] = useState({title:null, permissions:null});
    const [filter, setFilter] = useState({page:1, limit:10, permissions:[]});
    const [isLoading, setIsLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const {isReady, token, logout, user} = useContext(AuthContext);
    const {langDictionary} = useContext(LangContext)
    const pageKey="admin-roles-page";
    const pageTitle=langDictionary?.user_roles;
    const navigate = useNavigate()

    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text={langDictionary?.name} filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {getString(item?.name) || '-//-'}
                    </span>
                )
            }
        },
        // {
        //     text:<Sort text={langDictionary?.start_page} filter={filter} setFilter={setFilter} targetAttribute='start_page'/>, content:(item)=>{
        //         return (
        //             <span className="text-dark ms-2 fw-bold">
        //                 {getString(langDictionary[Permissions.PAGES[item?.start_page]]) || '-//-'}
        //             </span>
        //         )
        //     }
        // },
        {
            text:<Sort text="Tertip belgisi" filter={filter} setFilter={setFilter} targetAttribute='order'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?.order || '-//-'}
                    </span>
                )
            }
        },
        TableStatusItem(filter, setFilter),
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '/', true)}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <AccentButton theme="danger" className="m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu roly ýok etmekçimi?")){
                                AxiosHelper.deleteRole(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Rol üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                })
                            }
                        }}>
                            <i className="bi bi-trash2"/>
                        </AccentButton>
                        <AccentLink theme="primary" className="m-1" to={`/settings/roles/${item?.id}`}>
                            <i className="bi bi-pencil"/>
                        </AccentLink>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getRoles(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle} isFilterable={true} onDoubleClick={(item)=>navigate(`/settings/roles/${item?.id}`)}
            filterBlock={<RolesFilter permissions={permissions} filter={filter} setFilter={setFilter}/>}
            startBlock={
                <LinkButton to={'/settings/roles/create'} theme="primary" className="me-2">
                    <i className="bi bi-plus-lg me-2"/>
                    {langDictionary?.add_new}
                </LinkButton>
            } />
    )
}