import InputBlock from "../../../../components/input-block/input-block";
import Select from "../../../../components/select/select";
import { districtTypes, epidStatuses, villageTypes } from "../../../../utils/main";
export default function Form({form, setForm, errors, options}){
    return (
        <div className="row">
            <div className="col-md-12">
                <InputBlock error={errors?.name} id={'admin-villages-form-name'} label={'Ady'} 
                    value={form?.name || ''} setValue={(e)=>setForm({...form, name:e})}
                    icon={'bi bi-keyboard'} />
            </div>
            {/* <div className="col-md-6">
                <Select error={errors?.district} id={'admin-villages-form-district'} label={'Etrap/şäher'} key={'admin-villages-form-district'} 
                    items={options.districts.map((_)=>{return({id:_?.id, name:`${_?.name} ${districtTypes[_?.type].toLowerCase()}`})})} 
                    value={form.district || ''} setValue={(district)=>setForm({...form , district})}/>
            </div>
            <div className="col-md-6">
                <Select error={errors?.village} id={'admin-villages-form-village'} label={'Etrap/şäher'} key={'admin-villages-form-village'} 
                    condition={(item)=>item?.district == form?.district}
                    items={options.villages.map((_)=>{return({id:_?.id, district:_?.district, name:`${_?.name} ${villageTypes[_?.type].toLowerCase()}`})})} 
                    value={form.village || ''} setValue={(village)=>setForm({...form , village})}/>
            </div> */}
            <div className="col-md-12">
                <Select error={errors?.street} id={'admin-villages-form-street'} label={'Köçe'} key={'admin-villages-form-street'} 
                    // condition={(item)=>item?.district == form?.district}
                    items={options.streets} 
                    value={form.street || ''} setValue={(street)=>setForm({...form , street})}/>
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.order} id={'admin-bed-form-order'} label={'Tertip belgisi'} 
                    value={form?.order || ''} setValue={(e)=>setForm({...form, order:e})} type="number" 
                    icon={'bi bi-123'} />
            </div>
            <div className="col-md-6">
                <Select error={errors?.status} id={'villages-status'} items={epidStatuses} label={"Statusy"}
                    value={form?.status !== undefined ?  form?.status : ''} setValue={(e)=>setForm({...form, status:e})} itemTitleLabel="title" itemValueLabel="value"/>
            </div>
        </div>
    )
}