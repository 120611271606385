import InputBlock from "../../../../components/input-block/input-block"
import { useContext } from "react"
import { LangContext } from "../../../../context/LangContext"
import PermissionForm from "./_permissions"
import ModulesForm from "./_modules"
import DashboardForm from "./_dashboard"
import ScrollToTop from "../../../../components/to-top"
import Select from "../../../../components/select/select"
import { epidStatuses } from "../../../../utils/main"
import LimitForm from "./_limit"

export default function AdminRolesForm({form, setForm, errors, setErrors, saveFunction}){
    const {lang, langDictionary} = useContext(LangContext)
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <InputBlock error={errors?.name} id={'admin-roles-form-name'} label={langDictionary?.name} 
                    value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                    icon={'bi bi-keyboard'} />
                </div>
                <div className="col-md-6">
                    <InputBlock error={errors?.order} id={'admin-bed-form-order'} label={'Tertip belgisi'} 
                        value={form?.order} setValue={(e)=>setForm({...form, order:e})} type="number"
                        icon={'bi bi-123'} />
                </div>
                <div className="col-md-6">
                    <Select error={errors?.status} id={'analyse-type-status'} items={epidStatuses} label={"Statusy"}
                        value={form?.status} setValue={(e)=>setForm({...form, status:e})} itemTitleLabel="title" itemValueLabel="value"/>
                </div>
                <div className="col-md-12">
                    <DashboardForm errors={errors} setErrors={setErrors} form={form} setForm={setForm}/>
                </div>
                <div className="col-md-12">
                    <ModulesForm form={form} setForm={setForm} errors={errors} setErrors={setErrors}/>
                </div>
                <div className="col-md-12">
                    <LimitForm errors={errors} setErrors={setErrors} form={form} setForm={setForm}/>
                </div>
                <div className="col-md-12">
                    <PermissionForm form={form} setForm={setForm} errors={errors} setErrors={setErrors}/>
                </div>
            </div>
        </>
    )
}