import InputWrapper from "../input-wrapper/input-wrapper"

export default function InputBlock({id, label, type='text', value, setValue, placeholder="...", icon=null, iconTitle='', error, inputProps=null, onIconClick=()=>{}, 
    onEnter=()=>{}, startContent = null, isTitle = true, isRequired = false, isDisabled=false}){
    return (
        <InputWrapper id={id+'-wrapper'} label={label} key={'input-wrapper-'+id} error={error} isTitle={isTitle} isRequired={isRequired}>
            <span className="position-absolute top-50 start-0 translate-middle-y">
                {startContent}
            </span>
            <input type={type} name={id} id={id} className={"form-control number-input w-100 bg-transparent mb-0 "+(isDisabled ? 'bg-accent-secondary' : "")} placeholder={placeholder}
                value={value} onChange={(e)=>setValue(e.target.value, e)} {...inputProps} onKeyDown={(e)=>{
                    if(e.code === 'Enter'){
                        onEnter()
                    }
                }}
                disabled={isDisabled}
            />
            <i title={iconTitle} className={`${icon} position-absolute top-50 end-0 translate-middle-y me-2`} onClick={onIconClick}/>
        </InputWrapper>
    )
}