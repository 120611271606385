import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { addZero, checkRequire, getFormattedDate, getString } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import { handleErrors } from "../../../../utils/error-handler";
import Button, { AccentButton } from "../../../../components/button/button";
import CopyableText from "../../../../components/copyable-text/copyable-text";
import Modal from "../../../../components/modal/modal";
import JSONPretty from "react-json-pretty";
import 'react-json-pretty/themes/monikai.css'
import { LangContext } from "../../../../context/LangContext";
import InputBlock from "../../../../components/input-block/input-block";
import Select from "../../../../components/select/select";

export default function AdminLogs(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({title:''});
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:50});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selected, setSelected] = useState({});
    const {isReady, token, logout, user} = useContext(AuthContext);
    const {langDictionary} = useContext(LangContext)
    const modalId = 'admin-logs-modal';
    const pageKey="admin-logs-page";
    const pageTitle="Loglar";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter]) 
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Metod" filter={filter} setFilter={setFilter} targetAttribute='method'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?.method || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Salgy" filter={filter} setFilter={setFilter} targetAttribute='route'/>, content:(item)=>{
                return (item?.route)
            }
        },
        {
            text:<Sort text="Ip salgy" filter={filter} setFilter={setFilter} targetAttribute='ipAddress'/>, content:(item)=>{
                return (
                    item?.ipAddress || '-//-'
                )
            }
        },
        {
            text:<Sort text="Ulanyjy" filter={filter} setFilter={setFilter} targetAttribute='userId'/>, content:(item)=>{
                return (
                    item?.userId ?
                    <CopyableText text={item?.userId}/>
                    :'-//-'
                )
            }
        },
        {
            text:<Sort text="Jogap statusy" filter={filter} setFilter={setFilter} targetAttribute='status'/>, content:(item)=>{
                return (
                    item?.status || '-//-'
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {getFormattedDate(date, '/', true)}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <AccentButton theme="secondary" className="me-2" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setSelected({...item})
                        }}>
                            <i className="bi bi-eye"/>
                        </AccentButton>
                        {/* <AccentButton theme="danger" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu logy ýok etmekçimi?")){
                                AxiosHelper.deleteLog(item?.id).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Log üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                }).catch((e)=>handleErrors(e, logout))
                            }
                        }}>
                            <i className="bi bi-trash2"/>
                        </AccentButton> */}
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getLogs(filter).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle} isFilterable={false} filterBlock={null} pageTitleEndComponent={<DeleteModal refresh={refresh}/>}
            startBlock={<FilterBlock filter={filter} setFilter={setFilter}/>}
            selectedViewBlock={
                <Modal id={modalId} isFooter={false} isHeader={false} modalSize="modal-lg">
                    <div className="d-flex align-items-center fw-500">
                        <span className="text-slatgray">
                            Log #{selected?.id}
                        </span>
                        <Button theme="light" className="ms-auto" data-bs-dismiss="modal">
                            <i className="bi bi-x-lg me-2"/>
                            {langDictionary?.close}
                        </Button>
                    </div>
                    <hr className="my-2" />
                    <SelectedViewer selected={selected}/>
                </Modal>
            }
            />
    )
}

function SelectedViewer({selected}) {
    const items = [
        {title:'Metod', value:selected?.method},
        {title:'Salgy', value:selected?.route},
        {title:'Ip salgy', value:selected?.ipAddress},
        {title:'Jogap statusy', value:selected?.status},
        {title:'Döredilen wagty', value:getFormattedDate(selected?.createdAt, '/', true)},
        {title:'Ulanyjy', value:selected?.userId ? <CopyableText text={selected?.userId}/> : '-//-'},
    ]
    return(
        <div className="overflow-hidden rounded-3 border">
            <table className="table table-striped table-bordered m-0">
                <tbody>
                    {
                        items.map((item)=>{
                            return(
                                <tr>
                                    <td>{item?.title}</td>
                                    <td className="text-center">{item?.value}</td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td>Bedeni</td>
                        <td>
                            <div style={{
                                maxHeight:350+'px',
                                overflowY:'auto',
                                fontSize:1.5+'em'
                            }}>
                                <JSONPretty data={selected?.body}/>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

function DeleteModal({refresh = ()=>{}}) {
    const closeRef = useRef()
    const {logout} = useContext(AuthContext)
    const {langDictionary} = useContext(LangContext)
    const [deleteFilter, setDeleteFilter] = useState({end_date:null});
    const deleteModalId = 'universal-delete-modal'
    function deleteAll() {
        if(window.confirm("Siz hakykatdan hem loglary ýok etmekçimi?")){
            AxiosHelper.deleteLog(deleteFilter).then((result)=>{
                if(result.status == 200){
                    appendNotification("Loglar üstünlikli ýok edildi!", 'success')
                    refresh()
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    return(
        <ModalWithButton modalId={deleteModalId} closeRef={closeRef} title={'Loglary ýok etmek'} 
            buttonProps={{className:'btn btn-sm btn-danger px-3 fw-500'}}
            buttonContent={<>
                <i className="bi bi-trash2 me-2"/>
                {langDictionary?.delete}
            </>}
            endButtons={<AccentButton className="ms-2" theme="primary" onClick={deleteAll}><i className="bi bi-check2-square me-2"/> Tassyklamak</AccentButton>}>
            <InputBlock error={null} id={'delete-end-date'} label={'Ahyrky sene'} type="date"
                value={deleteFilter?.end_date} setValue={(end_date)=>setDeleteFilter({...deleteFilter, end_date})}/>
        </ModalWithButton>
    )
}

function FilterBlock({filter, setFilter}) {
    const [elements, setElements] = useState({statuses:[], routes:[]})
    const {isReady, logout} = useContext(AuthContext)
    const closeRef = useRef()
    const modalId = 'filter-modal'
    const [shadowFilter, setShadowFilter] = useState({})
    useEffect(()=>refresh(), [isReady])
    function refresh(){
        if(isReady){
            AxiosHelper.getLog('filter').then((result)=>{
                if(result.status == 200){
                    setElements({...result.data})
                }
            }).catch((e)=>handleErrors(e, logout))
        }
    }
    console.log(elements);
    return(
        <ModalWithButton closeRef={closeRef} modalId={modalId} title={'Filter'} 
            buttonProps={{className:'btn btn-sm btn-light border fw-500 text-nowrap px-3'}} 
            buttonContent={<><i className="bi bi-sliders me-2"/>Filter</>}
            isSaveEnable={true} saveFunction={()=>setFilter({...filter, ...shadowFilter})}>
                <div className="row">
                    <div className="col-md-6">
                        <InputBlock error={null} id={'filter-start_date'} label={'Başlangyç sene'} type="date"
                            value={shadowFilter?.start_date} setValue={(start_date)=>setShadowFilter({...shadowFilter, start_date})}/>
                    </div>
                    <div className="col-md-6">
                        <InputBlock error={null} id={'filter-end-date'} label={'Ahyrky sene'} type="date"
                            value={shadowFilter?.end_date} setValue={(end_date)=>setShadowFilter({...shadowFilter, end_date})}/>
                    </div>
                    <div className="col-md-6">
                        <Select error={null} id={'filter-status'} items={elements?.statuses} 
                            value={shadowFilter?.status} setValue={(status)=>setShadowFilter({...shadowFilter, status})} 
                            label={'Status'} itemTitleLabel="status" itemValueLabel="status"/>
                    </div>
                    <div className="col-md-6">
                        <Select error={null} id={'filter-route'} items={elements?.routes} 
                            value={shadowFilter?.route} setValue={(route)=>setShadowFilter({...shadowFilter, route})} 
                            label={'Route'} itemTitleLabel="route" itemValueLabel="route"/>
                    </div>
                </div>
            </ModalWithButton>
    )
}