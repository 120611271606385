import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Configurations from "../config/configurations";
import axios from "axios";

export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [user, setUser]  = useState(null);
    const [isReady, setReady]  = useState(false);

    const login = useCallback((token, user) => {
        setToken(token);
        setUser(user);
        window.localStorage.setItem("token", token)
        window.localStorage.setItem("userData", JSON.stringify({...user}))
    }, []);

    const logout = () => {
        axios.post(`${Configurations.getServer()}auth/logout`, {}, 
            {
                headers:{authorization : `Bearer ${token}`},
                withCredentials:true
            }
        ).then((response)=>{
        }).catch((e)=>{
            console.log(e)
        }).finally(()=>{
            setReady(true);
            setToken(null);
            setUser(null);
            window.localStorage.removeItem("userData");
        })
    }

    useEffect(()=>{
        axios.post(`${Configurations.getServer()}auth/refresh`, {}, {withCredentials:true}).then((response)=>{
            if(response.status == 200 && response.data && response.data?.user && response.data?.token){
                login(response.data?.token, response.data?.user);
            }
        }).catch((e)=>{
            console.log(e);
        }).finally(()=>{
            setReady(true);
        });
        // const token = window.sessionStorage.getItem('token')
        // const userData = window.sessionStorage.getItem('userData')
        // if(token && userData){
        //     setToken(token)
        //     setUser(userData)
        // }else{
        //     setToken(null)
        //     setUser(null)
        // }
        // setReady(true)
        // console.log(token, userData);
    }, [login])

    return {login, logout, token, user, setUser, isReady}; 
}