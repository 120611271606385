import { useContext, useState } from "react"
import { LangContext } from "../../context/LangContext"
import styles from './style.module.css'
import Button, { AccentButton } from "../button/button"
import { getFormattedDate, getFullname } from "../../utils/main"
import Modal from "../modal/modal"
import useWindowDimensions from "../../hooks/useWindowDimensions"
const MODAL_ID = 'change-view-modal'

export default function PatientChangeLog({data = []}){
    const {langDictionary} = useContext(LangContext)
    const [selected, setSelected] = useState({})
    return(
        <div className="p-3 rounded-3 box-shadow border">
            <div className="d-flex align-items-center">
                <span className="fw-500">
                    {langDictionary?.change_history}
                </span>
                <span className="ms-auto border bg-accent-warning rounded-3 text-orange px-2 fw-500">
                    {data?.length}
                </span>
            </div>
            <hr className="my-2" />
            <div className="d-grid overflow-auto pe-2" style={{maxHeight:450+'px'}}>
            {
                data.map((item)=>{
                    return(
                        <ChangeItem item={item} setSelected={setSelected}/>
                    )
                })
            }
            </div>
            <ViewModal model={selected}/>
        </div>
    )
}

function ChangeItem({item, setSelected}){
    const {langDictionary} = useContext(LangContext)
    return(
        <div className={styles.change_item + " d-flex align-items-top"}>
            <div className={styles.change_item_indicator}>
                <input type="radio" checked/>
                <div className={styles.indicator}/>
            </div>
            <div className={'w-100'}>
                <div className="d-flex align-items-center mb-1 border rounded-3 bg-light text-small p-2 cursor-pointer  fw-bold"
                     data-bs-toggle="modal" data-bs-target={`#${MODAL_ID}`} onClick={()=>setSelected(item)}>
                    <span className="w-100">
                        {getFullname(item?.doctor)}
                    </span>
                    <span className="text-slatgray">
                        {getFormattedDate(item?.createdAt)}
                    </span>
                </div>
            </div>
        </div>
    )
}

function ViewModal({model = null}){
    const {langDictionary} = useContext(LangContext)
    const {height} = useWindowDimensions()
    
    return(
        <Modal id={MODAL_ID} isFooter={false} isHeader={false}>
            <div className="d-flex align-items-center">
                <span className="fw-500 text-slatgray">
                    {langDictionary?.change_history} #{model?.id}
                </span>
                <Button theme="light" className="px-3 ms-auto" data-bs-dismiss="modal">
                    <i className="bi bi-x-lg me-2"/>
                    {langDictionary?.close}
                </Button>
            </div>
            <hr className="my-2" />
            {[
                model && model?.id != null ?
                <>
                    <div className="text-medium fw-500 mb-2">
                        {
                            [
                                {title:'Sene', value:getFormattedDate(model?.createdAt, '/', true)},
                                {title:'Amal', value:langDictionary[ACTIONS[model?.action].label]},
                                {title:'Lukman', value:getFullname(model?.doctor, true), condition:(model?.doctor && model?.doctor?.id)},
                                {title:'Hassahana', value:model?.doctor?.hospital, condition:model?.doctor?.hospital != null},
                                {title:'Raýat ID', value:'#'+model?.patient_id, condition:model?.patient_id},
                                {title:'Resminama ID', value:'#'+model?.document_id, condition:model?.document_id},
                            ].map((item)=>{
                                if(item.condition === undefined || item?.condition){
                                    return(
                                        <div className="d-flex align-items-center">
                                            <div className="col-4">
                                                <span className="text-slatgray">
                                                    {item.title}
                                                </span>
                                            </div>
                                            <div className="col-8 text-end">
                                                {item?.value}
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    {
                        model?.body &&
                        <>
                        <table className="table table-striped table-bordered m-0">
                            <thead className="bg-accent-primary text-primary">
                                <tr>
                                    <th className="text-center" colSpan={2}>
                                    {
                                        model?.action == ACTION_CREATE || model?.action == ACTION_DOCUMENT_CREATE ?
                                        "Girizilen ululyklar"
                                        :
                                        "Üýtgedilen ululyklar"
                                    }
                                         
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className="overflow--auto" style={{maxHeight:(height-250)+'px'}}>
                            <table className="table table-striped table-bordered m-0">
                                <tbody>
                                {
                                    Object.entries(titles).map(([label, title])=>{
                                        if(model?.body[label] !== undefined){
                                            return(
                                                <tr>
                                                    <td>{title}</td>
                                                    <td className="text-center">{model?.body[label]}</td>
                                                </tr>
                                            )
                                        }
                                    })
                                }
                                {
                                    model?.body?.documents && model?.body?.documents?.length ?
                                    <tr>
                                        <td>Resminamalar</td>
                                        <td className="text-center">
                                            {
                                                Array.from(model?.body?.documents).map((document)=>{
                                                    return(
                                                        `${document?.type} ${document?.serial_key} ${document?.number} ${document?.given_date ? `(${document?.given_date})` : ''}`
                                                    )
                                                })
                                            }
                                        </td>
                                    </tr>
                                    :
                                    null
                                }
                                </tbody>
                            </table>
                        </div>
                        </>
                    }
                </>
                :
                null
            ]}
        </Modal>
    )
}


const ACTION_CREATE = 10
const ACTION_UPDATE = 20
const ACTION_DELETE = 30
const ACTION_DOCUMENT_CREATE = 40
const ACTION_DOCUMENT_UPDATE = 50
const ACTION_DOCUMENT_DELETE = 60
const ACTIONS = {
    [ACTION_CREATE]:{
        label:'action_create', 
        icon:'person-plus', 
        color:'teal'
    },
    [ACTION_UPDATE]:{
        label:'action_update', 
        icon:'pencil-square', 
        color:'primary'
    },
    [ACTION_DELETE]:{
        label:'action_delete', 
        icon:'person-lines-fill', 
        color:'trash'
    },
    [ACTION_DOCUMENT_CREATE]:{
        label:'action_document_create', 
        icon:'file-earmark-plus', 
        color:'teal'
    },
    [ACTION_DOCUMENT_UPDATE]:{
        label:'action_document_update', 
        icon:'file-earmark-text', 
        color:'primary'
    },
    [ACTION_DOCUMENT_DELETE]:{
        label:'action_document_delete', 
        icon:'file-earmark-text', 
        color:'trash'
    },
}

const titles = {
    'id':'ID',
    'isAnonim':'Näbellimi',
    'name':'Ady',
    'surname':'Familiýasy',
    'second_name':'Atasynyň ady',
    'gender':'Jynsy',
    'blood_group':'Gan topary',
    'birth_date':'Doglan güni',
    'country':'Raýatlygy',
    'birth_region':'Hasaba duran welaýaty',
    'birth_city':'Hasaba duran şäheri',
    'birth_village':'Hasaba duran etraby',
    'region':'Ýaşaýan welaýaty',
    'city':'Ýaşaýan şäheri',
    'village':'Ýaşaýan etraby',
    'note':'Bellik',
    'phone_number':'Telefon belgisi',
    'home_phone':'Öý telefon belgisi',
    'mail':'E-poçta',
    'is_health_book':'Saglyk depderi barmy',
    'health_book':'Saglyk depderi',
    'benefit':'Ýeňillik kategoriýasy',
    'number':'Resminama belgisi',
    'type':'Resminama görnüşi',
    'serial_key':'Seriýa belgisi',
    'given_date':'Berlen senesi'
}