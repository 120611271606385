import { Link, useLocation, useNavigate } from "react-router-dom";
import AdminLogo from "../admin/_components/header/_logo";
import Configurations from "../../config/configurations";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { LangContext } from "../../context/LangContext";
import LanguageSwitcher from "../admin/_components/header/_language_switcher";
import Permissions from "../../utils/permissions";

export default function AuthWrapper(props){
    const {isReady, isLogin, user} = useContext(AuthContext);
    const {langDictionary} = useContext(LangContext)
    const navigate = useNavigate();
    const location = useLocation()
    const pathname = location?.state
    console.log('Pathname: ', pathname);
    useEffect(()=>{
        if(isReady && isLogin){
            navigate(pathname || Permissions.PAGES_RED[user?._role?.start_page] || '/', {replace:true})
        }
    }, [isReady, isLogin])
    return (
        <div className="w-100 vh-100 auth-wrapper">
            <div className="col-md-6 col-lg-7 d-none d-md-block bg-auth h-100 position-relative">
                <div className="gray-overlay"></div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 form-container h-100">
                <div className="d-grid">
                    <Link to="/" className="admin-logo mb-3">
                        {/* <img src="/img/favicon.png" alt="ADMSES logo"/> */}
                        <span className="ms-0">{Configurations.APPNAME}</span>
                    </Link>
                    {
                        props.children
                    }
                </div>
            </div>
            <div className="position-absolute d-flex align-items-center w-100 justify-content-start top-0 start-0 p-3">
                <p className="border-start border-white border-5 ps-2 fw-500 text-white fs-5 d-none d-md-block" style={{maxWidth:400+'px'}}>
                    {Configurations.FULLAPPNAME}
                </p>
                <div className="d-flex align-items-center ms-auto">
                    <LanguageSwitcher isSmall={true}/>
                    <img src="/img/logo.png" alt="" height={75}/>
                </div>
            </div>
            <div className="position-absolute bottom-0 start-0 p-3 w-100">
                <hr className="border-white mb-0"/>
                <div className="d-flex align-items-center w-100 justify-content-between ">
                    <p className="ps-2 text-white d-none d-md-block">
                        Copyright &copy; {new Date().getFullYear()}. {langDictionary?.copyright_text} 
                    </p>
                    <span className="text-small fw-500 text-slatgray">
                        Powered by {Configurations.COMPANY_NAME} {Configurations.COMPANY_APPENDIX}
                    </span>
                </div>
            </div>
        </div>
    )
}