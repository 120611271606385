import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { addZero, checkRequire, getString } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import { handleErrors } from "../../../../utils/error-handler";
import STATUSES from "../../../../utils/statuses";
import Badge from "../../../../components/badge/badge";
import { AccentButton } from "../../../../components/button/button";
import { LangContext } from "../../../../context/LangContext";

export default function AdminDepartments(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({title:''});
    const [errors, setErrors] = useState({title:null});
    const [filter, setFilter] = useState({page:1, limit:10});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout, user} = useContext(AuthContext);
    const {langDictionary} = useContext(LangContext)
    const closeRef = useRef();
    const modalId = 'admin-departments-modal';
    const pageKey="admin-departments-page";
    const pageTitle="Bölümler";
    useEffect(()=>{
        if(isReady) refresh()
    }, [isReady, filter])
    
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Ady" filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.name) ? getString(item?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Bejergi üçinmi" filter={filter} setFilter={setFilter} targetAttribute='isTreatment'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?.isTreatment ? langDictionary?.yes : langDictionary?.no}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Registratura" filter={filter} setFilter={setFilter} targetAttribute='isRegister'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?.isRegister ? langDictionary?.yes : langDictionary?.no}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Tertip belgisi" filter={filter} setFilter={setFilter} targetAttribute='order'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {getString(item?.order) || '-//-'}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Status" filter={filter} setFilter={setFilter} targetAttribute='status'/>, content:(item)=>{
                return (
                    <AccentButton theme={item?.status == 99 ? "secondary" : 'success'}>
                        <i className={`bi bi-eye${item?.status == 99 ? '-slash' : ''}`}/>
                    </AccentButton>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (
                    <span className="text-dark fw-bold">
                        {`${date.getFullYear()}-${addZero(date.getMonth()+1)}-${addZero(date.getDate())}`}
                    </span>
                )
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <AccentButton theme="danger" className="me-2" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu SES edarasyny ýok etmekçimi?")){
                                AxiosHelper.deleteDepartment(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("SES edarasy üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                }).catch((e)=>handleErrors(e, logout))
                            }
                        }}>
                            <i className="bi bi-trash2"/>
                        </AccentButton>
                        <AccentButton theme="primary" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"/>
                        </AccentButton>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getDepartments(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, logout, errors, setErrors)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateDepartment(form?.id, form, token) : AxiosHelper.createDepartment(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Ýerli edara üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, logout, errors, setErrors)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)) err.name='Bölüm adyny giriziň'
        setErrors(err)
        return err.name;
    }

    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle} isFilterable={false} filterBlock={null}
            startBlock={
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Bölümi üýtgetmek' :"Täze bölüm goşmak"}
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} 
                    onButtonClick={()=>{
                        setIsUpdate(false)
                        setForm({
                            title:'',
                            unit:'',
                            status:'',
                            order:''
                        }) 
                        setErrors({})
                    }}>
                    <Form form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save}/>
                </ModalWithButton>
            } />
    )
}