import { useContext, useEffect, useState } from "react"
import { LangContext } from "../../../../../context/LangContext"
import Button, { AccentButton } from "../../../../../components/button/button"
import SemiDonutChart from "../../../../../components/semi-donut-chart"
import { COLORS } from "../../../../../utils/main"
import DonutChart from "../../../../../components/donut-chart"
import Chart from "react-apexcharts"
import BarChart from "../../../../../components/bar-chart"
import AreaChart from "../../../../../components/area-chart"

export default function ReportPaymentsBlock(){
    const {langDictionary} = useContext(LangContext)
    return(
        <div className="p-2 rounded-3 box-shadow bg-white">
            <div className="d-flex align-items-center">
                <span className="text-slatgray fw-500">
                    {langDictionary?.payments}
                </span>
                <div className="ms-auto">
                    <AccentButton theme="primary" className="me-2">
                        <i className="bi bi-file-earmark-medical"/>
                    </AccentButton>
                    <Button theme="light">
                        <i className="bi bi-arrow-repeat"/>
                    </Button>
                </div>
            </div>
            <hr className="my-2" />
            <div className="row">
                <div className="col-md-8 border-end">
                    <ByDate/>
                </div>
                <div className="col-md-4">
                    <ByStatus/>
                </div>
            </div>
        </div>
    )
}
function ByStatus() {
    return(
        <div className="p-1 pb-0 text-medium ">
            <div className="d-flex w-100">
                <div className="col-7">
                    <DonutChart 
                        labels={['Garaşylýan', 'Tamamlanan']}
                        series={[65, 30, 5]}
                        height={200}
                        labelShow={false}
                        toolbarShow={false}
                        />
                </div>
                <div className="col-5 pt-4">
                {
                    [
                        {x:'Nagt', y:65},
                        {x:'Kart', y:30},
                        {x:'Geçirim', y:5},
                    ].map((item, index)=>{
                        return(
                            <div className="d-flex align-items-bottom text-nowrap fw-500 my-2">
                                <span className="text-slatgray">
                                    <i className="bi bi-square-fill me-1" style={{color:COLORS[index]}}/>
                                    {item.x}
                                </span>
                                <div className="w-100 border-0 border-bottom border-dashed"/>
                                <span className="text-dark text-nowrap ms-auto">
                                    {item.y} <i className="bi bi-percent"/>
                                </span>
                            </div>
                        )
                    })
                }
                </div>
            </div>
            <div className="d-flex flex-wrap">
                {
                    [
                        {x:'Jemi', y:33500},
                        {x:'Nagt', y:16500},
                        {x:'Kart', y:14500},
                        {x:'Geçirim', y:2500},
                    ].map((item)=>{
                        return(
                            <div className="p-1 col-6">
                                <div className="p-2 rounded-3 bg-light fw-500">
                                    <span className="text-slatgray">
                                        {item.x}:
                                    </span>
                                    <br />
                                    <p className="text-warning fs-4 text-center p-0 m-0">
                                        {item.y} m.
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

function ByDate(){
    const startDate = new Date()
    startDate.setDate(startDate.getDate()-6)
    const [series, setSeries]= useState([])
    useEffect(()=>{
        const arr = []
        for (let i = 0; i < 6; i++) {
            arr.push({
                x: startDate.getTime(),
                y: (Math.random()*100)
            })
            startDate.setDate(startDate.getDate()+1)
        }
        setSeries(arr)
    }, [])
    const [options, setOptions] = useState({
        chart:{
            type:'area',
            height:350,
            toolbar:{
                show:false
            }
        },
        series:[
            {
                name:'Radiologiýa',
                data:[12362, 11520, 10236, 12260, 12536, 9852, 13256]
            }
        ],
        xaxis: {
            type: 'datetime',
            categories: ["04/11/2024", "04/12/2024", "04/13/2024", "04/14/2024", "04/15/2024", "04/16/2024", "04/17/2024"]
        },
        stroke: {
            width: 2,
            curve:'smooth'
        },
        tooltip: {
          shared: false,
          intersect: true
        },
        markers: {
          size: 4
        },
        legend: {
            horizontalAlign: 'left',
            position: 'top'
        },
        dataLabels: {
            enabled: false,
        },
    })
    return(
        <Chart id={'analyse-reports-by-date'} type={"area"} height={320} options={options} series={options.series}/>
    )
}